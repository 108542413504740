import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { strings } from '../../helpers/translation';
import { localeSetLanguage } from '../../reducers/locale';
import './header.css';

const Index = ({ back, fromCart, title, minimize, direction, location, user, showLoyalty, resLoyalty, login, offers, showOffer, updateLang, showSelectLocation }) => {
    const [state, setState] = useState({})
    const settings = useSelector(state => state.settings)
    const dispatch = useDispatch()
    const scrollContainerRef = useRef(null)
    useEffect(() => {
        strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
    }, [])


    function renderNewHeader() {
        let loyalty = resLoyalty ? (user && user.info ? (user.info.loyalty ? user.info.loyalty : { points: 0 }) : { login: true }) : false;

        return (
            <>
                <div className='selectLocation'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 0 16px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='resImage' style={{ backgroundImage: 'url("' + settings?.menuLogo + '")' }}>

                            </div>
                            <div className='resTitle'>
                                {settings?.resName}
                            </div>
                        </div>
                        <div>
                            {
                                state?.loading ?
                                    <Button
                                        loading={true}
                                        style={{ cursor: 'pointer', backgroundColor: 'transparent', width: '30px', minWidth: '30px', height: '30px' }}
                                        circular
                                        className="cart" />
                                    :
                                    <div
                                        className="cart"
                                        style={{ cursor: 'pointer', border: '2px solid var(--themeColor)', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', padding: '0', position: 'relative', width: '30px', height: '30px', fontFamily: direction === 'rtl' ? 'poppins-regular' : 'poppins-regular-ar' }}
                                        onClick={() => {
                                            setState({ ...state, loading: true });

                                            setTimeout(() => {
                                                // dispatch(localeSetLanguage())

                                                localStorage.setItem('direction', direction === 'rtl' ? 'ltr' : 'rtl');
                                                strings.setLanguage(direction === 'rtl' ? 'en' : 'ar');
                                                document.body.style.direction = (direction === 'rtl' ? 'ltr' : 'rtl');
                                                updateLang(direction === 'rtl' ? 'ltr' : 'rtl');
                                                let rightContainer = document.getElementById('rightSideContainer')
                                                if (rightContainer) {
                                                    rightContainer.style.left = direction === 'rtl' ? 'auto' : '0'
                                                    rightContainer.style.right = direction === 'rtl' ? '0' : 'auto'

                                                }

                                                setState({ ...state, loading: false });
                                            }, 1000);


                                        }}>
                                        <div>
                                            {direction === 'rtl' ? 'EN' : 'ع'}
                                        </div>

                                    </div>

                            }
                        </div>
                    </div>

                    {
                        (location && location.deliveryTime) || (location && location.minOrder) ?
                            <div className='tagView'>
                                {
                                    location && location.deliveryTime ? <div className='tag' style={direction === 'rtl' ? { padding: '0  0 0 16px' } : { padding: '0 16px 0 0' }}>
                                        <div className='icon'>
                                            <Icon name='clock outline'></Icon>
                                        </div>
                                        <div className='text'>
                                            {strings.deliveryTime + " " + location.deliveryTime.value + strings[location.deliveryTime.label]}
                                        </div>
                                    </div>
                                        : ''
                                }

                                {
                                    location && location.minOrder ? <div className='tag' style={direction === 'rtl' ? { padding: '0  0 0 16px' } : { padding: '0 16px 0 0' }}>
                                        <div className='icon'>
                                            <Icon name='dollar sign'></Icon>
                                        </div>
                                        <div className='text'>
                                            {strings.minOrder + location.minOrder + " " + strings[settings?.currency]}
                                        </div>

                                    </div>
                                        : ''
                                }
                            </div>
                            : ''
                    }



                </div>

                <div className='codesView' ref={scrollContainerRef}>
                    {
                        loyalty ?
                            <div className='loyaltyContainer' onClick={() => {
                                if (!loyalty.login) {
                                    showLoyalty()
                                }
                            }}>
                                <span style={{ fontSize: '18px', margin: '0 8px' }} role='img' aria-label="">🎉</span>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    {
                                        loyalty.login ?
                                            <>
                                                <Button basic className='action' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', minWidth: '180px' }} onClick={() => login()}>
                                                    <div style={{ color: 'var(--themeColor)', textDecoration: 'underline' }}> {strings.clickHere}</div>
                                                    <div style={{ textAlign: 'start' }}>{strings.login}</div>
                                                </Button>
                                            </>
                                            :

                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', minWidth: '90px' }}>
                                                <span className='pts'>{(loyalty.points ? loyalty.points : 0) + strings[settings?.currency]} </span>
                                                {strings.inWallet}
                                            </div>
                                    }
                                    {/* <Icon circular name='info'   bordered style={{ cursor: 'pointer', color: 'var(--themeColor)',width:'50px', margin: '0', boxShadow: `0 0 0 0.15em var(--themeColor) inset`, margin: '0 9px' }} onClick={() => showLoyalty()} /> */}

                                </div>

                            </div>
                            : ''
                    }

                    {
                        offers?.length > 0 && offers?.map((item, index) => {
                            return (
                                <div key={index} className='loyaltyContainer' onClick={() => {
                                    showOffer(item)
                                }}>
                                    <i class="discountIcon"></i>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', width: item.limited ? '200px' : '120px', textAlign: 'start' }}>
                                        <span className='pts'>{item.code} </span>
                                        <span>{`${strings.Get}${item.percent}% ${strings.discount} ${(item.limited ? (strings.onfirst + item.limited + strings.orders) : strings.nextOrder)}`} </span>

                                        {/* <span>{strings.Get + item.percent + strings.discount + (item.limited ? (strings.onfirst + item.limited + strings.orders) : strings.nextOrder)} </span> */}
                                    </div>
                                </div>)
                        })
                    }
                </div>


                <div className='selectLocationView' onClick={() => {
                    if (location && location.type === 'dineIn') {
                        return
                    };
                    showSelectLocation();
                }
                }>
                    {
                        showSelectLocation && <>
                            <div style={{ backgroundColor: 'var(--themeColor)', position: 'absolute', height: '50px', opacity: '0.05', width: '100%' }} />
                            <div style={{ display: 'flex', alignItems: 'center', padding: '16px', height: '50px', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon className='icon' name='map marker alternate'></Icon>
                                    <div className='deliveryType'>{
                                        (location && location.type) ?
                                            (location.type === 'pickup' ?
                                                ((direction === 'rtl') ? 'استلم الطلب من :'
                                                    : 'Pickup from:')
                                                : location.type === 'dineIn' ?
                                                    ((direction === 'rtl') ? 'يقدم الى: ' : 'Serving To:')



                                                    : ((direction === 'rtl') ? 'التوصيل إلى :' : 'Delivering to:')) : 'Delivering to'}</div>
                                    <div className='location'>{
                                        location ?
                                            (location.type === 'dineIn' ?
                                                (location.location ? ((direction === 'rtl' ? 'طاولة رقم ' : 'Table #') + location.location) : (direction === 'rtl' ? ' داخل المطعم' : 'Dine in'))
                                                : (location.location && location.location.name ? (direction === 'rtl' ? location.location.name_ar : location.location.name) : location.location))
                                            : 'Select Location'}</div>

                                </div>
                                {
                                    location && location.type !== 'dineIn' ?
                                        <div style={{ fontSize: '22px' }}>
                                            <Icon className='icon' name='angle down'></Icon>
                                        </div>
                                        : ''
                                }

                            </div>
                        </>
                    }



                </div>
            </>
        )
    }

    return (
        <div className='header' style={fromCart ? { width: '100%' } : minimize ? { position: 'sticky', top: '0', padding: '16px' } : { boxShadow: 'none' }}>

            {
                fromCart || minimize ?
                    <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', direction: 'ltr' }}>
                        <Icon name="arrow left"
                            onClick={() => {
                                back ? back() :
                                    // (document.referrer.indexOf(window.location.host)) > -1 ? window.history.back() : 
                                    document.location.href = "/" + settings?.path
                            }} className='back'

                        ></Icon>
                        <div className='titleBar'>{minimize ? ((direction === 'rtl' && minimize.name_ar) ? minimize.name_ar : minimize.name) : title}</div>
                        <div className='back'></div>


                    </div>


                    :

                    renderNewHeader()
            }
        </div>
    );
};

export default Index;
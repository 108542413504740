import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
// import Home from './home/home'
import Menu from './components/MenuComponents/menu'
import Cart from './components/Cart/cart'
import RatingPage from './components/Cart/ratingPage/ratingPage'
import Profile from './components/profile/profile.js'
import { Helmet } from 'react-helmet'
import OrdersStatus from './components/MenuComponents/orderStatus/orderStatus'
import { firestore, auth, functions } from 'firebase'
import { connect } from 'react-redux';
import { flushOrders, updateOrder } from './reducers/newOrder'

import { updateUserProperty, setActiveOrder, persistMenu, updateRes, updateUserLocation, updateTimings, setHelpers, sendNotif } from './reducers/userReducer'
import { setLoyalty } from './reducers/loyaltyReducer'
import ItemsList from './components/MenuComponents/itemsList.js'
import MenuItem from './components/MenuComponents/item-page/item.js'
import { updateRecentOrders } from './reducers/recentOrdersReducers'

import NewLogin from './components/Login/newLogin';
import Notification from './components/notification'
import {
  checkCoponeValid
} from './helpers/cartHelpres'

import { Loader, Dimmer } from 'semantic-ui-react'
import './App.css';
import LocalizedStrings from 'react-localization';
import { getOffers } from './reducers/offers';
import { setStoreSettings } from './reducers/settings';
import { getPrice } from './helpers';
import AlertModal from './components/Helpers/AlertModal';
import MenuRes from './components/MenuRes';
class App extends Component {
  state = {
    direction: localStorage.getItem('direction'),
    loading: true,
    defaultHosts: ['eg.food2order.io', 'app.food2order.io', 'food2order-test.firebaseapp.com', 'app.buzaar.io', 'localhost', '192.168.0.108', '192.168.70.167'],
    unsbscribGroup: [],
    unsubscribeBranch: false
  }

  strings = new LocalizedStrings({
    en: {
      'Alert!': 'Alert!',
      'You have already earned points': 'Share with friends to earn more 🤩',
      'Congrats': "Congrats",
      ' is added to your wallet for the next order 🎁 ': ' has been added to your wallet 🎁 ',
      'You have earned': 'You have earned',
      from: ' from ',
    },
    ar: {
      'Alert!': 'تحذير',
      'You have already earned points': ' شارك مع الأصدقاء لكسب المزيد 🤩',
      'Congrats': "مبروك",
      ' is added to your wallet for the next order 🎁 ': ' 🎁تم إضافتهم في محفظتك بنجاح ',
      'You have earned': 'لقد حصلت على ',
      from: ' من ',



    }
  })

  componentWillMount() {
    let { direction, defaultHosts } = this.state
    let { updateRes, setStoreSettings } = this.props;
    let unsubscribeBranch;
    let url = (window.location.pathname).split('/')
    let path;
    if (defaultHosts.includes(window.location.hostname)) {
      path = (url[1]).toLowerCase()
    }
    // firestore().collection('res').where('id', '==', 'hASQh193KMT3vmAvcNZy').get().then(records => {
    firestore().collection('res').where('startUrl', '==', (path ? ('/' + path) : window.location.hostname)).limit(1).get().then(records => {
      if (records.empty) {
        return this.setState({ loading: false })
      }
      records.forEach(result => {
        if (result.exists) {
          let data = result.data()
          updateRes(data.id)
          setStoreSettings({ ...data, resDocId: result.id, path: path })
          let { storeSubscriptions } = data.subscription ? data.subscription : {};
          let categoryMenu = storeSubscriptions?.categoryMenu?.activated;
          if (storeSubscriptions) {
            if (storeSubscriptions?.loyalty?.activated) {
              let loyalty = storeSubscriptions.loyalty
              let referal = new URL(window.location.href).searchParams.get('referal')
              let referalName = new URL(window.location.href).searchParams.get('name')
              loyalty.resKey = data.id
              this.props.setLoyalty({ ...storeSubscriptions?.loyalty, referal: referal ? { id: referal, name: referalName || '' } : false })
              if (referal && !auth().currentUser) {
                this.setState({ login: true })
              }
            } else {
              this.props.setLoyalty(false)
            }
          } else {
            this.props.setLoyalty(false)
          }

          let { branches, locationId } = this.props.selectedLocation ? this.props.selectedLocation : {}
          if (branches && (this.props.lastRes === data.id)) {
            if (locationId) {
              (async () => {
                let location = await firestore().collection('resturant').doc(data.id).collection('locations').doc(locationId).get();
                let locationData = location.data()
                if (locationData) {
                  let mainLocation = this.props.selectedLocation ? this.props.selectedLocation : {};
                  mainLocation.fees = locationData.fees
                  mainLocation.branches = locationData.branches
                  mainLocation.minOrder = locationData.minOrder
                  mainLocation.deliveryTime = locationData.deliveryTime

                  this.props.updateUserLocation(mainLocation)
                  unsubscribeBranch = firestore().collection('resturant').doc(data.id).onSnapshot(results => {
                    let allBranches = results.data().branches
                    mainLocation.active = allBranches[branches[0]].active
                    this.props.updateUserLocation(mainLocation)
                    let { activeMenu } = allBranches[branches[0]]
                    let { timings } = allBranches[branches[0]]
                    this.props.updateTimings(timings)

                    this.checkCopons(data.id, mainLocation.type)
                    this.loadMenu(branches[0], activeMenu, categoryMenu, mainLocation)
                  })
                  this.setState({ unsubscribeBranch })
                } else {
                  this.props.updateUserLocation(false)
                }


              })()


            } else {
              firestore().collection('resturant').doc(data.id).get().then(results => {
                let allBranches = results.data().branches;
                let { activeMenu } = allBranches[branches[0]]
                let { timings } = allBranches[branches[0]]
                this.props.updateTimings(timings)
                let mainLocation = this.props.selectedLocation ? { ...this.props.selectedLocation, active: allBranches[branches[0]].active } : {}
                if (mainLocation && mainLocation.type === 'dineIn') {
                  this.props.updateUserLocation(false)
                }
                this.checkCopons(data.id, this.props.selectedLocation.type)
                this.loadMenu(branches[0], activeMenu, categoryMenu, mainLocation)

              })
            }
          } else {
            if (window.location.href.includes('/menu-display')) {
              let branch = new URL(window.location.href).searchParams.get('branch');
              firestore().collection('resturant').doc(data.id).get().then(results => {
                let allBranches = results.data().branches;
                let { activeMenu } = allBranches?.[branch] || {}
                let { timings } = allBranches?.[branch] || {}
                if (timings) {
                  this.props.updateTimings(timings)
                }
                if (activeMenu) {
                  this.loadMenu(branch, activeMenu, categoryMenu, { type: 'dineIn' })
                }
                // this.checkCopons(data.id, this.props.selectedLocation.type)
              })

            } else {
              this.props.updateUserLocation(false)
              this.loadMenu(false, false, categoryMenu, false)
            }
          }

          this.setState({ config: result.data(), path: path, categoryMenu, direction })
        } else {
          this.setState({ loading: false, direction })
        }
      })
    })
  }

  checkLastOrdersRate(id) {
    let uid = auth().currentUser ? auth().currentUser.uid : false
    if (uid) {
      firestore().collection('resturant').doc(id).collection('orders')
        .where('created', '>', 1593561600000).where('userUid', '==', auth().currentUser.uid)
        .where('status', '==', 'delivered')
        .orderBy('created').limitToLast(1).onSnapshot((results => {
          results.forEach(record => {
            let data = record.data()
            if (!data.rated) {
              setTimeout(() => {
                this.setState({ rate: record.id })
              }, 3000)
            }
          })
        }))
    }
  }

  componentWillReceiveProps(prop) {
    const { selectedLocation } = this.props;
    let _this = this;
    let unsubscribeBranch;
    if ((prop.lastRes !== this.props.lastRes)) {
      this.props.flushOrders()
      this.props.updateUserLocation(false)
      return
    }

    if (prop.selectedLocation && (selectedLocation !== prop.selectedLocation)) {
      let { branches, activeMenu, type } = prop.selectedLocation;
      if (this.state.unsubscribeBranch) {
        this.state.unsubscribeBranch()
      }
      unsubscribeBranch = firestore().collection('resturant').doc(prop.lastRes).onSnapshot(results => {
        let allBranches = results.data().branches
        let { timings } = allBranches[branches?.[0]]
        _this.props.updateTimings(timings);
      })
      this.checkCopons(prop.lastRes, type);
      this.setState({ loading: true, unsubscribeBranch }, () => {
        this.loadMenu(branches[0], activeMenu, this.state.categoryMenu, prop.selectedLocation)
      })
    }
  }

  loadMenu(branch, activeMenu, categoryMenu, selectedLocation) {
    let { updateOrder, settings } = this.props
    if (!settings?.resKey) {
      return
    }

    if (window.location.href.includes('dinein')) {
      return
    }

    let { type } = selectedLocation ? selectedLocation : {}
    let { direction, unsbscribGroup } = this.state;

    unsbscribGroup.forEach(itemRef => {
      itemRef()
    })

    let ref = firestore().collection('resturant').doc(settings?.resKey).collection('menus').limit(1)
    if (branch) {
      ref = firestore().collection('resturant').doc(settings?.resKey).collection('menus').where('branches', 'array-contains', branch).limit(1)
    }
    let activeMenuKey = ((activeMenu && activeMenu[type]) ? activeMenu[type] : activeMenu);
    if (typeof (activeMenuKey) === 'string') {
      ref = firestore().collection('resturant').doc(settings?.resKey).collection('menus').doc(activeMenuKey)

      return ref.get().then(item => {
        if (item.data()) {
          let data = item.data()
          let { categouries } = data
          let mapItems = {}
          let menuItemsMap = {}
          let counter = 0
          // let categoriesIndex = 0
          Object.keys(categouries).map((key, index) => {
            let category = categouries[key]
            let newCategoury = {}
            newCategoury.category = category
            let { items } = category
            let innerCounter = 0;
            // if (categoryMenu) {
            //   newCategoury.loadItems = items.slice(10);
            //   items = (items && items.length) ? items.slice(0,10) : []
            // };

            items.forEach((itemKey, itemIndex) => {
              let menuItemRef = firestore().collection('resturant').doc(settings?.resKey).collection('menuItems').doc(itemKey).onSnapshot(itemResult => {
                if (itemResult.exists && itemResult.data()) {

                  counter++
                  let itemData = itemResult.data()

                  let { orderList } = this.props
                  let oldItem = orderList[itemResult.id]

                  itemData.id = itemResult.id
                  itemData.index = itemIndex
                  itemData.price = getPrice(itemData, type)
                  if (oldItem) {
                    orderList[itemResult.id] = { ...oldItem, ...itemData }
                    updateOrder(orderList)
                  }
                  switch (itemResult.data().hide) {
                    case true:
                      delete menuItemsMap[itemKey];
                      newCategoury.items = newCategoury.items ? newCategoury.items.filter(item => item.id !== itemResult.id) : []
                      break;
                    default:
                      menuItemsMap[itemKey] = itemData
                      if (newCategoury.items) {
                        newCategoury.items = newCategoury.items.filter(item => item.id !== itemKey);
                        newCategoury.items.push(itemData)
                      } else {
                        newCategoury.items = [itemData];
                      }
                      newCategoury.items.sort((a, b) => (Number(a.index) > Number(b.index)) ? 1 : -1);
                      break
                  }
                  mapItems[key] = newCategoury;

                }
                innerCounter++;
                if ((Object.keys(categouries).length === Object.keys(mapItems).length) && (innerCounter >= items.length)) {
                  let optimizedItems = Object.keys(mapItems).map(item => {
                    return mapItems[item]
                  })
                  optimizedItems.sort((a, b) => (Number(a.category.id) > Number(b.category.id)) ? 1 : -1)
                  this.props.persistMenu({ menu: optimizedItems, menuMap: menuItemsMap, categoryMenu: categoryMenu })
                  this.setState({ loading: false, menuItemsMap, unsbscribGroup }, () => {
                    window.dataLayer.push({
                      'userID': auth().currentUser ? auth().currentUser.uid : 'unknown',
                      'resKey': settings?.resKey,
                      'lang': direction,
                      'branch': branch,
                      'itemsCount': counter,
                      'event': 'MenuView'
                    })
                  })
                }
              })
              unsbscribGroup.push(menuItemRef)

            })
            this.setState({ unsbscribGroup })
            return false
          })
        }
      })
    }
    if (settings?.resKey) {
      this.props.persistMenu({})
      return this.setState({ loading: false });
    }
  }

  checkCopons(resKey, type) {
    this.getActiveookings(resKey)
    if (!type) {
      return
    }
    type = type.toLowerCase()
    firestore().collection('resturant').doc(resKey).collection('offers').where('active', '==', true).onSnapshot(offers => {
      if (offers.empty) {
        this.props.getOffers(false)
        return this.props.setHelpers({})
      }
      let activeOffers = offers.docs.filter(rec => {
        let data = rec.data()
        return (data?.allow.includes(type)) && (data.endDate > Date.now()) && (data.count || data.count === 0 ? data.count > 0 : true)
      })
      this.props.getOffers(activeOffers.map(rec => rec.data()))

      let global = activeOffers.filter(rec => rec.data().global === true)
      let standalone = activeOffers.filter(rec => rec.data().standalone === true)

      let currentGlobalOffers = (window.matchMedia('(display-mode: standalone)').matches) ? standalone : global
      if (!currentGlobalOffers.length) {
        return this.props.setHelpers({})
      }
      currentGlobalOffers?.forEach(results => {
        let data = results.data() ? results.data() : {};

        data.id = data.code
        let percent = data.percent
        data.coponeKey = results.id
        checkCoponeValid(resKey, results.id, data.limited).then(() => {
          this.props.setHelpers({ percent: percent, cap: data.cap, couponeCode: data.code, coupon: data, discountApplied: (100 - percent) / 100 })
        }).catch(() => { this.props.setHelpers({}) })
      })
    })
    // let ref = firestore().collection('resturant').doc(resKey).collection('offers').where('global', '==', true)
    // if (window.matchMedia('(display-mode: standalone)').matches) {
    //   ref = firestore().collection('resturant').doc(resKey).collection('offers').where('standalone', '==', true)
    // }
    // ref.get().then(offers => {
    //   if (offers.empty) {
    //     return this.props.setHelpers({})
    //   }
    //   offers.forEach(results => {
    //     let data = results.data() ? results.data() : {};
    //     if ((data.allow && !data.allow.includes(type)) || (data.endDate < Date.now())) {
    //       return this.props.setHelpers({})
    //     }
    //     data.id = data.code
    //     let percent = data.percent
    //     data.coponeKey = results.id
    //     checkCoponeValid(resKey, results.id, data.limited).then(() => {
    //       this.props.setHelpers({ percent: percent, cap: data.cap, couponeCode: data.code, coupon: data, discountApplied: (100 - percent) / 100 })
    //     }).catch(() => { this.props.setHelpers({}) })
    //   })
    // })
  }
  getActiveookings(key) {
    let _this = this
    let { loyalty } = this.props
    let strings = this.strings
    if (key) {
      auth().onAuthStateChanged(user => {
        if (user) {
          firestore().collection('users').doc(user.uid).onSnapshot(function (doc) {
            let data = doc.data()
            firestore().collection('resturant').doc(key).collection('loyalty').doc(user.uid).onSnapshot(loyaltyRes => {
              if (loyaltyRes.exists) {
                let referal = new URL(window.location.href).searchParams.get('referal')
                let result = loyaltyRes.data()
                if (referal && (referal !== user.uid)) {
                  if (result.referal) {
                    setTimeout(() => {
                      _this.props.sendNotif({ title: strings['Alert!'], msg: strings['You have already earned points'] })
                    }, 300);
                  } else {
                    if (loyalty?.viral) {
                      var reward = functions().httpsCallable('reward');
                      reward({ uid: user.uid, resID: key, loyalty: { ...loyalty, referal: referal } })
                        .then((result) => {
                          setTimeout(() => {
                            _this.props.sendNotif({
                              title: strings['Congrats'],
                              msg: <div><span style={{ fontFamily: 'poppins-bold,poppins-bold-ar' }}>{` ${loyalty.gift} ${window.currency}`}</span> {strings[' is added to your wallet for the next order 🎁 ']}</div>
                            })
                          }, 300);
                        });
                    }
                  }
                }
                if (result.notif && !result.notif?.seen) {
                  setTimeout(() => {
                    _this.props.sendNotif({ key: key, userUID: user.uid, remove: true, title: strings['Congrats'], msg: <div>{strings['You have earned']}<span style={{ fontFamily: 'poppins-bold,poppins-bold-ar' }}>{` ${result.notif.valu} ${window.currency}`} </span> {`${strings.from} ${result.notif.from}`} </div> })
                  }, 300);
                }
                if (result.exp > Date.now()) {
                  data.loyalty = result
                }
                _this.props.updateUserProperty({
                  id: user.uid,
                  info: data
                })
              } else {
                _this.props.updateUserProperty({
                  id: user.uid,
                  info: data
                })
              }
            })
          }, function (error) {
            _this.props.updateUserProperty(false)
          });

          firestore().collection('resturant').doc(key).collection('orders').where('userUid', '==', user.uid).where('status', 'in', ['new', 'accepted', 'delivery']).onSnapshot(snap => {
            let { setActiveOrder } = this.props
            if (snap.empty) {
              setActiveOrder(false)
              // this.props.updateUserProperty()
            }
            snap.forEach(result => {
              let data = result.data()
              data.id = result.id
              setActiveOrder(data)
              // this.props.updateUserProperty()
            })
          })
          firestore().collection('resturant').doc(key).collection('orders').where('status', '==', 'delivered').where('userUid', '==', user.uid).orderBy('created', 'desc').limit(5).get().then((res) => {
            let recentOrders = []
            res.forEach(rec => {
              recentOrders.push(rec.data())
            })
            this.props.updateRecentOrders(recentOrders)
          })
        } else {
          _this.props.updateUserProperty(false)
        }
      })
    }
  }


  render() {
    let { config, path, loading, defaultHosts, rate, login } = this.state
    let { notif, settings } = this.props
    // switch (loading) {
    //   case true:
    //     return (<Loader active />);
    //   default:
    let customDomain = defaultHosts.includes(window.location.hostname)
    switch (!!config) {
      case true:
        return (
          <div>
            {
              notif &&
              <Notification notif={notif} />
            }
            {
              settings?.alertMessage && <AlertModal />
            }
            {
              loading && <Dimmer active page>
                <Loader active />
              </Dimmer>

            }
            {
              login && <NewLogin
                referal={{ id: this.props.loyalty?.referal?.id, name: this.props.loyalty?.referal?.name }}
                dismiss={() => this.setState({ login: false })}
                success={() => this.setState({ login: false })}
              />
            }
            {
              rate && <div style={{ position: 'fixed', width: '100%', height: '100vh', zIndex: '9999' }}>
                <RatingPage orderId={rate} dismiss={() => this.setState({ rate: false })} />
              </div>
            }
            <Helmet titleTemplate={"%s | " + config.title} defaultTitle={config.title}>
              <meta name="description" content={config.description} />
              <meta property="og:image" content={config.appIcons["icon-152x152"]} />
              <meta property="og:image:width" content="1856" />
              <meta property="og:image:height" content="972" />
              <meta property="og:title" content={config.title} />
              <meta property="og:description" content={config.description} />
              <meta property="og:url" content={window.location.href} />

              <link rel="shortcut icon" href={config.appIcons["favicon"]} />
              <link rel="apple-touch-icon" sizes="57x57" href={config.appIcons["icon-57x57"]} />
              <link rel="apple-touch-icon" sizes="60x60" href={config.appIcons["icon-60x60"]} />
              <link rel="apple-touch-icon" sizes="72x72" href={config.appIcons["icon-72x72"]} />
              <link rel="apple-touch-icon" sizes="76x76" href={config.appIcons["icon-76x76"]} />
              <link rel="apple-touch-icon" sizes="114x114" href={config.appIcons["icon-114x114"]} />
              <link rel="apple-touch-icon" sizes="120x120" href={config.appIcons["icon-120x120"]} />
              <link rel="apple-touch-icon" sizes="144x144" href={config.appIcons["icon-144x144"]} />
              <link rel="apple-touch-icon" sizes="152x152" href={config.appIcons["icon-152x152"]} />
              <link rel="apple-touch-icon" sizes="180x180" href={config.appIcons["icon-180x180"]} />
              <link rel="icon" type="image/png" sizes="192x192" href={config.appIcons["icon-192x192"]} />
              <link rel="icon" type="image/png" sizes="32x32" href={config.appIcons["icon-32x32"]} />
              <link rel="icon" type="image/png" sizes="96x96" href={config.appIcons["icon-96x96"]} />
              <link rel="icon" type="image/png" sizes="16x16" href={config.appIcons["icon-16x16"]} />
              <meta name="msapplication-TileImage" content={config.appIcons["icon-144x144"]} />
              <meta name="msapplication-TileColor" content="#ffffff" />

              <meta name="apple-mobile-web-app-capable" content="yes" />
              <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

            </Helmet>
            <div >

              <div className='leftSide' id='leftSide'>
                <Switch>
                  <Route exact path={customDomain ? ("/" + path + "/cart") : '/cart'} component={Cart} />
                  <Route exact path={customDomain ? ("/" + path + "/profile") : "/profile"} component={Profile} />
                  <Route exact path={customDomain ? ("/" + path + "/order-status") : "/order-status"} component={OrdersStatus} />
                  <Route exact path={customDomain ? ("/" + path + "/rating") : "/rating"} component={RatingPage} />
                  <Route exact path={customDomain ? ("/" + path + "/menu-display") : "/menu-display"} component={MenuRes} />

                  <Route exact path={"**categories**"} component={ItemsList} />
                  <Route exact path={'**menu**'} param='itemId' component={MenuItem} />
                  <Route exact path={"**/dinein"} render={(prop) => {
                    this.props.updateUserLocation(false)
                    return (
                      <Menu dineIn={true} />
                    )
                  }
                  } />
                  <Route path={"/**"} component={Menu} />
                </Switch>

              </div>
              <div
                className='emptySpace'
                style={{
                  backgroundImage: `url("${settings?.sideImage}")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  position: 'fixed',
                  top: 0
                }} >

              </div>
            </div>
          </div>
        )
      default:
        if (loading) {
          return <Loader active />
        }
        return (
          <Switch>
            <Route path={'/'} component={FourOhFour} />
          </Switch>
        )

    }
    // }

  }
}

const FourOhFour = () => <h1>404</h1>;

function mapStateToProps({ orderList, userProp, loyalty, settings }) {

  return {
    orderList: orderList,
    user: userProp ? userProp.user : {},
    selectedLocation: userProp ? userProp.location : {},
    timings: userProp ? userProp.timings : {},
    lastRes: userProp ? userProp.lastRes : '',
    allLocations: userProp ? userProp.allLocations : [],
    menu: userProp ? userProp.menu : {},
    categoryMenu: userProp ? userProp.categoryMenu : false,
    loyalty: loyalty,
    helpers: userProp ? userProp.helpers : {},
    activeOrder: userProp ? userProp.activeOrder : false,
    notif: userProp.notif,
    settings

  }
}

const mapDispatchToProps = dispatch => ({
  flushOrders: () => dispatch(flushOrders()),
  updateUserProperty: (user) => dispatch(updateUserProperty(user)),
  updateUserLocation: (value) => dispatch(updateUserLocation(value)),
  updateTimings: (value) => dispatch(updateTimings(value)),
  setActiveOrder: (activeOrder) => dispatch(setActiveOrder(activeOrder)),
  persistMenu: (menu) => dispatch(persistMenu(menu)),
  updateRes: (resKey) => dispatch(updateRes(resKey)),
  setLoyalty: (loyal) => dispatch(setLoyalty(loyal)),
  setHelpers: (helpers) => dispatch(setHelpers(helpers)),
  updateRecentOrders: (list) => dispatch(updateRecentOrders(list)),
  getOffers: (list) => dispatch(getOffers(list)),
  sendNotif: (notif) => dispatch(sendNotif(notif)),
  updateOrder: (updatedList) => dispatch(updateOrder(updatedList)),
  setStoreSettings: (params) => dispatch(setStoreSettings(params))

});
export default connect(mapStateToProps, mapDispatchToProps)(App)

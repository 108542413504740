import { firestore, auth } from 'firebase'
import { updateOrder } from '../reducers/newOrder'
// function distance(location) {
//     if (!location) {
//         return null
//     }
//     let lat1 = location.lat;
//     let lon1 = location.lng;
//     var R = 6371; // km (change this constant to get miles)
//     var dLat = (25.1874804 - lat1) * Math.PI / 180;
//     var dLon = (55.2529987 - lon1) * Math.PI / 180;
//     var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//         Math.cos(lat1 * Math.PI / 180) * Math.cos(25.1874804 * Math.PI / 180) *
//         Math.sin(dLon / 2) * Math.sin(dLon / 2);
//     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     var d = R * c;
//     switch (true) {
//         case d < 6:
//             return 6
//         case d < 10:
//             return 10
//         case d < 40:
//             return 15
//         default: return null

//     }
// }
export function calcTotalCost(orderList) {
    let cost = 0
    Object.keys(orderList).map(item => {
        let orderedItem = orderList[item];
        let price = orderedItem.modifiedPrice ? orderedItem.modifiedPrice : orderedItem.price
        return cost = cost + (price * orderedItem.count)
    })
    return (cost)
}
export function getAddedExtras(orderItem) {
    let { price, selectedExtras, name, name_ar } = orderItem
    let direction = localStorage.getItem('direction')
    let optimizedItems = []
    selectedExtras && selectedExtras.map(extra => {
        let extraItem = getAddedExtrasOptions(extra, price, direction)
        let extraPrice = extraItem.price
        extraItem.addedExtras = extraItem.addedExtras.sort()
        return optimizedItems.push({ array: extraItem.addedExtras, price: extraPrice })
    })
    let counter = {}
    optimizedItems.forEach(function (obj) {
        var key = JSON.stringify(obj.array)
        counter[key] = { count: (((counter[key] && counter[key].count) || 0) + 1), price: obj.price }
    })
    let optimized = Object.keys(counter).map(item => {
        let addons = JSON.parse(item)
        return { addons, count: counter[item].count, price: counter[item].count * counter[item].price, name, name_ar }
    })
    return optimized
}

export function getAddedExtrasOptions(extraItems, itemPrice, direction) {
    let addons = []
    let price = itemPrice * 1
    Object.keys(extraItems).map(itemKey => {
        let itemArray = extraItems[itemKey]
        return itemArray.map(extraItem => {
            // addons[itemKey]?addons[itemKey].push(extraItem):addons[itemKey] = [extraItem]
            price = price + (extraItem.price * 1)
            return addons.push(direction === 'rtl' && extraItem.name_ar ? extraItem.name_ar : extraItem.name)
        })
    })


    // let reducedArray = [...new Set(addons)]
    return { addedExtras: addons, price: price.toFixed(2) }
    // return reducedArray.map(item => {
    //     let count = addons.filter(element => element === item).length
    //     return (item + ' x ' + count)
    // })
}

export function calcItemTotal(item) {
    let { selectedExtras } = item
    let cost = 0
    if (selectedExtras && selectedExtras.length) {
        selectedExtras.map(extraItems => {
            cost = cost + (item.price * 1);
            return Object.keys(extraItems).map(itemKey => {
                let itemArray = extraItems[itemKey]
                return itemArray.map(extraItem => {
                    return cost = cost + (extraItem.price ? Number(extraItem.price) : 0);
                })
            })
        })
        return cost.toFixed(2)
    } else {
        let price = item.price
        return (cost = cost + (price * item.count)).toFixed(2)
    }
}

export function calcGrandTotal(user, helpers, orderList, freeItem, selectedAddress, resLoyalty, settings) {
    let { percent, cap, couponeCode } = helpers || {}
    let state = {}
    let cost = 0
    let loyalty = user && user.info ? user.info.loyalty : false
    let excluded = 0
    Object.keys(orderList).map(item => {
        let itemPrice = 0;
        let orderedItem = orderList[item];
        let { selectedExtras } = orderedItem

        if (selectedExtras && selectedExtras.length) {
            selectedExtras.map(extraItems => {
                cost = cost + (orderedItem.price * 1);
                itemPrice = itemPrice + (orderedItem.price * 1);
                return Object.keys(extraItems).map(itemKey => {
                    let itemArray = extraItems[itemKey]
                    itemArray.map(extraItem => {
                        cost = cost + (extraItem.price ? Number(extraItem.price) : 0);
                        itemPrice = itemPrice + (extraItem.price ? Number(extraItem.price) : 0);
                        return false
                    })
                    return false
                })
            })
        } else {
            let price = orderedItem.price
            itemPrice = itemPrice + (price * orderedItem.count);
            cost = cost + (price * orderedItem.count)
        }
        if ((orderedItem.excluded && orderedItem.excluded[couponeCode])) {
            excluded = excluded + itemPrice
        }
        return false


    })
    let subTotal = cost;
    state.subTotal = subTotal.toFixed(2);
    if (percent) {
        let deductedValue = ((cost - excluded) * (percent / 100))
        deductedValue = cap && deductedValue > cap ? cap : deductedValue;
        state.deductedValue = parseFloat(deductedValue).toFixed(2);
        state.coponeType = 'code'
        cost = (cost - deductedValue);
    } else if (freeItem && orderList && orderList[freeItem.itemId] && orderList[freeItem.itemId].count > 1) {
        let deductedValue = freeItem.detail ? freeItem.detail.price : 0
        state.deductedValue = parseFloat(deductedValue).toFixed(2);
        cost = (cost - deductedValue);
    }

    if (loyalty && resLoyalty) {
        let { points, exp, referal } = loyalty
        if (exp > Date.now()) {
            let { capValue, capPercent } = resLoyalty
            let maxPoints = capPercent * cost / 100
            if (maxPoints > (capValue * 1)) {
                maxPoints = capValue
            }
            if (points < maxPoints) {
                maxPoints = points
            }
            if (!state.deductedValue) {
                state.coponeType = 'loyalty'
                state.pointsPalance = (points - maxPoints).toFixed(2) * 1;
                state.deductedValue = (maxPoints * 1).toFixed(2) * 1;
                cost = (cost - maxPoints);
            } else {
                state.pointsPalance = points ? points : 0;
            }
            if (referal) {
                state.referal = referal
            }
        }
    }
    state.cartTotal = cost.toFixed(2);
    let vat = (cost * settings?.vat);
    state.vat = vat.toFixed(2);
    let delivery = (selectedAddress && selectedAddress.fees && selectedAddress.fees !== "0") ? (selectedAddress.fees * 1) : false
    state.delivery = delivery
    state.type = selectedAddress && selectedAddress.type ? selectedAddress.type : 'delivery'
    // state.total = (cost + vat + (delivery ? delivery : 0)).toFixed(2)
    state.total = (cost + (delivery ? delivery : 0)).toFixed(2)
    state.selectedAddress = selectedAddress ? selectedAddress : (user && user.info) ? { area: user.info.area, address: user.info.address, fullAddress: user.info.fullAddress, location: user.info.location } : ''
    return (state)
}

export function applyCoupone(couponeCode, user, orderList, type, settings) {
    // let { couponeCode ,menuItems} = this.state
    // let { user ,orderList} = this.props
    return new Promise((success, fail) => {
        if (!user || !user.info) {
            success({ login: true, step: (!user || !user.info) ? 0 : (!user.info.name || !user.info.address || !user.info.area || !auth().currentUser.phoneNumber) ? 2 : 0 })
        }
        if (!couponeCode) {
            success({ error: 'Please enter valid promocode' })
        }


        return firestore().collection("resturant").doc(settings?.resKey).collection('offers').where('code', '==', couponeCode).get().then(result => {
            if (result.empty) {
                success({ error: 'Please enter valid promocode' })
                return
            }
            let couponeDetails = result.docs[result.docs.length - 1].data();
            couponeDetails.coponeKey = result.docs[result.docs.length - 1].id
            couponeDetails.id = couponeCode;

            if (!couponeDetails.active || (couponeDetails.endDate < Date.now()) || (couponeDetails.count === 0 || false)) {
                success({ error: 'This coupone has been expired' })
                return
            }
            if (couponeDetails.area && couponeDetails.area.length) {
                if (!couponeDetails.area.includes(user.info.area)) {
                    success({ error: 'This coupone is not applied on your area' })
                    return

                }
            }
            if (type && couponeDetails.allow && !couponeDetails.allow.includes(type)) {
                success({ error: 'This coupone is not valid for ' + type + ' orders' })
                return
            }

            switch (couponeDetails.type) {
                case 'oneForOne':
                    let freeItem = couponeDetails
                    if (freeItem.itemId) {
                        return firestore().collection("resturant").doc(settings?.resKey).collection('menuItems').doc(freeItem.itemId).get().then(result => {
                            freeItem.detail = result.data()
                            freeItem.detail.id = freeItem.itemId
                            freeItem.detail.count = 2
                            orderList[freeItem.detail.id] = freeItem.detail
                            checkCoponeValid(settings?.resKey, couponeDetails.coponeKey, couponeDetails.limited).then(() => {
                                updateOrder(orderList)
                                success({ freeItem, coupon: couponeDetails, percent: null, error: false })
                            }).catch(error => {
                                success({ error: error.message })
                            })

                        })
                    }
                    break
                case 'discount':
                    let percent = couponeDetails.percent;
                    let cap = couponeDetails.cap
                    checkCoponeValid(settings?.resKey, couponeDetails.coponeKey, couponeDetails.limited).then((res) => {
                        success({ percent, cap, coupon: couponeDetails, couponeCode: couponeDetails.code, discountApplied: ((100 - percent) / 100), freeItem: null, error: false })
                    }).catch(error => {
                        success({ error: error.message })
                    })

                    break
                default: break;
            }
        }).catch(error => {
            console.log("errorerror", error)
        })
    })

}
export function checkCoponeValid(resKey, coponeKey, limit) {
    return new Promise((success, fail) => {
        if (limit && Number(limit)) {
            if (auth().currentUser && auth().currentUser.uid) {

                firestore().collection("resturant").doc(resKey).collection('offers').doc(coponeKey).collection('redeemed').doc(auth().currentUser.uid).get().then(redeemed => {
                    let data = redeemed.data()

                    if (data && (((Object.keys(data).length >= Number(limit))) || (data.endDate < Date.now()))) {
                        fail({ message: 'This coupone has been expired' })
                    } else {
                        success()
                    }
                })
            } else {
                success()
            }
        } else {
            success()
        }
    })
}
function getTotalPriceOfItem(item) {
    let price = item.price * 1
    if (item.selectedExtras && item.selectedExtras.length) {
        item.selectedExtras.map((extraIndex) => {
            return (
                Object.keys(extraIndex).map((categoryKey) => {
                    return (
                        extraIndex[categoryKey].map(innerItem => {
                            return (
                                price = price + (innerItem.price * 1)
                            )
                        })
                    )
                })
            )
        })

        return price
    } else {
        return price * item.count
    }
}

export function placeOrder(orderList, user, freeItem, percent, coupon, receipt, authId, node, settings, orderId, paymentIntent) {
    return new Promise((success, fail) => {
        let itemsList = []
        if (user && user.info && user.info.number && receipt && receipt.selectedAddress) {
            if (!receipt?.selectedAddress?.active) {
                receipt.selectedAddress.active = true
            }

            if (orderList) {
                Object.keys(orderList).map(orderedItemKey => {
                    let orderedItem = orderList[orderedItemKey]
                    itemsList.push({
                        id: orderedItemKey,
                        key: orderedItemKey,
                        name: orderedItem?.name,
                        name_ar: orderedItem?.name_ar,
                        count: orderedItem?.count,
                        quantity: orderedItem?.count,
                        price: orderedItem?.price,
                        totalPrice: getTotalPriceOfItem(orderedItem),
                        extra: orderedItem?.selectedExtras ? orderedItem.selectedExtras : null,
                        // specialNotes: orderedItem.specialNotes ? orderedItem.specialNotes : ''
                    });
                    return false
                })
                let post = {
                    items: itemsList,
                    // category: 'food',
                    user: {
                        key: auth().currentUser?.uid,
                        name: user.info.name ? user.info.name : user.customer ? user.customer.first_name : auth().currentUser.phoneNumber,
                        number: auth().currentUser.phoneNumber,
                        customer: user.customer ? user.customer : {},
                        // address: [user.info.address, user.info.fullAddress].join(" / "),
                        // area: user.info.area,
                        email: user.info.email ? user.info.email : '',
                    },
                    status: 'new',
                    created: Date.now(),
                    delivery: receipt?.delivery || '',
                    receipt,
                    storeType: process.env.REACT_APP_NAME,
                    branch: receipt?.branch || [],
                    res: settings?.resKey,
                    resID: settings?.resDocId,
                    stripe_account: settings?.stripe_id || false,
                    userUid: auth().currentUser.uid,
                    type: receipt?.type || '',
                    coupon: coupon ? {
                        id: coupon.id,
                        type: coupon.type,
                        value: percent ? percent : freeItem ? freeItem.detail.name : null,
                        detail: freeItem ? freeItem.detail : null
                    } : null,
                }
                if (authId) {
                    post.authId = authId
                }
                if (paymentIntent) {
                    post.paymentIntent = paymentIntent?.id;
                    post.payment_id = paymentIntent?.payment_id;
                }

                return firestore().collection("resturant").doc(settings?.resKey).collection(node).doc(orderId).set(post).then((dd) => {
                    window.dataLayer.push({
                        'userID': auth().currentUser.uid,
                        "ecommerce": {
                            'purchase': {
                                'actionField': {
                                    'id': orderId,
                                    'affiliation': 'Online Store',
                                    'revenue': receipt.total,
                                    'tax': receipt.vat,
                                    'shipping': receipt.delivery,
                                    'coupon': coupon ? coupon.id : ''
                                },
                                'products': itemsList,
                            },
                        },
                        'resKey': settings?.resKey,
                        'resName': settings?.resName,
                        'event': 'newOrder'
                    })
                    if (coupon) {
                        firestore().collection("resturant").doc(settings?.resKey).collection('offers').doc(coupon.coponeKey).collection('redeemed').doc(auth().currentUser.uid).set({
                            [orderId]: Date.now()
                        }, { merge: true }).then(() => {
                            console.log("order updated successfully", orderId)
                            success({ step: node === 'orders' ? 3 : 2, receipt: null, placeOrder: node === 'orders', loading: false, orderId: orderId })
                        }).catch(error => {
                            console.log("errorerror", error);
                        })
                    } else {
                        console.log("order updated successfully", orderId)
                        success({ step: node === 'orders' ? 3 : 2, receipt: null, placeOrder: node === 'orders', loading: false, orderId: orderId })
                    }

                }).catch(error => {
                    console.log("errorerrorerror", error);
                    success({ error: "Error while booking,please try again", loading: false })
                })
            }
        } else {
            success({ error: "error", loading: false })
        }
    })
}
export function CheckAddresses(addresses, allLocations, selectedLocation) {
    let activeKey = selectedLocation && selectedLocation.location && selectedLocation.location.name
    if (addresses) {
        let filteredAddresses = {}
        Object.keys(addresses).map(key => {
            let addressObject = addresses[key]
            let included = allLocations.filter(item => item.text.name ?
                ((item.text.name === addressObject.area) ||
                    (item.text.name === addressObject.area.name) ||
                    (item.text.name_ar === addressObject.area) ||
                    (item.text.name_ar === addressObject.area.name_ar)
                ) : item.text === addressObject.area).length > 0
            if (included) {
                activeKey = ((activeKey === addressObject.area.name) || (activeKey === addressObject.area)) ? key : activeKey;
                return filteredAddresses[key] = addressObject
            } else {
                addressObject.disabled = true
                return filteredAddresses[key] = addressObject
            }
        })
        addresses = Object.keys(filteredAddresses).length ? filteredAddresses : {}
    }
    return { addresses, activeKey }

}
export function validateUser(user, receipt, selectedLocation, allLocations, settings) {
    const direction = localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl';
    if (selectedLocation.minOrder && receipt.total < selectedLocation.minOrder) {
        return ({ loading: false, errorMinOrder: (direction === 'rtl' ? 'الحد الادنى للطلب في هذه المنطقة ' : 'minimum order to this area should be over ') + selectedLocation.minOrder + ' ' + settings?.currency })
    }
    if (!user || !auth().currentUser || !user.info) {
        return ({ login: true, step: 0, receipt, loading: false, changeTable: false })
    }
    // else if (!user.info || !user.info.name || !user.info.address || !user.info.area || !auth.currentUser.phoneNumber) {
    //     return ({ edit: true, receipt })
    // } 
    // else if (!receipt.delivery) {
    //     return ({ login: false, error: "Unfortunately, we are not delivering to '" + user.info.area + "' yet" })
    // }
    if (selectedLocation.type === 'pickup' || selectedLocation.type === 'dineIn') {
        return ({
            login: false,
            loading: false,
            errorMinOrder: false,
            pageStep: user.info.email && user.info.name ? 1 : 2,
            selectedAddress: selectedLocation,
            addresses: [],
            changeTable: false
            // selectAddress: !(user && user.info && user.info.addresses)
        })
    }

    else {
        let userAddresses = user.info ? user.info.addresses : false
        let { addresses, activeKey } = CheckAddresses(userAddresses, allLocations, selectedLocation);
        // if (addresses) {
        //     let filteredAddresses = {}
        //     Object.keys(addresses).map(key => {
        //         let addressObject = addresses[key]
        //         let included = allLocations.filter(item => item.text.name ?
        //             ((item.text.name === addressObject.area) ||
        //                 (item.text.name === addressObject.area.name) ||
        //                 (item.text.name_ar === addressObject.area) ||
        //                 (item.text.name_ar === addressObject.area.name_ar)
        //             ) : item.text === addressObject.area).length > 0
        //         if (included) {
        //             activeKey = ((activeKey === addressObject.area.name) || (activeKey === addressObject.area)) ? key : activeKey;
        //             return filteredAddresses[key] = addressObject
        //         } else {
        //             addressObject.disabled = true
        //             return filteredAddresses[key] = addressObject
        //         }
        //     })
        //     addresses = Object.keys(filteredAddresses).length ? filteredAddresses : false
        // }
        // selectedLocation.area === addresses.filter()
        return ({
            login: false,
            loading: false,
            errorMinOrder: false,
            // pageStep: (user && addresses && addresses[activeKey]) ? 1 : 1,
            pageStep: 1,
            selectedAddress: user && addresses && addresses[activeKey],
            addresses: addresses,
            cardObject: (user && user.info && user.info.card) ? user.info.card : {},
            changeTable: false,
            selectAddress: !(user && addresses && addresses[activeKey])
        })

    }
}
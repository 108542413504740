import React from 'react';
import { Icon } from 'semantic-ui-react';

const PaymentCard = ({ onClick, last_four, selected }) => {
    return (
        <div style={{
            margin: '16px 0',
            // width: '250px',
            padding: '12px 6px',
            // border: '1px solid',
            borderRadius: '6px',
            // direction: 'ltr',
            display: 'flex',
            background: 'rgba(246, 246, 246, 1)',
            justifyContent: 'space-between'
        }} onClick={onClick}>
            <div style={{ display: 'flex', width: '95%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* <div>{customerObject.first_name + " " + customerObject.last_name}</div> */}
                    <Icon
                        floated='right'
                        name='credit card outline'
                        size='large'
                    />
                </div>
                {/* <div style={{ padding: '0 12px', fontSize: '10px', color: 'gray' }}>
                        {card.brand}
                    </div> */}
                <div style={{ padding: '0 12px', fontSize: '14px', letterSpacing: '1px' }}>
                    {"XXXX " + last_four}
                </div>

            </div>

            {
                selected && <Icon
                    // floated='right'
                    name='check circle'
                    size='large'
                    style={{ color: 'var(--themeColor)' }}
                />

            }
            <p id="msg"></p>
        </div>
    );
};

export default PaymentCard;
import { Dimmer, Icon, Loader } from 'semantic-ui-react'
import React, { Component } from 'react';
import './loyalty.css'
import LocalizedStrings from 'react-localization';
import { auth, firestore, functions } from 'firebase'
import Slider from "react-slick";
import moment from 'moment';
import firstImg from '../img/firstStep.jpg'
import secondImg from '../img/secondStep.jpg'
import { ReactComponent as ReactLogo } from '../../Login/img/times.svg';

class LoyaltyTerms extends Component {
    strings = new LocalizedStrings({
        en: {
            loyaltyProgram: 'Loyalty program',
            loyaltyDescription: 'Earn points every time you have a successful order from ',
            howItWorks: 'How it works 🤔',
            shareAndEarn: "Share&Earn",
            inviteYourFrinds: "👉Invite your friends and receive ",
            forEachSucccessful: " for each successful order they make",
            get: '👉 Get ',
            "Invited friends will receive ": "👉Invited friends will receive ",
            inYourWallet: '% in your wallet as loyalty points',
            "in your wallet": "in your wallet",
            inThirWallet: ' In their wallet',
            redeemYourPoints: '👉 Redeem your points to get up to ',
            discountOff: '% off on your next order',
            maxuimum: '👉 Maxuimum ',
            perOrder: ' per order',
            eachPoint: '👉 Each point is valid for ',
            "Share with Whatsapp": "Share with Whatsapp",
            "Activate viral voucher program and get instant money in your wallet": "Activate viral voucher program and get instant money in your wallet",
            "For each friend you invite": "You will get {cashBack} {currency} for each friend you invite. And they get {gift} {currency} too 🤩",
            "Activate the loyalty program": "👉 Activate the loyalty program",
            "Receive 25 EGP instantly on your wallet": "👉 Receive {gift} {currency} instantly in your wallet",
            "Added to your wallet once activated": "Added to your wallet once activated",
            "Activate": "Activate",
            "Extra!": "Extra!",
            "Earn now 🤩": "Earn now 🤩",
            "One click and you will receive your gift": "One click and you will receive your gift",
            "Link copied to your clipboard": 'Link copied to your clipboard',
            login: "Login",
            copy: "Copy",
            days: ' days',
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",

        },
        ar: {
            loyaltyProgram: 'برنامج الولاء',
            loyaltyDescription: 'احصل على نقاط في كل مرة تطلب فيها طلب من ',
            howItWorks: 'كيف يعمل 🤔',
            shareAndEarn: "شارك و اكسب",
            inviteYourFrinds: "👈 قم بدعوة أصدقائك واستقبل ",
            forEachSucccessful: " لكل طلب ناجح ",
            get: '👈 احصل على ',
            inYourWallet: '٪ في محفظتك كنقاط ولاء',
            "in your wallet": "في محفظتك كنقاط ولاء",

            inThirWallet: ' في محفظتهم',
            "Invited friends will receive ": "👈 سوف يتلقى الأصدقاء المدعوون على ",
            redeemYourPoints: '👈 استبدل نقاطك لتحصل على ما يصل الى ',
            discountOff: '٪ خصم على طلبك التالي',
            maxuimum: '👈 الحد الأقصى ',
            perOrder: ' لكل طلب',
            eachPoint: '👈 كل نقطة صالحة حتى ',
            login: "تسجيل الدخول",

            "Activate viral voucher program and get instant money in your wallet": "قم تفعيل برنامج الولاء واحصل على أموال فورية في محفظتك",
            "Share with Whatsapp": "شارك مع Whatsapp",
            "For each friend you invite": "ستحصل على {cashBack} {currency} في محفظتك لكل صديق تقوم بدعوته وسيحصلون على {gift} {currency}",
            "Activate the loyalty program": "👈 تفعيل برنامج الولاء",
            "Receive 25 EGP instantly on your wallet": "👈 احصل على {gift} {currency} على محفظتك بشكل فوري",
            "Added to your wallet once activated": "ستضاف إلى محفظتك بمجرد تفعيلها",
            "Activate": "تفعيل",
            "Extra!": "إضافية",
            "Earn now 🤩": "اكسب الآن 🤩",
            "One click and you will receive your gift": "ضغطة واحدة لاستلام المزيد من النقاط",
            "Link copied to your clipboard": 'تم نسخ الرابط إلى الحافظة الخاصة بك',
            copy: "نسخ",
            days: ' يوم',
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",

        }

    })
    state = {
        loading: true
    }
    createLink = async (user) => {
        let _this = this
        var createViralLink = functions().httpsCallable('createViralLink');
        console.log(window.pathName, window.menuLogo, window.resName)
        let imageLink = window.menuLogo
        imageLink = imageLink?.replace(/=/g, '%3D').replace(/%2/g, '%252').replace(/&/g, '%26')
        await createViralLink({ name: user?.info?.name || '', uid: auth().currentUser?.uid, path: (window.pathName ? `/${window.pathName}` : false), si: imageLink, st: window.resName })
            .then((result) => {
                console.log('createViralLink', result)
                _this.setState({ link: result?.data?.shortLink, loading: false })
            });
    }
    componentWillMount() {
        let { direction, loyalty, user } = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
        if (user && loyalty?.viral) {
            this.createLink(user)
        }
        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden'

    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'

    }
    render() {
        let { dismiss, loyalty, user } = this.props
        let { loading, link } = this.state
        if (user && !link && loyalty?.viral) {
            this.createLink(user)
        }

        let viral = loyalty && loyalty.viral
        let strings = this.strings
        if (viral) {
            return this.renderViral()
        }
        return (
            <Dimmer active className='loyalty' page>
                <div className='popContainer' style={{backgroundColor:'#fff'}}>
                    <div style={{ textAlign: "initial", padding: '16px' }} onClick={() => {
                        dismiss()
                    }}>
                        <ReactLogo />
                        {/* <Icon corner='top left' name='remove'></Icon> */}

                    </div>

                    <div style={{ padding: '24px 0 30px' }}>
                        <img className='resLogo' src={window.menuLogo} alt="" />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className='loyaltyHeader bolded'>{strings.loyaltyProgram}</div>
                            <div className='loyaltyContent' style={{ width: '80%' }}>{strings.loyaltyDescription + window.resName}</div>

                        </div>
                    </div>
                    <div style={{ textAlign: 'initial', padding: '24px', backgroundColor: 'var(--themeColorOpacity10)' }}>
                        <div className='loyaltyHeader bolded'>{strings.howItWorks}</div>
                        <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings.get + loyalty.percent + strings.inYourWallet}</div>
                        <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings.redeemYourPoints + loyalty.capPercent + strings.discountOff}</div>
                        <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings.maxuimum + strings[window.currency] + loyalty.capValue + strings.perOrder}</div>
                        <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings.eachPoint + loyalty.expiration + strings.days}</div>
                    </div>
                    {
                        loyalty && loyalty?.viral && user && <div style={{ textAlign: 'initial', padding: '0 24px 24px', backgroundColor: 'var(--themeColorOpacity10)' }}>
                            <div className='loyaltyHeader bolded'>{strings.shareAndEarn}</div>
                            <div className='loyaltyContent'> {strings.inviteYourFrinds + loyalty.cashBack + strings[window.currency] + strings.forEachSucccessful}</div>
                            <div className='loyaltyContent'> {strings["Invited friends will receive "] + loyalty.gift + strings[window.currency] + strings.inThirWallet}</div>
                            <div style={{ color: 'var(--themeColor)', backgroundColor: 'var(--themeColorOpacity15)', borderRadius: '8px', border: '1px dashed', padding: '24px 8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {
                                    loading ?
                                        <Loader inline='centered' active={true} />
                                        :
                                        <div> {link}</div>

                                }
                                <div
                                    onClick={() => {
                                        navigator.clipboard.writeText(link)
                                    }}
                                    style={{ display: 'flex', color: 'var(--themeColor)' }}>
                                    <Icon name='copy outline' />
                                    {strings.copy}
                                </div>
                            </div>
                            <div
                                style={{ display: 'flex', alignItems: 'center', padding: '12px', justifyContent: 'center' }}
                                onClick={() => {
                                    let url = new URL(`whatsapp://send?text=${link}`)
                                    window.open(url, '_blank')
                                }}>
                                <div style={{ textDecoration: 'underline' }}>
                                    {strings["Share with Whatsapp"]}
                                </div>
                                <Icon style={{ fontSize: '18px' }} name='whatsapp' />
                            </div>
                        </div>
                    }

                </div>

            </Dimmer>
        )
    }
    renderViral() {
        let { dismiss, loyalty, user, direction } = this.props
        let strings = this.strings
        let { link, loading } = this.state
        const settings = {
            dots: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        let data = [
            {
                id: 1,
                title: <div className='loyaltyHeader'  ><span className='bolded'>{loyalty.gift} {strings[window.currency]} </span> {strings['in your wallet']} </div>,
                detail: strings['Activate viral voucher program and get instant money in your wallet'],
                img: firstImg
            },
            {
                id: 2,
                title: <div className='loyaltyHeader'> <span className='bolded'>{`${loyalty.cashBack} ${strings[window.currency]}`}</span> {strings['Extra!']} </div>,
                detail: strings.formatString(strings['For each friend you invite'], { currency: strings[window.currency], gift: loyalty.gift, cashBack: loyalty.cashBack }),
                img: secondImg
            },
            {
                id: 3, title: <div className='loyaltyHeader bolded'> {strings['Earn now 🤩']}</div>, detail: strings["One click and you will receive your gift"]
            },
        ]
        const Slide = ({ index }) => {
            return (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'var(themeColorOpacity10)', direction: direction }}>
                    {
                        index === 2 ?
                            <div style={{
                                paddingTop: '91%',
                                width: '100%',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                borderRadius: '16px 16px 0 0'
                            }}>
                                <div style={{ textAlign: 'initial', padding: '24px 16px', position: 'absolute', top: '0', width: '33%' }}>
                                    <div className='loyaltyHeader bolded' style={{ color: 'var(--themeColor)' }}>{strings.shareAndEarn}</div>
                                    <div className='loyaltyHeader bolded' style={{ fontSize: '18px' }}>{strings.howItWorks}</div>
                                    <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings['Activate the loyalty program']}</div>
                                    <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings.formatString(strings['Receive 25 EGP instantly on your wallet'], { currency: strings[window.currency], gift: loyalty.gift, cashBack: loyalty.cashBack })}</div>
                                    <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings.inviteYourFrinds + loyalty.cashBack + strings[window.currency] + strings.forEachSucccessful}</div>
                                    <div className='loyaltyContent' style={{ padding: '8px 0' }}> {strings["Invited friends will receive "] + loyalty.gift + strings[window.currency] + strings.inThirWallet}</div>
                                </div>

                                <div style={{ padding: '8px', direction: 'ltr' }}>
                                    {
                                        [0, 1, 2].map(itm => <Icon style={{ color: 'var(--themeColor)', fontSize: '10px' }} name={index === itm ? 'circle' : 'circle outline'} />)
                                    }
                                </div>
                            </div>


                            :
                            <div style={{
                                paddingTop: '91%',
                                width: '100%',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                width: '100%',
                                backgroundImage: `url(${data[index]?.img})`,
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'center',
                                borderRadius: '16px 16px 0 0'
                            }}>
                                <div style={{ padding: '8px', direction: 'ltr' }}>
                                    {
                                        [0, 1, 2].map(itm => <Icon key={itm} style={{ color: 'var(--themeColor)', fontSize: '10px' }} name={index === itm ? 'circle' : 'circle outline'} />)
                                    }
                                </div>

                            </div>

                    }

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {data[index]?.title}
                        {/* <div className='loyaltyHeader'>{data[index]?.title}</div> */}
                        <div className='loyaltyContent' style={{ width: '80%' }}>{data[index]?.detail}</div>

                    </div>
                </div>
            )
        }
        return (
            <Dimmer active className='loyalty' page>

                <div className='popContainer' >
                    <div style={{ backgroundColor: 'var(--themeColorOpacity10)', minHeight: '82vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ position: 'absolute', zIndex: '99', textAlign: "initial", padding: '18px', left: (direction === 'rtl' ? '0' : 'auto'), right: (direction === 'rtl' ? 'auto' : '0') }} onClick={() => {
                            dismiss()
                        }}>
                            <ReactLogo />

                        </div>
                        <div>
                            <Slider {...settings}>
                                {
                                    [0, 1, 2].map(rec => <Slide index={rec} />)
                                }


                            </Slider>
                        </div>
                        {
                            user?.info?.loyalty?.referal ?
                                <div style={{ padding: '12px 12px 50px' }}>
                                    <div style={{
                                        color: 'var(--themeColor)',
                                        backgroundColor: 'var(--themeColorOpacity15)',
                                        borderRadius: '8px', border: '1px dashed',
                                        padding: '24px 8px', display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        direction: 'ltr'
                                    }}>
                                        {
                                            loading ?
                                                <Loader inline='centered' active={true} />
                                                :
                                                <div> {link}</div>

                                        }
                                        <div
                                            onClick={() => {
                                                navigator.clipboard.writeText(link)
                                                this.props.sendNotif({ msg: strings['Link copied to your clipboard'] })
                                            }}
                                            style={{ display: 'flex', color: 'var(--themeColor)' }}>
                                            <Icon name='copy outline' />
                                            {strings.copy}
                                        </div>
                                    </div>
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', padding: '12px', justifyContent: 'center' }}
                                        onClick={() => {
                                            let url = new URL(`whatsapp://send?text=${link}`)
                                            window.open(url, '_blank')
                                        }}>
                                        <div style={{ textDecoration: 'underline' }}>
                                            {strings["Share with Whatsapp"]}
                                        </div>
                                        <Icon style={{ fontSize: '18px' }} name='whatsapp' />
                                    </div>
                                </div>
                                :
                                <div style={{
                                    padding: '16px 12px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    backgroundColor: 'var(--themeColor)',
                                    color: '#fff',
                                    textAlign: 'initial',
                                    margin: '12px 12px 50px',
                                    borderRadius: '8px'
                                }}>
                                    <div>
                                        <div style={{ fontWeight: '600', fontSize: '18px', paddingBottom: '4px' }}>{`${loyalty.gift} ${strings[window.currency]}`}</div>
                                        <div>{strings['Added to your wallet once activated']}</div>
                                    </div>
                                    <div style={{
                                        backgroundColor: '#fff',
                                        color: 'var(--themeColor)',
                                        borderRadius: '12px',
                                        padding: '8px 12px',
                                        cursor: 'pointer',
                                        fontWeight: '600'
                                    }}
                                        onClick={() => {
                                            if (!user) {
                                                return this.props.login()
                                            }
                                            this.setState({ loading: true }, async () => {
                                                await firestore().collection('resturant').doc(window.resKey).collection('loyalty').doc(user?.id).set({
                                                    referal: 'self',
                                                    points: loyalty.gift,
                                                    exp: moment().add(loyalty.expiration, 'day').valueOf(),
                                                    created: Date.now()
                                                })
                                                this.createLink(user)

                                            })

                                        }}
                                    >
                                        {user ? strings.Activate : strings.login}
                                    </div>

                                </div>
                        }

                    </div>


                </div>



            </Dimmer>
        )

    }



}

export default LoyaltyTerms
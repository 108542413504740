import React, { Component } from 'react';
import './offlineBanner.css'
import { strings } from '../../../helpers/translation';
const Banner = () => {
    return (
        <div className='showBanner'>
            <div className='banner'>{strings.offlinMsg}
            </div>
        </div>
    );
};

export default Banner;

import { createAction } from 'redux-action';
export const LOYALTY_OBJECT = 'LOYALTY_OBJECT';
export const setLoyalty = createAction(LOYALTY_OBJECT, (loyal) => {
    return loyal
})

const initialState = {
    // newOrder: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOYALTY_OBJECT:
            return action.payload;
        default:
            return state;
    }
};
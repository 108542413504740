import { createAction } from 'redux-action';

export const RECENT_ORDERS = 'RECENT_ORDERS';
export const updateRecentOrders = createAction(RECENT_ORDERS, (order) => {
    return order
})


export default (state = [], action) => {
    switch (action.type) {
        case RECENT_ORDERS:
            return action.payload
        default:
            return state;
    }
};
import React, { useEffect, useState } from 'react';
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import './getApp.css'
const GetApp = ({ user, direction, loyalty, dismiss, login, sendNotif }) => {
    const [step, setStep] = useState(0)
    useEffect(() => {
        setTimeout(() => {
            setStep(1)
        }, 3000)
    }, [])
    return (
        <div className='loyalty'>
            {
                step === 1 ?
                    <Step1 loyalty={loyalty} dismiss={dismiss} direction={direction} login={() => login()} setStep={(e) => { setStep(2) }} /> :
                    step === 2 ?
                        <Step2 loyalty={loyalty} dismiss={dismiss} direction={direction} /> : ''
            }
        </div>
    );
};

export default GetApp;
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import LogRocket from 'logrocket';
import { auth } from './fire'
if (!window.location.href.includes("localhost") && !window.location.href.includes('food2order-test.firebaseapp')) {
  LogRocket.init('q7b1lu/food2order');
  // LogRocket.init('1ppsbu/food2order-clinet');
  // LogRocket.init('gljfvm/food');
}
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/sw.js').then(function (registration) {
    // console.log('ServiceWorker registration successful with scope:', registration.scope);
  }).catch(function (error) {
    console.log('ServiceWorker registration failed:', error);
  });
}

auth().onAuthStateChanged((user) => {
  if (user) {
    window.dataLayer.push({ 'userID': user.uid })
    LogRocket.identify(user.uid, {
      name: user.uid,
      email: user.email,
      resKey: window.resKey,
      // Add your own custom user variables here, ie:
      subscriptionType: 'restaurant'
    });
  }
})

const routes = (

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>

    </PersistGate>

  </Provider>
);
render(routes, document.getElementById('root'));
// serviceWorker.unregister();

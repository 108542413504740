import React, { Component } from 'react';
import './btn.css'
import { connect } from 'react-redux';
import { updateOrder } from '../../../reducers/newOrder'
import { Dimmer } from 'semantic-ui-react'
class Btn extends Component {
    state = {
        addedItem: this.props.addedItem ? this.props.addedItem : false,
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
    }

    handleCounter(type, count) {
        let { menuItem, orderList, buthcer } = this.props

        if (menuItem && menuItem.modifierList && menuItem.modifierList.length) {
            menuItem.count = count
            menuItem.removeItem = !type
            if (!type && count === 1) {
                delete orderList[menuItem.id];
                this.props.updateOrder(orderList);
                return
            }
            let prevExtras = orderList && orderList[menuItem.id] && orderList[menuItem.id].selectedExtras;
            if (prevExtras) {
                menuItem.selectedExtras = prevExtras
            }
            return this.props.showExtras(menuItem)
        }

        if (type) {
            if (buthcer) {
                count += 0.5
            } else {
                count++
            }
            menuItem.count = count
            orderList[menuItem.id] = menuItem
            this.props.updateOrder(orderList)
            return
        } else {
            if (buthcer ? count === 0.5 : count === 1) {
                menuItem.count = 0
                delete orderList[menuItem.id]
                this.props.updateOrder(orderList)
                return
            }
            if (buthcer) {
                count -= 0.5
            } else {
                count--
            }
            let prevExtras = orderList && orderList[menuItem.id] && orderList[menuItem.id].selectedExtras;
            if (prevExtras) {
                prevExtras.pop()
                menuItem.selectedExtras = prevExtras
            }

            menuItem.count = count
            orderList[menuItem.id] = menuItem
            this.props.updateOrder(orderList)
        }

    }

    render() {
        let { menuItem, inverted, orderList, counter, disabled } = this.props
        let count = orderList && orderList[menuItem.id] ? orderList[menuItem.id].count : 0;
        let direction = localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl'
        return (
            count !== 0 ?

                <div className={inverted ? 'btnContainerInv' : 'btnContainer'}>
                    <div className={inverted ? 'decreaseInv' : 'decrease'} onClick={() => this.handleCounter(false, count)}>-</div>
                    <div className={inverted ? 'counterInv' : 'counter'} >{counter ? counter : count}</div>
                    <div className={inverted ? 'increaseInv' : 'increase'} onClick={() => this.handleCounter(true, count)}>+</div>
                </div>
                :
                <>
                    <Dimmer active={this.state.showDisabled} page onClick={() => this.setState({ showDisabled: false })}>
                        <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '8px', padding: '24px', color: 'rgb(74, 74, 74)' }}>{
                            direction === 'rtl' ?
                                <>
                                    <div style={{fontSize:'16px',paddingBottom:'8px'}}>
                                        الطلب التلقائي موقوف حالياً
                                    </div>
                                    <div>الرجاء طلب المساعدة من طاقم المطعم</div>
                                </>
                                :
                                <>
                                    <div style={{fontSize:'16px',paddingBottom:'8px'}}>
                                        Self ordering is currently disabled
                                    </div>
                                    <div>Kindly ask the waiter to take your order</div>
                                </>
                        }</div>
                    </Dimmer>



                    <div className='btnContainer' onClick={() => {
                        if (disabled) {
                            document.body.style.pointerEvents = 'none'

                            this.setState({ showDisabled: true }, () => {
                                setTimeout(() => {
                                    document.body.style.pointerEvents = 'auto'
                                    this.setState({ showDisabled: false })
                                }, 3000);
                            })
                            return
                        }
                        this.handleCounter(true, 0)
                    }}>


                        <div className='increase' style={{ fontSize: '11px', padding: '8px 10px', fontWeight: '200' }} >{direction === 'rtl' ? 'اضافة +' : 'ADD +'}</div>
                    </div>
                </>
        )
    }
}
function mapStateToProps(state) {
    return {
        orderList: state.orderList,
    }
}

const mapDispatchToProps = dispatch => ({
    updateOrder: (updatedList) => dispatch(updateOrder(updatedList)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Btn);

import React, { useState } from 'react';
import { localizeString } from '../../../helpers';
import { Button, Icon, Image, Placeholder } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrder } from '../../../reducers/newOrder';
export const MenuItem = ({ data = {}, }) => {
    const { pictures, price } = data;
    const [loading, setLoading] = useState(pictures?.length ? true : false)
    let image = pictures?.[pictures?.length - 1];
    return (
        <div className='listItem' style={{ border: 'none', color: '#4a4a4a', borderRadius: '8px', padding: '0', justifyContent: 'normal', display: 'flex', flexDirection: 'column', overflow: 'hidden', boxShadow: '0 0 8px 4px #cacaca20' }}>
            <div style={{ aspectRatio: '1', width: '100%', position: 'relative' }}>
                {
                    loading ? <div style={{ width: '100%', aspectRatio: '1', objectFit: 'cover', position: 'absolute', zIndex: 999 }}>
                        <Placeholder>
                            <Placeholder.Image square />
                        </Placeholder>
                    </div> : ''
                }
                <Image onLoad={() => setLoading(false)} style={{ width: '100%', aspectRatio: '1', objectFit: 'cover' }} alt='' src={image} />
            </div>
            <div style={{ padding: '8px ', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div className='itemTitle' >
                        {
                            localizeString(data, 'name')
                        }
                    </div>
                    <div className='itemDetails'>
                        {
                            localizeString(data, 'detail')
                        }
                    </div>
                </div>

                <div className='itemPrice'>AED  {price}</div>

            </div>

        </div>
    );
};



export const MenuItemModal = ({ data = {}, dismiss }) => {
    const dispatch = useDispatch();
    const orderList = useSelector(state => state.orderList);
    console.log("orderorder", orderList);
    const { pictures, price } = data
    return (
        <div className='listItem' style={{ border: 'none', color: '#4a4a4a', borderRadius: '8px', padding: '0', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <div style={{ aspectRatio: '1.5', position: 'relative' }}>
                <div style={{ position: 'absolute', right: 0, padding: '16px' }}>
                    <Button icon='close' circular style={{ background: '#fafafa' }} onClick={() => {
                        dismiss()
                    }} />

                </div>
                {
                    pictures?.length > 0 && <img style={{ width: '100%', aspectRatio: '1.5', objectFit: 'cover' }} alt='' src={pictures?.[pictures?.length - 1]} />
                }
            </div>
            <div style={{ padding: '16px ', textAlign: 'justify' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='itemTitle' >
                        {
                            localizeString(data, 'name')
                        }

                    </div>
                    <Button icon='cart' circular style={{ background: orderList?.[data.id] ? 'var(--themeColor)' : '#fafafa', color: orderList?.[data.id] ? '#fff' : '#4a4a4a' }} onClick={() => {
                        if (orderList[data.id]) {
                            delete orderList[data.id]
                        } else {
                            orderList[data.id] = data
                        }
                        dispatch(updateOrder(orderList))
                    }} />
                </div>

                <div className='itemDetails' style={{ '-webkit-box-orient': 'unset' }}>
                    {
                        localizeString(data, 'detail')

                    }
                </div>
                <div className='itemPrice'>AED  {price}</div>

            </div>

        </div>
    );
};
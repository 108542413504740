import { createAction } from 'redux-action';

export const OFFERS = 'OFFERS';
export const getOffers = createAction(OFFERS, (offer) => {
    return offer
})


export default (state = [], action) => {
    switch (action.type) {
        case OFFERS:
            return action.payload
        default:
            return state;
    }
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore, auth } from 'firebase'
import { Button, Icon, Loader, Modal } from 'semantic-ui-react'
import clock from '../img/clock.svg'
import './orderStatus.css'
import { Link, Redirect } from 'react-router-dom'
import LocalizedStrings from 'react-localization';
import { setActiveOrder } from '../../../reducers/userReducer';

const OrderStatus = () => {
    const { resKey, pathName } = useSelector(state => state.settings);
    const { activeOrder } = useSelector(state => state.userProp);
    const dispatch = useDispatch()
    const [state, setState] = useState({
        loading: true,
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl'
    });
    const [strings] = useState(new LocalizedStrings({
        en: {
            orderStatus: 'Order Status',
            orderSent: 'Order sent',
            inKitchen: 'Order Accepted',
            onTheWay: 'On The Way',
            confirmDilevery: 'CONFIRM DELIVERY',
            readyForPick: 'Ready For Pickup',
            confirmPickup: 'CONFIRM PICKUP',
            backToMenu: 'Back to menu',
            preparingOrder: 'Preparing your order',
            servedIn: 'Served',
            in: ' in ',
            min: ' mins',
            hours: ' hours',
            served: 'Served',
            servingNow: 'Serving now',
            areYouSure: "Are you sure you have received the order?",
            Nope: 'Nope',
            confirm: "YES!",
            getPrepared: 'Get prepared. Your order arriving'
        },
        ar: {
            orderStatus: 'حالة الطلب',
            orderSent: 'تم إرسال الطلب',
            inKitchen: 'تم قبول الطلب',
            onTheWay: 'الطلب في الطريق',
            confirmDilevery: 'تأكيد التوصيل',
            readyForPick: 'جاهز للإستلام',
            confirmPickup: 'تأكيد الإستلام',
            backToMenu: 'العودة إلى القائمة',
            preparingOrder: 'طلبك قيد التحضير',
            servedIn: 'يقدم',
            in: ' في خلال ',
            min: ' دقيقة',
            hours: ' ساعة',
            served: 'تم التقديم',
            servingNow: 'يقدم الآن',
            areYouSure: "هل أنت متأكد من استلامك الطلب؟",
            Nope: 'لا',
            confirm: "نعم!",
            getPrepared: 'استعد. سيصل طلبك'

        }
    }))
    useEffect(() => {
        strings.setLanguage(state.direction === 'rtl' ? 'ar' : 'en');
        getActiveookings()
        return () => {
            if (state.unsubscribe) {
                state.unsubscribe()
            }
        }
    }, [])

    function renderConfirmPopup() {
        return (<Modal open={true}>
            <Modal.Content >
                <Modal.Description>
                    <p style={{ textAlign: 'initial' }}>
                        {strings.areYouSure}
                    </p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' basic onClick={() => setState({ ...state, confimPopup: false })}>
                    {strings.Nope}
                </Button>
                <Button
                    content={strings.confirm}
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => confirmDelivery()}
                    positive
                />
            </Modal.Actions>
        </Modal >)
    }

    function getDate(date) {
        if (date) {
            let formatedDate = new Date(date)
            return (formatedDate.toLocaleString())
        } else {
            return ""
        }

    }
    function confirmDelivery() {
        let orderId = activeOrder.id
        setState({ rate: true, orderId })
        firestore().collection('resturant').doc(resKey).collection('orders').doc(orderId).update({
            status: 'delivered',
            delivered: Date.now()
        }).then(() => {
            setState({ ...state, confimPopup: false })
        })

    }

    function getActiveookings() {
        let key = resKey
        let { unsubscribe } = state
        let tokinizedUrl = new URL(window.location.href);
        var tokenId = tokinizedUrl.searchParams.get("token");
        if (key) {
            if (tokenId) {
                auth().signInWithCustomToken(tokenId).then(user => {
                    unsubscribe = firestore().collection('resturant').doc(key).collection('orders').where('userUid', '==', user.user.uid).where('status', 'in', ['new', 'accepted', 'delivery']).limit(1).onSnapshot(snap => {
                        if (snap.empty) {
                            dispatch(setActiveOrder(false))
                            setState({ ...state, activeOrder: null, loading: false, unsubscribe })
                        }
                        snap.forEach(result => {
                            let data = result.data()
                            data.id = result.id
                            dispatch(setActiveOrder(data))
                            setState({ ...state, activeOrder: data, loading: false.unsubscribe })
                        })
                    })
                }).catch(error => {
                    dispatch(setActiveOrder(false))
                    setState({ ...state, activeOrder: null, loading: false, unsubscribe })
                })
            } else {
                auth().onAuthStateChanged(user => {
                    if (user) {
                        unsubscribe = firestore().collection('resturant').doc(key).collection('orders').where('userUid', '==', user.uid).where('status', 'in', ['new', 'accepted', 'delivery']).limit(1).onSnapshot(snap => {
                            if (snap.empty) {
                                dispatch(setActiveOrder(false))
                                setState({ ...state, activeOrder: null, loading: false, unsubscribe })
                            }
                            snap.forEach(result => {
                                let data = result.data()
                                data.id = result.id
                                dispatch(setActiveOrder(data))
                                setState({ ...state, activeOrder: data, loading: false, unsubscribe })
                            })
                        })
                    }
                    //  else {
                    //     dispatch(setActiveOrder(false))
                    //     setState({ ...state, activeOrder: null, loading: false, unsubscribe })
                    // }
                })

            }
        }
    }
    if (state.loading) {
        return (<Loader active />)
    }
    if (!activeOrder || !activeOrder.status) {

        return (
            <Redirect to={(pathName ? ('/' + pathName + (state.rate ? `/rating?orderId=${state.orderId}` : '')) : '/rating?orderId=' + state.orderId)} ></Redirect>
        )
    }
    return (
        <div className='orderStatusClass' >
            <div className='orderStatusClassContainer' >
                <div className='titleContainer' >
                    <div className='text' >{strings.orderStatus}</div>
                    <div className='underline' ></div>
                </div>
                <div className='statusContainer' >
                    <div className='stepsContainer' >
                        <div className='line' style={{ height: activeOrder.receipt.type === 'dineIn' ? '80px' : '66%' }} />
                        <div className='titleIconContainer'>
                            <Icon name={activeOrder.created ? 'check circle' : 'circle outline'} className='icon' />
                            <div className='textContainer'>
                                <div className='upperText'>{strings.orderSent}</div>
                                {
                                    activeOrder.created && <div className='lowerTextContainer'>
                                        <img className='icon' alt='' src={clock} />
                                        <div className='time'>{getDate(activeOrder.created)}</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='titleIconContainer'>
                            <Icon name={activeOrder.accepted ? 'check circle' : 'circle outline'} className='icon' />
                            <div className='textContainer'>
                                <div className='upperText'>{activeOrder.receipt.type === 'dineIn' ? strings.preparingOrder : strings.inKitchen}</div>
                                {
                                    activeOrder.accepted && <div className='lowerTextContainer'>
                                        <img className='icon' alt='' src={clock} />
                                        <div className='time'>{
                                            (activeOrder.receipt.type === 'pickup' ?
                                                strings.readyForPick :
                                                activeOrder.receipt.type === 'dineIn' ?
                                                    strings.servedIn :
                                                    strings.preparingOrder) + strings.in + (activeOrder.preparationTime && activeOrder.preparationTime.value ? activeOrder.preparationTime.value : activeOrder.preparationTime) + (activeOrder.preparationTime && activeOrder.preparationTime.label ? (activeOrder.preparationTime.label === 'mins' ? strings.min : strings.hours) : strings.min)

                                        }</div>

                                        {/* <div className='time'>{this.getDate(activeOrder.accepted)}</div> */}
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            activeOrder.receipt.type !== 'dineIn' ? <div className='titleIconContainer'>
                                <Icon name={activeOrder.deliveryStamp ? 'check circle' : 'circle outline'} className='icon' />
                                <div className='textContainer'>
                                    <div className='upperText'>{activeOrder.receipt &&
                                        activeOrder.receipt.type === 'pickup' ?
                                        strings.readyForPick :
                                        strings.onTheWay}</div>
                                    {
                                        activeOrder.receipt.type !== 'pickup' && activeOrder.deliveryStamp && <div className='lowerTextContainer'>
                                            <img className='icon' alt='' src={clock} />
                                            {/* <div className='time'>{this.getDate(activeOrder.deliveryStamp)}</div> */}
                                            <div className='time'>{strings.getPrepared + strings.in + activeOrder.deliveryTime + strings.min}</div>


                                        </div>
                                    }
                                </div>
                            </div>
                                : ''
                        }

                    </div>
                </div>
                <Button animated disabled={!activeOrder.accepted} className='confirmDelivery' onClick={() => setState({ ...state, confimPopup: true })}>{
                    activeOrder.receipt && activeOrder.receipt.type === 'pickup' ?
                        strings.confirmPickup
                        : activeOrder.receipt.type === 'dineIn' ?
                            strings.served
                            : strings.confirmDilevery}</Button>
                <div className='backtomenu'>
                    <Link to={pathName ? ('/' + pathName) : '/'} className='backtomenu'>{strings.backToMenu}</Link>
                </div>
                {
                    state.confimPopup && renderConfirmPopup()
                }
            </div>
        </div>
    );
};

export default OrderStatus;
import LocalizedStrings from "react-localization";

export const strings = new LocalizedStrings({
    en: {
        minOrder: " min order ",
        deliveryTime: " Delivery within",
        min: " min(s)",
        hour: " hour(s)",
        day: " day(s)",
        AED: " AED",
        EGP: " EGP",
        BHD: " BHD",
        KWD: " KWD",
        OMR: " OMR",
        QAR: " QAR",
        SAR: " SAR",
        USD: " USD",
        pts: ' Pts',
        inWallet: 'in your wallet',
        clickHere: 'Click here ',
        login: "to start earning points",
        Get: 'Get ',
        discount: '% discount ',
        onfirst: 'off your first ',
        orders: ' orders',
        nextOrder: ' on your next order',
        youHave: "You have an ",
        active: "Active",
        order: 'order',
        priceOnSelection: "Price On Selection",
        showCart: "Show Cart",
        orderSent: "Your Order has been sent 👏",
        trackOrder: "Track order",
        name: 'Name',
        yoyrcart: 'Your Cart',
        Checkout: "Checkout",
        subtotal: 'Sub Total',
        vat: 'VAT',
        discount: 'Discount',
        total: "Total",
        delivery: "Delivery charge",
        applyCoupone: "Apply coupon",
        cartEmpty: "Your cart is empty",
        addItemsFrom: "Add items from ",
        toPlaceOrder: " to place order",
        promoCode: "promo code",
        placeOrder: "PLACE ORDER",
        continue: 'Continue',
        breakfast: "Breakfast",
        dinner: "Dinner",
        soup: "Soup",
        freeItem: "Free Item",
        cash: "Cash on delivery",
        card: "Card on delivery",
        online: 'Online Payment',
        cashPickup: "Cash on pickup",
        cardPickup: "Card on pickup",
        onlinePickup: "Online Payment",
        cashDineIn: "Cash on leave",
        cardDineIn: "Card on leave",
        onlineDineIn: 'Online Payment',
        search: "Search for food 😋",
        login: "Login",
        menu: "Menu",
        InclusiveOfVat: "(Inclusive of VAT)",
        remove: 'Remove',
        deliveringTo: 'Delivering to',
        pickupfrom: "Pickup From",
        servingTo: "Serving to",

        paymentMethod: 'Payment method',
        addressDetails: 'Address Details',
        areaName: "Area Name",
        fullAddress: 'Full Address',
        email: 'Email',
        building: 'Building',
        street: 'Street',
        floor: 'Floor',
        flat: 'Flat',
        addressLabel: 'Address Label',
        notes: 'Other notes for driver',
        saveAddress: 'Save address',
        addNewAddress: '+Add new address',
        specialNotes: 'Special Notes',
        Change: "Change",
        WhereToDeliver: "Where to deliver?",
        addNewCard: '+ Add New Card',
        selectAddress: 'Select Address',
        added: 'Added ',
        tableNumber: 'Table: ',
        save: 'Save',
        offlinMsg: "Store can't receive orders now",
        chooseLocation: "Choose your Location",
        searchYourArea: "Search your area",
        SelectYourArea: "Select your area",
        pickup: "Pickup",
        delivery: "Delivery",
        pickupTitle: "Pickup From?",
        dineIn: "Dine in",
        selectTable: "Choose your table",
        enterTableNumber: "Enter table number here..",
        select: 'Select',
        letsOrder: 'Lets Order',
        scanQrCode: 'Scan QR code!',
        qrDescription: 'You will find QR code on the table inside the restaurant',
        chooseCurrentLocation: 'Or Choose your current location',
        cantFindLocation: "Sorry, we don't deliver to this area!",
        locationList: 'Check locations list here',

        "Card number": 'Card number',
        "Expiration date": 'Expiration date',
        "applePay": "ApplePay / Google Pay",
        "Delete Saved Location!": 'Delete Saved Location!',
        "Are you sure": 'Are you sure',
        "Delete location": 'Delete location',
        Cancel: 'Cancel',



    },
    ar: {
        minOrder: " الحد الادنى ",
        deliveryTime: " التوصيل خلال ",
        min: " دقيقة",
        hour: " ساعة",
        day: " يوم",
        AED: " درهم",
        EGP: " جنيه",
        BHD: " BHD",
        KWD: " دينار",
        OMR: " ريال",
        QAR: " ريال",
        SAR: " ريال",
        USD: " USD",
        pts: ' نقطة',
        inWallet: ' في محفظتك',
        clickHere: 'إضغط هنا ',
        login: "لبدء كسب النقاط",
        Get: 'احصل على ',
        discount: '% خصم ',
        onfirst: 'على اول ',
        orders: ' طلب',
        nextOrder: ' على طلبك القادم',
        youHave: "لديك طلب ",
        active: "جاري",
        order: '',
        priceOnSelection: "السعر عند الاختيار",
        showCart: "عرض السلة",
        orderSent: "تم إرسال طلبك 👏",
        trackOrder: "تتبع طلبك",


        name: 'الاسم',
        yoyrcart: 'قائمة الطلبات',
        Checkout: "الدفع",
        subtotal: 'مبلغ إجمالي',
        vat: 'ضريبة',
        discount: 'خصم',
        total: "إجمالي",
        delivery: "رسوم التوصيل",
        applyCoupone: "تطبيق القسيمة",
        cartEmpty: "قائمة التسوق خالية",
        addItemsFrom: "أضف الطلبات من ",
        toPlaceOrder: " لوضع الطلب",
        promoCode: "الرمز الترويجي",
        placeOrder: "تأكيد الطلب",
        continue: 'استمر',
        breakfast: "فطور",
        dinner: "غداء",
        soup: "حسااء",
        freeItem: "مجاني",
        cash: "الدفع كاش عند الاستلام",
        card: "الدفع بالبطاقة عند الاستلام",
        online: 'الدفع الالكتروني',
        cashPickup: "الدفع كاش عند الاستلام",
        cardPickup: "الدفع بالبطاقة عند الاستلام",
        onlinePickup: "الدفع الالكتروني",

        cashDineIn: "الدفع كاش عند المغادرة",
        cardDineIn: "الدفع بالبطاقة عند المغادرة",
        onlineDineIn: 'الدفع الالكتروني',
        search: "بحث عن الطعام 😋",
        login: "تسجيل الدخول",
        menu: "القائمة",
        InclusiveOfVat: "(تتضمن ال VAT)",
        remove: 'إلغاء',
        deliveringTo: 'توصل إلى',
        pickupfrom: "استلام الطلب من",
        servingTo: "تقدم إلى",

        paymentMethod: 'طريقة الدفع',
        addressDetails: 'تفاصيل العنوان',
        areaName: "اسم المنطقة",
        fullAddress: 'العنوان',
        addressLabel: 'تسمية عنوان',
        notes: 'ملاحظات أخرى للسائق',
        email: 'البريد الإلكتروني',
        building: 'مبنى',
        street: 'شارع',
        floor: 'طابق',
        flat: 'شقة',
        saveAddress: 'حفظ العنوان',
        addNewAddress: '+ أضف عنوان جديد',
        specialNotes: 'ملاحظات خاصة',
        Change: "تغيير",
        WhereToDeliver: "إلى أين يتم التوصيل؟",
        addNewCard: '+ إضافة بطاقة جديدة',
        selectAddress: 'حدد العنوان',
        added: 'إضافة ',
        tableNumber: 'طاولة رقم : ',
        save: 'تم',
        offlinMsg: "لا يمكن للمتجر تلقي الطلبات الآن",


        chooseLocation: "اختر موقعك",
        searchYourArea: "ابحث في منطقتك",
        SelectYourArea: "اختر منطقتك",
        pickup: "استلام من المتجر",
        delivery: "توصيل",
        pickupTitle: "أين ستستلم طلبك؟",
        dineIn: "في المتجر",
        selectTable: "ادخل رقم الطاولة",
        enterTableNumber: "أدخل رقم الطاولة هنا ..",
        select: 'إختر',
        letsOrder: 'اطلب الان',
        scanQrCode: 'إمسح رمز QR',
        qrDescription: 'ستجد رمز ال QR على الطاولة داخل المطعم',
        chooseCurrentLocation: 'أو اختر موقعك الحالي',
        cantFindLocation: "عذرا لا نقوم بالتوصيل الى هذه المنطقة!",
        locationList: 'تحقق من قائمة المواقع هنا',

        "Card number": 'رقم البطاقة',
        "Expiration date": 'تاريخ الإنتهاء',
        "Delete Saved Location!": 'حذف الموقع المحفوظ!',
        "Are you sure": 'هل أنت متأكد',
        "Delete location": 'حذف الموقع',
        Cancel: 'إلغاء',



    }

})

import { Dimmer, Icon, Loader } from 'semantic-ui-react'
import React, { Component } from 'react';
// import '../loyalty.css'
import LocalizedStrings from 'react-localization';
import { auth, firestore, functions } from 'firebase'
import Slider from "react-slick";
import moment from 'moment';
import androidShareIcon from '../../img/androidShareIcon.png'
import iosShareIcon from '../../img/iosShareIcon.png'
import plausIcon from '../../img/plausIcon.png'
import firstImg from '../../img/firstStep.jpg'
import secondImg from '../../img/secondStep.jpg'
import { ReactComponent as ReactLogo } from '../../../Login/img/times.svg';

class Step2 extends Component {
    strings = new LocalizedStrings({
        en: {

            appExperience: 'Great app experience',
            appExperienceMsg: 'Full screen experience without the web browser',
            tapThe: 'Tap the',
            share: 'Share',
            moreMenu: 'More menu',
            tapIcon: 'Tap the icon',
            addScreen: 'Add to home screen',
            fromOptions: 'from the available options',
            closeBrowser: 'Browser will close and the',
            appIcon: 'App icon',
            willBeAdded: 'will be added to your home screen. Launch the app once to ensure the install completed successfully.',
            icon: 'icon',
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",

        },
        ar: {
            appExperience: 'تجربة تطبيق رائعة',
            appExperienceMsg: 'تجربة استخدام افضل من خلال التطبيق على هاتفك',
            tapThe: 'اضغط على أيقونة',
            share: 'نشر',
            moreMenu: 'قائمة المزيد',
            tapIcon: 'اضغط على الأيقونة',
            addScreen: 'أضف إلى الشاشة الرئيسية',
            fromOptions: 'من الخيارات المتاحة',
            closeBrowser: 'سيتم إغلاق المتصفح و',
            appIcon: 'أيقونة التطبيق',
            willBeAdded: 'ستتم إضافته إلى شاشتك الرئيسية. قم بتشغيل التطبيق للتأكد من اكتمال التثبيت بنجاح.',
            icon: '',
           
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",

        }

    })
    state = {
        loading: true
    }
    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }
    componentWillMount() {
        let { direction, loyalty, user } = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');

        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden'

    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'

    }
    render() {
        let { dismiss, loyalty, direction } = this.props


        let strings = this.strings
        console.log('fsdfdsfs', this.getMobileOperatingSystem())
        return (
            <Dimmer active className='getapp' page>
                <div className='step2Container'>
                    <div style={{ textAlign: "initial", padding: '16px', position: 'absolute', right: direction === 'rtl' ? 'auto' : '0', left: direction === 'rtl' ? '0' : 'auto', cursor: 'pointer' }} onClick={() => {
                        dismiss()
                    }}>
                        <ReactLogo />
                    </div>

                    <div className='infoContainer'>
                        <div className='title'>{strings.appExperience}</div>
                        <div className='desc'>{strings.appExperienceMsg}</div>
                        <div className='stepCont' style={{ marginTop: '33px' }}>
                            <img src={this.getMobileOperatingSystem() === 'iOS' ? iosShareIcon : androidShareIcon} alt='' />
                            <div className='stepContTitle' >{strings.tapThe} <span>{this.getMobileOperatingSystem() === 'iOS' ? strings.share : strings.moreMenu}</span> {strings.icon}</div>
                        </div>
                        <div className='stepCont' style={{ marginTop: '24px' }}>
                            <img src={plausIcon} alt='' />
                            <div className='stepContTitle'>{strings.tapIcon} <span>{strings.addScreen}</span> {strings.fromOptions}</div>
                        </div>
                        <div className='stepContTitle' style={{ marginTop: '30px' }}>{strings.closeBrowser} <span>{strings.appIcon}</span> {strings.willBeAdded}</div>

                    </div>

                </div>

            </Dimmer>
        )
    }




}

export default Step2
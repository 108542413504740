import LocalizedStrings from 'react-localization';
import { createAction } from 'redux-action';
import { strings } from '../helpers/translation';
export const SET_STORE_TYPE = 'SET_STORE_TYPE';
export const SET_STORE_SETTINGS = 'SET_STORE_SETTINGS';
export const SET_ALERT = 'SET_ALERT';
export const UPDATE_DIRECTION = 'UPDATE_DIRECTION';

function setDirection(settingsDirection) {
    let direction = localStorage.getItem('direction')
    if (direction) {
        document.body.style.direction = direction
        strings.setLanguage(direction === 'rtl' ? 'ar' : 'en')
    } else {
        if (settingsDirection) {
            strings.setLanguage(settingsDirection === 'rtl' ? 'ar' : 'en')
            direction = settingsDirection
            document.body.style.direction = settingsDirection
            localStorage.setItem('direction', settingsDirection);
        } else {
            if (this.strings.getLanguage() === 'en') {
                strings.setLanguage('en')
                direction = 'ltr'
                document.body.style.direction = 'ltr'
                localStorage.setItem('direction', 'ltr');
            } else {
                strings.setLanguage('ar')
                direction = 'rtl'
                document.body.style.direction = 'rtl'
                localStorage.setItem('direction', 'rtl');
            }
        }
    }
    return direction
}



export const setStoreSettings = createAction(SET_STORE_SETTINGS, (params) => {
    let { settings, appIcons, title, path, subscription, currency } = params
    document.documentElement.style.setProperty('--themeColor', settings.color);
    document.documentElement.style.setProperty('--themeColorOpacity05', settings.color + "05");
    document.documentElement.style.setProperty('--themeColorOpacity', settings.color + "10");
    document.documentElement.style.setProperty('--themeColorOpacity30', settings.color + "30");
    document.documentElement.style.setProperty('--secondaryColor', settings.secondaryColor);
    document.documentElement.style.setProperty('--counterBtn', settings.counterBtnBg);
    document.documentElement.style.setProperty('--counterBtnColor', settings.counterBtnColor);
    let direction = setDirection(settings.direction)
    let deliveryModes = subscription?.deliveryMode || [];
    let interactiveDinein;
    if (subscription?.storeSubscriptions?.dineIn?.endDate > Date.now()) {
        deliveryModes.push('dineIn');
        interactiveDinein = subscription?.storeSubscriptions.dineIn.interactive;
    }
    if (subscription?.storeSubscriptions?.pickup?.endDate > Date.now()) {
        deliveryModes.push('pickup')
    }
    let post = {
        resKey: params.id,
        resDocId: params.resDocId,
        headerLogo: (direction === 'rtl') ? settings.headerLogo_ar : settings.headerLogo,
        footerLogo: (direction === 'rtl') ? settings.footerLogo_ar : settings.footerLogo,
        menuLogo: appIcons["icon-128x128"],
        resName: title,
        pathName: path,
        sideImage: settings.sideImage,
        closedOn: settings.openHours,
        counterCode: subscription?.counterCode || '971',
        vat: subscription?.vat,
        interactiveDinein,
        deliveryModes: deliveryModes.filter((a, b) => deliveryModes.indexOf(a) === b),
        featuredImage: appIcons.featuredImage,
        currency: currency || 'AED',
        direction,
        categoryMenu: subscription?.storeSubscriptions?.categoryMenu?.activated || false,
        layout: settings?.layout ? settings?.layout : (subscription?.storeSubscriptions?.categoryMenu?.activated ? 'categories' : 'list'),
        noVat: subscription.vat === "0",
        bazzarStore: false,
        stripe: false
    }


    let paymentMethods = subscription?.paymentMeth || ['cash'];

    if (subscription?.storeSubscriptions?.online) {
        post.destinationId = subscription?.storeSubscriptions?.online?.id
        paymentMethods.push('online')
        post.paymentMeth = paymentMethods;
        post.stripe_id = subscription?.storeSubscriptions?.online?.stripe_id;
        localStorage.setItem('stripe_id', post.stripe_id)
    }
    if (settings.bazzarStore) {
        post.bazzarStore = true
        if (paymentMethods?.includes('online')) {
            post.stripe = true
            post.paymentMeth = ['applePay', ...paymentMethods]
        }
    }
    return post
})

export const UpdateDirection = createAction(UPDATE_DIRECTION, (params) => {
    setDirection(params)
    return params
})

export const setStoreType = createAction(SET_STORE_TYPE, (params) => {
    return params
})


export const SetAlertMessage = createAction(SET_ALERT, (params) => {
    return params
})

const initialState = {
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STORE_SETTINGS:
            return {
                ...state,
                ...action.payload
            };
        case SET_STORE_TYPE:
            return {
                ...state,
                storeType: action.payload
            };
        case UPDATE_DIRECTION:
            let direction = action.payload;
            return { ...state, direction }
        case SET_ALERT:
            return {
                ...state,
                alertMessage: action.payload
            };

        default:
            return state;
    }
};
export const countryCodes = [ {key: "af", text: "93", value: "93", flag: "af"}
, {key: "al", text: "355", value: "355", flag: "al"}
, {key: "dz", text: "213", value: "213", flag: "dz"}
, {key: "as", text: "1684", value: "1684", flag: "as"}
, {key: "ad", text: "376", value: "376", flag: "ad"}
, {key: "ao", text: "244", value: "244", flag: "ao"}
, {key: "ai", text: "1264", value: "1264", flag: "ai"}
, {key: "ag", text: "1268", value: "1268", flag: "ag"}
, {key: "ar", text: "54", value: "54", flag: "ar"}
, {key: "am", text: "374", value: "374", flag: "am"}
, {key: "aw", text: "297", value: "297", flag: "aw"}
, {key: "au", text: "61", value: "61", flag: "au"}
, {key: "at", text: "43", value: "43", flag: "at"}
, {key: "az", text: "994", value: "994", flag: "az"}
, {key: "bs", text: "1242", value: "1242", flag: "bs"}
, {key: "bh", text: "973", value: "973", flag: "bh"}
, {key: "bd", text: "880", value: "880", flag: "bd"}
, {key: "bb", text: "1246", value: "1246", flag: "bb"}
, {key: "by", text: "375", value: "375", flag: "by"}
, {key: "be", text: "32", value: "32", flag: "be"}
, {key: "bz", text: "501", value: "501", flag: "bz"}
, {key: "bj", text: "229", value: "229", flag: "bj"}
, {key: "bm", text: "1441", value: "1441", flag: "bm"}
, {key: "bt", text: "975", value: "975", flag: "bt"}
, {key: "bo", text: "591", value: "591", flag: "bo"}
, {key: "ba", text: "387", value: "387", flag: "ba"}
, {key: "bw", text: "267", value: "267", flag: "bw"}
, {key: "br", text: "55", value: "55", flag: "br"}
, {key: "io", text: "246", value: "246", flag: "io"}
, {key: "vg", text: "1284", value: "1284", flag: "vg"}
, {key: "bn", text: "673", value: "673", flag: "bn"}
, {key: "bg", text: "359", value: "359", flag: "bg"}
, {key: "bf", text: "226", value: "226", flag: "bf"}
, {key: "bi", text: "257", value: "257", flag: "bi"}
, {key: "kh", text: "855", value: "855", flag: "kh"}
, {key: "cm", text: "237", value: "237", flag: "cm"}
, {key: "ca", text: "1", value: "1", flag: "ca"}
, {key: "cv", text: "238", value: "238", flag: "cv"}
, {key: "bq", text: "599", value: "599", flag: "bq"}
, {key: "ky", text: "1345", value: "1345", flag: "ky"}
, {key: "cf", text: "236", value: "236", flag: "cf"}
, {key: "td", text: "235", value: "235", flag: "td"}
, {key: "cl", text: "56", value: "56", flag: "cl"}
, {key: "cn", text: "86", value: "86", flag: "cn"}
, {key: "co", text: "57", value: "57", flag: "co"}
, {key: "km", text: "269", value: "269", flag: "km"}
, {key: "cd", text: "243", value: "243", flag: "cd"}
, {key: "cg", text: "242", value: "242", flag: "cg"}
, {key: "ck", text: "682", value: "682", flag: "ck"}
, {key: "cr", text: "506", value: "506", flag: "cr"}
, {key: "ci", text: "225", value: "225", flag: "ci"}
, {key: "hr", text: "385", value: "385", flag: "hr"}
, {key: "cu", text: "53", value: "53", flag: "cu"}
, {key: "cw", text: "599", value: "599", flag: "cw"}
, {key: "cy", text: "357", value: "357", flag: "cy"}
, {key: "cz", text: "420", value: "420", flag: "cz"}
, {key: "dk", text: "45", value: "45", flag: "dk"}
, {key: "dj", text: "253", value: "253", flag: "dj"}
, {key: "dm", text: "1767", value: "1767", flag: "dm"}
, {key: "do", text: "1", value: "1", flag: "do"}
, {key: "ec", text: "593", value: "593", flag: "ec"}
, {key: "eg", text: "20", value: "20", flag: "eg"}
, {key: "sv", text: "503", value: "503", flag: "sv"}
, {key: "gq", text: "240", value: "240", flag: "gq"}
, {key: "er", text: "291", value: "291", flag: "er"}
, {key: "ee", text: "372", value: "372", flag: "ee"}
, {key: "et", text: "251", value: "251", flag: "et"}
, {key: "fk", text: "500", value: "500", flag: "fk"}
, {key: "fo", text: "298", value: "298", flag: "fo"}
, {key: "fj", text: "679", value: "679", flag: "fj"}
, {key: "fi", text: "358", value: "358", flag: "fi"}
, {key: "fr", text: "33", value: "33", flag: "fr"}
, {key: "gf", text: "594", value: "594", flag: "gf"}
, {key: "pf", text: "689", value: "689", flag: "pf"}
, {key: "ga", text: "241", value: "241", flag: "ga"}
, {key: "gm", text: "220", value: "220", flag: "gm"}
, {key: "ge", text: "995", value: "995", flag: "ge"}
, {key: "de", text: "49", value: "49", flag: "de"}
, {key: "gh", text: "233", value: "233", flag: "gh"}
, {key: "gi", text: "350", value: "350", flag: "gi"}
, {key: "gr", text: "30", value: "30", flag: "gr"}
, {key: "gl", text: "299", value: "299", flag: "gl"}
, {key: "gd", text: "1473", value: "1473", flag: "gd"}
, {key: "gp", text: "590", value: "590", flag: "gp"}
, {key: "gu", text: "1671", value: "1671", flag: "gu"}
, {key: "gt", text: "502", value: "502", flag: "gt"}
, {key: "gn", text: "224", value: "224", flag: "gn"}
, {key: "gw", text: "245", value: "245", flag: "gw"}
, {key: "gy", text: "592", value: "592", flag: "gy"}
, {key: "ht", text: "509", value: "509", flag: "ht"}
, {key: "hn", text: "504", value: "504", flag: "hn"}
, {key: "hk", text: "852", value: "852", flag: "hk"}
, {key: "hu", text: "36", value: "36", flag: "hu"}
, {key: "is", text: "354", value: "354", flag: "is"}
, {key: "in", text: "91", value: "91", flag: "in"}
, {key: "id", text: "62", value: "62", flag: "id"}
, {key: "ir", text: "98", value: "98", flag: "ir"}
, {key: "iq", text: "964", value: "964", flag: "iq"}
, {key: "ie", text: "353", value: "353", flag: "ie"}
, {key: "il", text: "972", value: "972", flag: "il"}
, {key: "it", text: "39", value: "39", flag: "it"}
, {key: "jm", text: "1876", value: "1876", flag: "jm"}
, {key: "jp", text: "81", value: "81", flag: "jp"}
, {key: "jo", text: "962", value: "962", flag: "jo"}
, {key: "kz", text: "7", value: "7", flag: "kz"}
, {key: "ke", text: "254", value: "254", flag: "ke"}
, {key: "ki", text: "686", value: "686", flag: "ki"}
, {key: "kw", text: "965", value: "965", flag: "kw"}
, {key: "kg", text: "996", value: "996", flag: "kg"}
, {key: "la", text: "856", value: "856", flag: "la"}
, {key: "lv", text: "371", value: "371", flag: "lv"}
, {key: "lb", text: "961", value: "961", flag: "lb"}
, {key: "ls", text: "266", value: "266", flag: "ls"}
, {key: "lr", text: "231", value: "231", flag: "lr"}
, {key: "ly", text: "218", value: "218", flag: "ly"}
, {key: "li", text: "423", value: "423", flag: "li"}
, {key: "lt", text: "370", value: "370", flag: "lt"}
, {key: "lu", text: "352", value: "352", flag: "lu"}
, {key: "mo", text: "853", value: "853", flag: "mo"}
, {key: "mk", text: "389", value: "389", flag: "mk"}
, {key: "mg", text: "261", value: "261", flag: "mg"}
, {key: "mw", text: "265", value: "265", flag: "mw"}
, {key: "my", text: "60", value: "60", flag: "my"}
, {key: "mv", text: "960", value: "960", flag: "mv"}
, {key: "ml", text: "223", value: "223", flag: "ml"}
, {key: "mt", text: "356", value: "356", flag: "mt"}
, {key: "mh", text: "692", value: "692", flag: "mh"}
, {key: "mq", text: "596", value: "596", flag: "mq"}
, {key: "mr", text: "222", value: "222", flag: "mr"}
, {key: "mu", text: "230", value: "230", flag: "mu"}
, {key: "mx", text: "52", value: "52", flag: "mx"}
, {key: "fm", text: "691", value: "691", flag: "fm"}
, {key: "md", text: "373", value: "373", flag: "md"}
, {key: "mc", text: "377", value: "377", flag: "mc"}
, {key: "mn", text: "976", value: "976", flag: "mn"}
, {key: "me", text: "382", value: "382", flag: "me"}
, {key: "ms", text: "1664", value: "1664", flag: "ms"}
, {key: "ma", text: "212", value: "212", flag: "ma"}
, {key: "mz", text: "258", value: "258", flag: "mz"}
, {key: "mm", text: "95", value: "95", flag: "mm"}
, {key: "na", text: "264", value: "264", flag: "na"}
, {key: "nr", text: "674", value: "674", flag: "nr"}
, {key: "np", text: "977", value: "977", flag: "np"}
, {key: "nl", text: "31", value: "31", flag: "nl"}
, {key: "nc", text: "687", value: "687", flag: "nc"}
, {key: "nz", text: "64", value: "64", flag: "nz"}
, {key: "ni", text: "505", value: "505", flag: "ni"}
, {key: "ne", text: "227", value: "227", flag: "ne"}
, {key: "ng", text: "234", value: "234", flag: "ng"}
, {key: "nu", text: "683", value: "683", flag: "nu"}
, {key: "nf", text: "672", value: "672", flag: "nf"}
, {key: "kp", text: "850", value: "850", flag: "kp"}
, {key: "mp", text: "1670", value: "1670", flag: "mp"}
, {key: "no", text: "47", value: "47", flag: "no"}
, {key: "om", text: "968", value: "968", flag: "om"}
, {key: "pk", text: "92", value: "92", flag: "pk"}
, {key: "pw", text: "680", value: "680", flag: "pw"}
, {key: "ps", text: "970", value: "970", flag: "ps"}
, {key: "pa", text: "507", value: "507", flag: "pa"}
, {key: "pg", text: "675", value: "675", flag: "pg"}
, {key: "py", text: "595", value: "595", flag: "py"}
, {key: "pe", text: "51", value: "51", flag: "pe"}
, {key: "ph", text: "63", value: "63", flag: "ph"}
, {key: "pl", text: "48", value: "48", flag: "pl"}
, {key: "pt", text: "351", value: "351", flag: "pt"}
, {key: "pr", text: "1", value: "1", flag: "pr"}
, {key: "qa", text: "974", value: "974", flag: "qa"}
, {key: "re", text: "262", value: "262", flag: "re"}
, {key: "ro", text: "40", value: "40", flag: "ro"}
, {key: "ru", text: "7", value: "7", flag: "ru"}
, {key: "rw", text: "250", value: "250", flag: "rw"}
, {key: "bl", text: "590", value: "590", flag: "bl"}
, {key: "sh", text: "290", value: "290", flag: "sh"}
, {key: "kn", text: "1869", value: "1869", flag: "kn"}
, {key: "lc", text: "1758", value: "1758", flag: "lc"}
, {key: "mf", text: "590", value: "590", flag: "mf"}
, {key: "pm", text: "508", value: "508", flag: "pm"}
, {key: "vc", text: "1784", value: "1784", flag: "vc"}
, {key: "ws", text: "685", value: "685", flag: "ws"}
, {key: "sm", text: "378", value: "378", flag: "sm"}
, {key: "st", text: "239", value: "239", flag: "st"}
, {key: "sa", text: "966", value: "966", flag: "sa"}
, {key: "sn", text: "221", value: "221", flag: "sn"}
, {key: "rs", text: "381", value: "381", flag: "rs"}
, {key: "sc", text: "248", value: "248", flag: "sc"}
, {key: "sl", text: "232", value: "232", flag: "sl"}
, {key: "sg", text: "65", value: "65", flag: "sg"}
, {key: "sx", text: "1721", value: "1721", flag: "sx"}
, {key: "sk", text: "421", value: "421", flag: "sk"}
, {key: "si", text: "386", value: "386", flag: "si"}
, {key: "sb", text: "677", value: "677", flag: "sb"}
, {key: "so", text: "252", value: "252", flag: "so"}
, {key: "za", text: "27", value: "27", flag: "za"}
, {key: "kr", text: "82", value: "82", flag: "kr"}
, {key: "ss", text: "211", value: "211", flag: "ss"}
, {key: "es", text: "34", value: "34", flag: "es"}
, {key: "lk", text: "94", value: "94", flag: "lk"}
, {key: "sd", text: "249", value: "249", flag: "sd"}
, {key: "sr", text: "597", value: "597", flag: "sr"}
, {key: "sz", text: "268", value: "268", flag: "sz"}
, {key: "se", text: "46", value: "46", flag: "se"}
, {key: "ch", text: "41", value: "41", flag: "ch"}
, {key: "sy", text: "963", value: "963", flag: "sy"}
, {key: "tw", text: "886", value: "886", flag: "tw"}
, {key: "tj", text: "992", value: "992", flag: "tj"}
, {key: "tz", text: "255", value: "255", flag: "tz"}
, {key: "th", text: "66", value: "66", flag: "th"}
, {key: "tl", text: "670", value: "670", flag: "tl"}
, {key: "tg", text: "228", value: "228", flag: "tg"}
, {key: "tk", text: "690", value: "690", flag: "tk"}
, {key: "to", text: "676", value: "676", flag: "to"}
, {key: "tt", text: "1868", value: "1868", flag: "tt"}
, {key: "tn", text: "216", value: "216", flag: "tn"}
, {key: "tr", text: "90", value: "90", flag: "tr"}
, {key: "tm", text: "993", value: "993", flag: "tm"}
, {key: "tc", text: "1649", value: "1649", flag: "tc"}
, {key: "tv", text: "688", value: "688", flag: "tv"}
, {key: "vi", text: "1340", value: "1340", flag: "vi"}
, {key: "ug", text: "256", value: "256", flag: "ug"}
, {key: "ua", text: "380", value: "380", flag: "ua"}
, {key: "ae", text: "971", value: "971", flag: "ae"}
, {key: "gb", text: "44", value: "44", flag: "gb"}
, {key: "us", text: "1", value: "1", flag: "us"}
, {key: "uy", text: "598", value: "598", flag: "uy"}
, {key: "uz", text: "998", value: "998", flag: "uz"}
, {key: "vu", text: "678", value: "678", flag: "vu"}
, {key: "va", text: "39", value: "39", flag: "va"}
, {key: "ve", text: "58", value: "58", flag: "ve"}
, {key: "vn", text: "84", value: "84", flag: "vn"}
, {key: "wf", text: "681", value: "681", flag: "wf"}
, {key: "ye", text: "967", value: "967", flag: "ye"}
, {key: "zm", text: "260", value: "260", flag: "zm"}
, {key: "zw", text: "263", value: "263", flag: "zw"}]
import React, { useEffect, useState } from 'react';
import './thankyoupage.css'
import checkimg from '../../MenuComponents/img/checkimg.svg'
import { Link } from 'react-router-dom'
import { auth, firestore } from 'firebase';
import { Dimmer, Loader } from 'semantic-ui-react';
import { strings } from '../../../helpers/translation';
import { useSelector } from 'react-redux';

const ThankYouPage = ({ user }) => {
    const { pathName } = useSelector(state => state.settings)

    const [state, setState] = useState({})
    useEffect(() => {
        let element = document.getElementById('fb-messenger-checkbox')
        let user_ref = Date.now()
        if (element && user && user.info && !user.info.PSID) {
            setState({ ...state, loading: true })
            setTimeout(() => {
                setState({ ...state, loading: false })
            }, 1500);
            element.setAttribute('user_ref', user_ref)
            element.setAttribute('ref', auth().currentUser.uid)

            window.FB.Event.subscribe('messenger_checkbox', function (e) {
                console.log("messenger_checkbox event");
                if (e.event === 'rendered') {
                    console.log("Plugin was rendered");
                } else if (e.event === 'checkbox') {
                    var checkboxState = e.state;
                    setState({...state, chicked: checkboxState === 'checked', user_ref: e.user_ref })
                    console.log("Checkbox state: " + checkboxState);
                } else if (e.event === 'not_you') {
                    console.log("User clicked 'not you'");
                } else if (e.event === 'hidden') {
                    console.log("Plugin was hidden");
                }

            })
            window.FB.XFBML.parse()


        }
    }, [])

    function submit() {
        let { user_ref, chicked } = state

        if (chicked) {
            window.FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
                'app_id': '639127950286009',
                'page_id': '107675070834235',
                'ref': auth().currentUser.uid,
                'user_ref': user_ref
            });
            firestore().collection('users').doc(auth().currentUser.uid).update({
                messengerId: user_ref
            })
        }
    }
    return (
        <div className='thankyouClass' >
            {
                state.loading ?
                    <Dimmer page active>
                        <Loader active />
                    </Dimmer>
                    : ''

            }
            <div className='thankyouClassContainer' >
                <img className='checkimg' alt='checkimg' src={checkimg} />
                <div className='holatext' >Hola!</div>
                <div className='yourordertext' >{strings.orderSent}</div>
                <Link to={pathName ? ('/' + pathName + '/order-status') : '/order-status'} onClick={() => {
                    submit()
                }} className='trackorderbutton' >{strings.trackOrder}</Link>

                <div id="fb-messenger-checkbox" className="fb-messenger-checkbox" origin={window.location.href}
                    page_id='107675070834235' messenger_app_id='639127950286009' allow_login="true" size="xlarge"
                    skin="light" center_align="true">
                </div>
            </div>
        </div>
    );
};

export default ThankYouPage;
import { createAction } from 'redux-action';
import { strings } from '../helpers/translation.js'
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const localeSetLanguage = createAction(SET_LANGUAGE, (lang) => {
    let direction = localStorage.getItem('direction') || 'ltr'

    strings.setLanguage(direction === 'rtl' ? 'en' : 'ar');
    document.body.style.direction = (direction === 'rtl' ? 'ltr' : 'rtl');
    let rightContainer = document.getElementById('rightSideContainer')
    if (rightContainer) {
        rightContainer.style.left = direction === 'rtl' ? 'auto' : '0'
        rightContainer.style.right = direction === 'rtl' ? '0' : 'auto'

    }
    localStorage.setItem('direction', direction === 'rtl' ? 'ltr' : 'rtl');


    return lang
});

const locales = [
    { locale: 'en', label: 'English' },
    { locale: 'ar', label: 'Arabic' },
    { locale: 'zh-CN', label: 'Chinese' },
    { locale: 'rus', label: 'Russian' },
];

const INITIAL_STATE = {
    locales: locales,
    isSet: false
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case SET_LANGUAGE:
            return { locale: action.payload, isSet: true };
        default:
            return state;
    }
};


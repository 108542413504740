import { Dimmer, Icon, Loader } from 'semantic-ui-react'
import React, { Component } from 'react';
import './offerDetails.css'
import LocalizedStrings from 'react-localization';
import { auth, firestore, functions } from 'firebase'
import Slider from "react-slick";
import moment from 'moment';
import firstImg from '../img/firstStep.jpg'
import secondImg from '../img/secondStep.jpg'
import { ReactComponent as ReactLogo } from '../../Login/img/times.svg';
import { Link } from 'react-router-dom';

class LoyaltyTerms extends Component {
    strings = new LocalizedStrings({
        en: {
            get: 'Get',
            loyaltyProgram: 'Loyalty program',
            expiration: 'Expiration',
            discountCode: 'Discount code',
            maximumDiscount: 'Maximum discount of',
            validUntil: 'Valid until',
            ok: 'Ok',
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",

        },
        ar: {
            get: 'احصل على خصم',
            loyaltyProgram: 'برنامج الولاء',
            expiration: 'تاريخ الإنتهاء',
            discountCode: 'كود الخصم',
            maximumDiscount: 'الحد الأقصى للخصم',
            validUntil: 'صالح حتى',

            ok: 'موافق',
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",

        }

    })
    state = {
        loading: true
    }

    componentWillMount() {
        let { direction } = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');

        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden'

    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'

    }
    render() {
        let { dismiss, offer } = this.props


        let strings = this.strings

        return (
            <Dimmer active className='offerDetails' page>
                <div className='popContainer' style={{ backgroundColor: '#fff' }}>
                    <div className='infoContainer'>
                        <div className='title'>{strings.get + " " + offer?.percent + "%"}</div>
                        <div className='desc' style={{ marginTop: '3px' }}>{strings.maximumDiscount + " " + offer?.cap + " " + strings[window.currency]}</div>

                    </div>
                    <div className='infoContainer'>
                        <div className='desc' style={{ color: '#343434' }}>{strings.expiration}</div>
                        <div className='desc' style={{ marginTop: '3px' }}>{strings.validUntil + " " + moment(offer?.endDate).format("MMMM DD, YYYY")}</div>

                    </div>
                    <div className='infoContainer'>
                        <div className='desc' style={{ color: '#343434' }}>{strings.discountCode}</div>
                        <div className='code' style={{ marginTop: '3px' }}>{offer?.code}</div>
                    </div>
                    <div id='activeOrderContainer' className='buttonContainer' onClick={() => {
                        dismiss()
                    }}>
                        <div className='text'>{strings.ok}</div>
                    </div>

                </div>

            </Dimmer>
        )
    }


}

export default LoyaltyTerms
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { localizeString } from '../../helpers';


const Item = ({ data, settings }) => {
    return (<Link to={(settings?.pathName ? ('/' + settings?.pathName) : '') + '/menu?itemId=' + data.id} className='listItem' style={{ border: 'none', color: '#4a4a4a', borderRadius: '8px', padding: '0', display: 'flex', flexDirection: 'column', overflow: 'hidden', boxShadow: '0 0 8px 4px #cacaca20' }}>
        <div style={{ aspectRatio: '1.5', }}>
            {
                data?.pictures?.length > 0 && <img style={{ width: '100%', aspectRatio: '1.5', objectFit: 'cover' }} alt='' src={data?.pictures?.[0]} />
            }

        </div>
        <div style={{ padding: '8px ' }}>
            <div className='itemTitle' >
                {
                    localizeString(data, 'name')
                }
            </div>
            <div className='itemDetails'>
                {
                    localizeString(data, 'detail')
                }
            </div>
            <div className='itemPrice'>AED  {data?.price}</div>

        </div>

    </Link>)
}

const GridLayout = () => {
    const { menu } = useSelector(state => state.userProp)
    const settings = useSelector(state => state.settings)
    return (
        <div className='listContainer' style={{ paddingBottom: '150px' }}>
            {
                menu?.map(item => <div key={item?.category?.id}>
                    <div className='title'>
                        {
                            localizeString(item?.category, 'name')
                        }
                    </div>

                    <Grid className='list' style={{ margin: '0 4px' }}>
                        {
                            item?.items?.map(rec => {
                                return (
                                    <Grid.Column width={8}>
                                        <Item data={rec} settings={settings} />
                                    </Grid.Column>
                                )
                            })
                        }
                    </Grid>
                </div>
                )
            }

        </div>
    );
};

export default GridLayout;
import React, { Component } from 'react';
import './cart.css'
import Btn from '../Helpers/AddItemBtn/btn'
import 'semantic-ui-css/semantic.min.css'
import { Input, Button, Dimmer, Icon, TextArea, Dropdown, Loader } from 'semantic-ui-react'
import { connect, useSelector } from 'react-redux';
import { flushOrders } from '../../reducers/newOrder'

import { updateUserLocation, updateUserAddress, setHelpers } from '../../reducers/userReducer'
import NewLogin from '../Login/newLogin';
import ThankYouPage from './thankyoupage/thankyoupage'
import Header from '../Header/index';
import { applyCoupone, calcGrandTotal, placeOrder, validateUser, calcItemTotal, getAddedExtras, CheckAddresses } from '../../helpers/cartHelpres';
import { Link } from 'react-router-dom';
import Extras from '../MenuComponents/extra/extra';
import Banner from '../MenuComponents/offlineBanner/offlineBanner'
import { firestore, auth, functions } from 'firebase';
import Payment from './payment.js'
import PaymentBtn from "../Helpers/PaymentBtn";
import SelectLocation from '../MenuComponents/selectLocation/selectLocation'
import MenuItem from '../MenuComponents/item-page/item.js'
import moment from 'moment';
import { strings } from '../../helpers/translation';
import { localizeString } from '../../helpers';
import StripeCard from "./StripeCard";
import PaymentCard from '../Helpers/PaymentCard';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SetAlertMessage } from '../../reducers/settings';


class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
            pageStep: 0,
            fromCart: true,
            selectedPayment: 'cash',
            loading: false,
            cardObject: {},
            orderId: firestore().collection('orders').doc().id,
        }
        // this.handleScriptLoad = this.handleScriptLoad.bind(this);
        // this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        // this.submitNewAddress = this.submitNewAddress.bind(this)

    }
    // handleScriptLoad() {
    //     // Declare Options For Autocomplete 
    //     // var circle = new google.maps.Circle(
    //     //     { center: { lat: 25.1874756, lng: 55.2529987 }, radius: 20000 });

    //     var options = {
    //         types: ['geocode'],
    //         componentRestrictions: { country: 'ae' }
    //     };

    //     // Initialize Google Autocomplete 
    //     /*global google*/
    //     this.autocomplete = new google.maps.places.Autocomplete(
    //         document.getElementById('autocompleteadd'),
    //         options);
    //     // this.autocomplete.setBounds(circle.getBounds());

    //     // Avoid paying for data that you don't need by restricting the 
    //     // set of place fields that are returned to just the address
    //     // components and formatted address
    //     this.autocomplete.setFields(['address_components',
    //         'formatted_address', 'geometry', 'type', 'name', 'vicinity']);
    //     // Fire Event when a suggested name is selected
    //     this.autocomplete.addListener('place_changed',
    //         this.handlePlaceSelect);
    // }
    // handlePlaceSelect() {
    //     // Extract City From Address Object
    //     let addressObject = this.autocomplete.getPlace();
    //     let address = addressObject.address_components;
    //     let area = addressObject.name
    //     // Check if address is valid
    //     if (address) {
    //         // Set State
    //         this.setState(
    //             {
    //                 area,
    //                 address: addressObject.formatted_address,
    //                 location: {
    //                     lat: addressObject.geometry.location.lat(),
    //                     lng: addressObject.geometry.location.lng()
    //                 }
    //             }
    //         );
    //     }
    // }

    showBanner(props) {
        let { selectedLocation, timings, settings } = props
        let type = selectedLocation && selectedLocation.type ? (selectedLocation.type === "delivery" ? "deliveryTimings" : selectedLocation.type === "dineIn" ? "dineinTimings" : selectedLocation.type === "pickup" ? "pickupTimings" : '') : ''
        let selectedTimings = timings && timings[type]
        let today = moment(new Date()).format("dddd")
        let closed = settings?.closedOn
        if (selectedTimings && Object.keys(selectedTimings).length) {
            let nowOptimized = moment('1955-01-05T' + moment().format('HH:mm'))
            let nowTime = nowOptimized.toDate().getTime()

            if (selectedTimings[today].isOpen) {
                let count2 = 0
                selectedTimings[today].timings.every(time => {
                    let startTime = moment(time.startTime)
                        .set('year', moment(nowOptimized).year())
                        .set('month', moment(nowOptimized).month())
                        .set('date', moment(nowOptimized).date());
                    let endTime = moment(time.endTime)
                        .set('year', moment(nowOptimized).year())
                        .set('month', moment(nowOptimized).month())
                        .set('date', moment(time.endTime).hours() === 0 ? moment(nowOptimized).date() + 1 : moment(nowOptimized).date());

                    if ((moment(nowTime).isSameOrAfter(startTime)) && moment(nowTime).isSameOrBefore(endTime)) {
                        this.setState({ showOfflineBanner: false })
                        return false
                    }
                    count2 += 1
                    return true
                })
                if (count2 === selectedTimings[today].timings.length) {
                    this.setState({ showOfflineBanner: true })
                }
            } else {
                this.setState({ showOfflineBanner: true })
            }
        } else {
            if (closed) {
                // let nowOptimized = moment('1955-01-05T21:30')

                let nowOptimized = moment('1955-01-05T' + moment().format('HH:mm'))
                let nowTime = nowOptimized.toDate().getTime()
                let from = new Date('1955', '0', '05', closed.from, closed.minFrom).getTime()
                let to = new Date('1955', '0', '05', closed.to, closed.minTo).getTime()
                if (closed.from > closed.to ? (to > nowTime) || (nowTime > from) : (to > nowTime) && (nowTime > from)) {
                    setTimeout(() => {
                        this.setState({ showOfflineBanner: true })
                    }, 500);
                } else {
                    this.setState({ showOfflineBanner: false })
                }
            }
        }
    }
    componentWillReceiveProps(prop) {
        this.showBanner(prop)
    }
    componentWillMount() {
        let { direction, cardObject } = this.state
        let { user, userAddressProp } = this.props
        strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
        let _this = this
        var url = new URL(window.location.href);
        var tap_id = url.searchParams.get("tap_id");
        if (tap_id) {
            console.log("tap_id", tap_id)
            this.setState({ proccessPayment: true }, () => {
                var checkAuth = functions().httpsCallable('AuthRedirect');
                checkAuth({ authId: tap_id }).then(function (result) {
                    let { data } = result
                    console.log("checkAuth", data)
                    let { card, customer, status, errors } = data
                    if (errors) {
                        cardObject = user.info.card
                        return _this.setState({ tokenId: tap_id, cardObject, error: errors[0].description, loading: false, proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })
                    }

                    if (status === "AUTHORIZED") {
                        card.customer = customer
                        if (card && card.id) {
                            firestore().collection('users').doc(auth().currentUser.uid).update({
                                [`card.${card.id}`]: card
                            }).then(() => {
                                _this.setState({ placeOrder: true, proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })
                            })
                        } else {
                            _this.setState({ placeOrder: true, proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })
                        }
                        _this.props.flushOrders()
                    } else {
                        _this.setState({ error: 'Cannot process your card', proccessPayment: false, pageStep: 1, selectedAddress: userAddressProp })

                    }
                }).catch(error => {
                    console.log("errorerror", error)

                })
            })
        }
        this.showBanner(this.props)

        // let closed = window.closedOn
        // if (closed) {
        //     let nowOptimized = moment('1955-01-05T' + moment().format('HH:mm'))
        //     let nowTime = nowOptimized.toDate().getTime()
        //     let from = new Date('1955', '0', '05', closed.from, closed.minFrom).getTime()
        //     let to = new Date('1955', '0', '05', closed.to, closed.minTo).getTime()

        //     if (closed.from > closed.to ? (to > nowTime) || (nowTime > from) : (to > nowTime) && (nowTime > from)) {
        //         setTimeout(() => {
        //             this.setState({ showOfflineBanner: true, disableOrder: true })
        //         }, 500);
        //     } else {
        //         this.setState({ showOfflineBanner: false, disableOrder: false })
        //     }
        // }
        if (user && user.info && user.info.card) {
            this.setState({ cardObject: user.info.card })
        }
        window.scrollTo({
            top: '0'
        })
    }

    render() {
        let { orderList, user, selectedLocation, allLocations, loyalty, helpers, timings, settings } = this.props
        let { freeItem, discountApplied, coupon, couponeCode } = helpers ? helpers : {}

        let { errorMinOrder, login, placeOrder, direction, extras, showOfflineBanner, selectAddress, pageStep, selectedAddress, fromCart, loading, addcard, proccessPayment, cardObject } = this.state
        let count = (orderList ? Object.keys(orderList) : []).map(item => {
            return orderList[item].count
        }).reduce((a, b) => a + b, 0)
        let receipt = calcGrandTotal(user, helpers, orderList, freeItem, selectedLocation, loyalty, settings)
        let path = settings?.pathName

        let earningPoints = ((receipt.subTotal - (receipt.deductedValue ? receipt.deductedValue : 0)) * (loyalty.percent / 100)).toFixed(2)


        if (placeOrder) {
            return (<ThankYouPage user={user} />)
        }
        if (proccessPayment) {
            return (

                <>
                    <Dimmer active>
                        <Loader content='Processing Payment' />
                    </Dimmer>
                </>
            )
        }
        if (extras) {
            return (
                <div className='cartSection' style={{ direction: direction }}>
                    {/* <div className='leftSide'> */}
                    {
                        extras.removeItem ?
                            <Extras menuItem={extras} dismiss={() => this.setState({ extras: null })} />
                            :
                            <MenuItem
                                removeRedirect={() => this.setState({ redirect: false })}
                                selectedItem={extras}
                                helpers={helpers}
                                direction={direction}
                                goBack={() => {
                                    this.setState({ extras: false })
                                }} />
                    }

                    {/* </div> */}
                    {/* <div className='rightSide' style={direction === 'rtl' ? { left: '0px', right: 'auto', backgroundImage: window.sideImage } : { left: 'auto', right: '0px', backgroundImage: window.sideImage }}>
                        <img className='sideImage' alt='sideImage' src={window.sideImage}></img>
                    </div> */}
                </div>
            )

        }
        return (
            <div className='cartSection' style={{ direction: direction }}>
                {login ?
                    <NewLogin
                        dismiss={() => this.setState({ login: false })}
                        success={() => this.setState(validateUser(this.props.user, receipt, selectedLocation, allLocations, settings))}>

                    </NewLogin>
                    : ''

                }
                {
                    showOfflineBanner && <Banner timings={timings} hide={() => this.setState({ showOfflineBanner: false })} />
                }
                {
                    (!showOfflineBanner && selectedLocation && selectedLocation.active && !selectedLocation.active.active) && <Banner timings={timings} active={selectedLocation.active} hide={() => this.setState({ showOfflineBanner: false })} />
                }

                <Header
                    count={count}
                    direction={direction}
                    fromCart={fromCart}
                    title={pageStep === 0 ? strings.yoyrcart : pageStep === 1 ?
                        (addcard ? strings.addNewCard : strings.Checkout)
                        : pageStep === 2 ?
                            strings.selectAddress
                            : pageStep === ''}
                    back={() => {
                        pageStep !== 0 ?
                            (addcard ? this.setState({ addcard: false })
                                : this.setState({ pageStep: selectedAddress ? pageStep - 1 : 0 }))
                            : window.history.back()
                    }
                    } />

                {

                    pageStep === 0 ?
                        <div className='cartDetail' >
                            {/* <div className='title'>
                                    {strings.yoyrcart}
                                </div> */}
                            {
                                Object.keys(orderList).length ?

                                    <div style={{ minHeight: '60%' }}>
                                        <div >
                                            <div className='cartList'>
                                                {
                                                    Object.keys(orderList).map((itemKey, index) => {
                                                        let item = orderList[itemKey]
                                                        let excluded = item.excluded && item.excluded[couponeCode]
                                                        let addedExtras = getAddedExtras(item)
                                                        let itemTotalPrice = calcItemTotal(item)
                                                        if (addedExtras && addedExtras.length) {
                                                            return addedExtras.map((extraRecord, innerIndex) => {
                                                                return (
                                                                    <div key={innerIndex} className='cartItem' style={{ borderBottom: '1px solid #cccccc40' }}>
                                                                        <div style={{ maxWidth: '70%' }}>
                                                                            <div>{direction === 'rtl' && extraRecord.name_ar ? extraRecord.name_ar : extraRecord.name}</div>
                                                                            <div className='itemPrice'>
                                                                                {
                                                                                    discountApplied && !excluded && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: direction === 'rtl' ? '0 0 0 8px' : '0 8px 0 0', color: '#adadad' }}>{extraRecord.price + strings[settings?.currency] + ' '}</span>
                                                                                }
                                                                                {
                                                                                    ((discountApplied && !excluded) ? ((extraRecord.price * discountApplied).toFixed(2) + strings[settings?.currency]) : (extraRecord.price + strings[settings?.currency]))
                                                                                }



                                                                            </div>
                                                                            {
                                                                                extraRecord.addons.map((item, pos) => {
                                                                                    let extraCount = extraRecord.addons?.filter(rec => rec === item)?.length
                                                                                    if (extraRecord.addons.indexOf(item) === pos) {
                                                                                        return <div key={pos} className="itemDetails">{strings.added + extraCount + "x " + item}</div>
                                                                                    }
                                                                                    return ''
                                                                                })
                                                                            }
                                                                            {
                                                                                item.specialNotes && <div className="itemDetails">"{item.specialNotes}"</div>
                                                                            }
                                                                        </div>
                                                                        <div className='btnHolder'>
                                                                            <Btn counter={extraRecord.count} menuItem={item} showExtras={(extras) => this.setState({ extras })} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            )
                                                        }

                                                        return (
                                                            <div key={index} className='cartItem' style={{ borderBottom: '1px solid #cccccc40' }}>
                                                                <div style={{ maxWidth: '70%' }}>
                                                                    <div>{direction === 'rtl' && item.name_ar ? item.name_ar : item.name}</div>
                                                                    <div className='itemPrice'>
                                                                        {
                                                                            discountApplied && !excluded && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: direction === 'rtl' ? '0 0 0 8px' : '0 8px 0 0', color: '#adadad' }}>{calcItemTotal(item) + strings[settings?.currency] + ' '}</span>
                                                                        }
                                                                        {
                                                                            (discountApplied && !excluded ? ((itemTotalPrice * discountApplied).toFixed(2) + strings[settings?.currency]) : (calcItemTotal(item) + strings[settings?.currency]))
                                                                        }
                                                                    </div>

                                                                </div>
                                                                <div className='btnHolder'>
                                                                    <Btn menuItem={item} showExtras={(extras) => this.setState({ extras })} />
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                }

                                                <div className='notes'>
                                                    <div>{strings.specialNotes}</div>
                                                    <TextArea
                                                        onChange={(e, { value }) => { this.setState({ specialNotes: value }) }}
                                                        type=''
                                                        placeholder={strings.specialNotes}
                                                        style={{
                                                            margin: '8px 0',
                                                            width: '100%',
                                                            border: 'none',
                                                            borderRadius: '4px',
                                                            outline: 'none',
                                                            fontSize: '12px',
                                                            fontWeight: '100',
                                                            minHeight: '90px',
                                                            backgroundColor: '#f5f5f5',
                                                            padding: '8px'
                                                        }}>

                                                    </TextArea>
                                                </div>
                                            </div>
                                            <div className='separator'></div>

                                            {
                                                loyalty && <div className='discountContainer' style={{ paddingTop: '18px' }}>
                                                    <div className='discountContainer2' style={{ backgroundColor: 'var(--counterBtn)' }}>
                                                        <div className='text' style={{ color: 'var(--themeColor)' }}>
                                                            <Icon className='notificationicon' name='gift' />
                                                            {<div>{direction === 'rtl' ? 'ستحصل على ' : 'You will earn '}
                                                                <span style={{ fontSize: '14px', color: 'var(--themeColor)' }}>
                                                                    {earningPoints}
                                                                </span>
                                                                {direction === 'rtl' ? ' نقاط ولاء عند إكمال هذا الطلب ' : ' Loyalty Points when you complete this order'}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            <div className='listDetails' style={{ marginBottom: '90px' }}>
                                                <div className='subDetail'>
                                                    <div>{strings.subtotal}</div>
                                                    <div>{receipt.subTotal + strings[settings?.currency]}</div>
                                                </div>
                                                {/* <div className='subDetail'>
                                        <div>{strings.vat}</div>
                                        <div>{receipt.vat + strings[window.currency]}</div>
                                    </div> */}
                                                {
                                                    receipt.delivery &&
                                                    <div className='subDetail'>
                                                        <div>{strings.delivery}</div>
                                                        <div>{receipt.delivery + strings[settings?.currency]}</div>
                                                    </div>
                                                }
                                                {
                                                    receipt.deductedValue ?
                                                        <div className='subDetail' style={{ color: 'var(--themeColor)' }}>
                                                            <div>{strings.discount} <span style={{ fontSize: '10px', color: '#cccccc' }}>({coupon ? coupon.code : 'loyalty pts'})</span></div>
                                                            <div>{" - " + receipt.deductedValue + strings[settings?.currency]}</div>
                                                        </div>
                                                        : ''
                                                }

                                                <div className='mainDetail'>
                                                    <div>{strings.total} {settings?.noVat ? '' : <span style={{ fontSize: '10px', color: '#cccccc' }}>{strings.InclusiveOfVat}</span>} </div>
                                                    <div> {(receipt.total) + strings[settings?.currency]}</div>
                                                </div>

                                                {
                                                    errorMinOrder &&
                                                    <div className='error'>

                                                        <div className='discountContainer'>
                                                            <div className='discountContainer2'>
                                                                <div className='text'>
                                                                    <Icon className='notificationicon' name='info' />
                                                                    {errorMinOrder}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='actionBtn'>
                                            <Button loading={loading} className='placeOrderBtn' disabled={showOfflineBanner || (selectedLocation && selectedLocation.active && !selectedLocation.active.active)} onClick={() => {
                                                this.setState({ loading: true }, () => {
                                                    setTimeout(() => {
                                                        let newState = validateUser(user, receipt, selectedLocation, allLocations, settings)
                                                        this.setState(newState, () => {
                                                            let { errorMinOrder } = newState
                                                            if (errorMinOrder) {
                                                                window.scrollTo(0, document.body.scrollHeight);
                                                            }
                                                        })
                                                    }, 1000)
                                                })
                                            }}>{strings.continue}</Button>
                                        </div>
                                    </div>
                                    :
                                    <div className='empty' style={{ textAlign: 'center' }}>
                                        <div className='title'>
                                            {strings.cartEmpty}
                                        </div>
                                        <div className='detail'>
                                            {strings.addItemsFrom} <Link to={path ? ('/' + path) : '/'}>{strings.menu}</Link> {strings.toPlaceOrder}
                                        </div>
                                    </div>
                            }

                        </div>
                        : pageStep === 2 ?
                            this.renderAddAddress()
                            : pageStep === 1 && selectedAddress ?
                                addcard ?
                                    (settings?.stripe ?

                                        <StripeCard goBack={(card) => { this.setState({ addcard: false, selectedCard: card }) }} />
                                        :
                                        <Payment success={(res) => {
                                            cardObject[res.card.id] = res.card;
                                            this.setState({
                                                cardObject,
                                                addcard: false,
                                                selectedCard: res.card,
                                                tokenId: res.id,
                                                error: false
                                            })
                                        }}
                                            direction={direction}
                                        />)
                                    :
                                    this.renderCheckout()
                                : ''

                }

                {
                    selectAddress && this.renderAddressPicker()
                }


            </div >
        )
    }
    renderCheckout() {
        let { orderList, user, selectedLocation, allLocations, loyalty, helpers, settings } = this.props;
        let { percent, freeItem, coupon } = helpers ? helpers : {}
        let { selectedAddress, selectedPayment, specialNotes, loading, direction, selectedCard, cardObject, error, orderId } = this.state
        selectedAddress.fees = selectedLocation.fees
        let receipt = calcGrandTotal(user, helpers, orderList, freeItem, selectedAddress, loyalty, settings)
        let _this = this

        if (this.state.changeTable) {
            return <SelectLocation direction={direction} dismiss={(area) => this.setState(validateUser(user, receipt, area, allLocations, settings))} branch={selectedLocation.branches[0]} />
        }
        return (
            <div className='cartDetail'>
                <div className='section'>

                    <div className='title'>
                        {selectedLocation.type === 'pickup' ? strings.pickupfrom : selectedLocation.type === 'dineIn' ? strings.servingTo : strings.deliveringTo}
                    </div>

                    {
                        selectedAddress ?
                            <div className='cartList' style={{ fontWeight: '600' }}>
                                <div key={"index"} className='cartItem' style={{ border: 'none', padding: '8px 0' }}>
                                    <div>
                                        <span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>

                                        {
                                            selectedAddress.type === 'pickup' ?
                                                <>
                                                    {localizeString(selectedAddress, 'area')}
                                                    <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                        {localizeString(selectedAddress, 'address')}
                                                    </div>
                                                </>

                                                : selectedAddress.type === 'dineIn' ?
                                                    strings.tableNumber + selectedAddress.location

                                                    :

                                                    <>
                                                        {selectedAddress.label}
                                                        <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                            {(selectedAddress.area.name ? (direction === 'rtl' ? selectedAddress.area.name_ar : selectedAddress.area.name) : localizeString(selectedAddress, 'area')) + " - " + selectedAddress.fullAddress}
                                                        </div>
                                                    </>
                                        }


                                    </div>
                                    {selectedLocation.type !== 'pickup' && <Button compact className="removeBtn" basic onClick={() => {
                                        if (selectedLocation.type === 'dineIn') {
                                            this.setState({ changeTable: true })
                                        } else {
                                            this.setState({ selectAddress: true })
                                        }
                                    }
                                    }>{strings.Change}</Button>}
                                </div>

                            </div>
                            :
                            <div className='cartList' style={{ fontWeight: '600' }}>
                                <div key={"index"} className='cartItem' >
                                    <div><span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>{user && user.info ? user.info.area : ''}</div>
                                    <Button compact className="removeBtn" basic onClick={() => this.setState({ selectAddress: true })}>{strings.Change}</Button>
                                </div>
                            </div>
                    }
                </div>

                <div className='section'>

                    <div className='title'>
                        {strings.paymentMethod}
                    </div>

                    <div className='cartList' style={{ fontWeight: '600' }}>
                        <div style={{ display: 'contents' }} className='cartItem' >
                            {
                                settings?.paymentMeth.map((item, index) => {
                                    return (
                                        <div key={index} style={{ padding: '6px 0' }} onClick={() => this.setState({ selectedPayment: item })}>
                                            <Icon style={{ color: 'var(--themeColor)' }} name={selectedPayment === item ? 'selected radio' : 'circle outline'} />
                                            <span style={{ padding: '4px' }}>
                                                {strings[selectedLocation.type === 'dineIn' ? (item + 'DineIn') : selectedLocation.type === 'pickup' ? (item + 'Pickup') : item]}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            {
                                selectedPayment === 'online' && <>

                                    {

                                        settings?.stripe ?
                                            user?.info?.stripeCards?.filter(r => r.stripe_account === settings?.stripe_id)?.map(card => {
                                                return (
                                                    <PaymentCard
                                                        selected={selectedCard && (selectedCard.id === card.id)}
                                                        key={card.id}
                                                        onClick={() => {
                                                            this.setState({ selectedCard: card })
                                                        }}
                                                        last_four={card.last_four || card?.card?.last4}
                                                    />

                                                )
                                            })
                                            :
                                            cardObject && Object.keys(cardObject).map((record, index) => {
                                                let card = cardObject[record];

                                                return (
                                                    <PaymentCard
                                                        selected={selectedCard?.id === card.id}
                                                        key={index}
                                                        onClick={() => {
                                                            this.setState({ selectedCard: card })
                                                        }}
                                                        last_four={card.last_four}
                                                    />

                                                )
                                            })

                                    }
                                    <div className='addNewCard' onClick={() => {
                                        this.setState({ addcard: true })
                                    }}>{strings.addNewCard}</div>

                                    {/* <Payment /> */}
                                </>
                            }
                        </div>
                    </div>
                </div>

                <div className='listDetails' style={{ boxShadow: 'rgba(0, 0, 0, 0.03) 0px -4px 6px', marginBottom: '90px' }}>
                    <div style={{ padding: '0 16px 8px' }}>
                        {
                            coupon && <div key={'coupon'} className='cartItem' style={{ color: 'var(--themeColor)', fontWeight: '800', border: 'none', padding: '8px', margin: '16px 0', backgroundColor: 'var(--counterBtn)', borderRadius: '8px' }}>
                                <div style={{ padding: '8px' }}>{coupon.code}</div>
                                <Button icon='cancel' className="removeBtn" basic onClick={() => this.props.setHelpers({})}></Button>
                            </div>
                        }
                        {
                            (percent || freeItem) ? ''
                                :
                                <div className='cartItem'>
                                    <div className='coupon'>
                                        <div style={{ display: 'flex', width: '100%', direction: 'ltr' }}>
                                            <Input style={{ width: '100%' }}
                                                onChange={(e, { value }) => this.setState({ couponeCode: value.toLowerCase() })}
                                                placeholder={strings.promoCode}
                                                labelPosition={direction === 'ltr' ? 'right' : 'left'}
                                                label={<Button className='ApplyCoupon'
                                                    onClick={() => user ?
                                                        applyCoupone(this.state.couponeCode, user, orderList, selectedLocation.type, settings).then(result => {
                                                            if (result && result.error) {
                                                                return (_this.setState(result))
                                                            }
                                                            _this.props.setHelpers(result)
                                                        }

                                                        )
                                                        : this.setState({ login: true })}>
                                                    {strings.applyCoupone}
                                                </Button>} />
                                        </div>
                                    </div>
                                </div>

                        }

                    </div>

                    <div className='subDetail'>
                        <div>{strings.subtotal}</div>
                        <div>{receipt.subTotal + strings[settings?.currency]}</div>
                    </div>
                    {
                        receipt.delivery &&
                        <div className='subDetail'>
                            <div>{strings.delivery}</div>
                            <div>{receipt.delivery + strings[settings?.currency]}</div>
                        </div>
                    }
                    {
                        receipt.deductedValue ?
                            <div className='subDetail' style={{ color: 'var(--themeColor)' }}>
                                <div>{strings.discount} <span style={{ fontSize: '10px', color: '#cccccc' }}>({coupon ? coupon.code : 'loyalty pts'})</span></div>
                                <div>{" - " + receipt.deductedValue + strings[settings?.currency]}</div>
                            </div>
                            : ''
                    }

                    <div className='mainDetail'>
                        <div>{strings.total} {settings?.noVat ? '' : <span style={{ fontSize: '10px', color: '#cccccc' }}>{strings.InclusiveOfVat}</span>} </div>
                        <div> {(receipt.total) + strings[settings?.currency]}</div>
                    </div>
                    {
                        error && <div className='error'>

                            <div className='discountContainer'>
                                <div className='discountContainer2' style={{ justifyContent: 'center' }}>
                                    <div className='text'>
                                        {error}
                                    </div>

                                </div>
                            </div>

                        </div>
                    }

                </div>
                <div className='actionBtn'>
                    {
                        selectedPayment === 'applePay' ?
                            <PaymentBtn
                                receipt={receipt}
                                walletPayNow={async (id) => {
                                    receipt.paymentType = selectedPayment;
                                    receipt.delivery = selectedLocation.fees;
                                    receipt.branch = selectedLocation.branches;
                                    receipt.specialNotes = specialNotes ? specialNotes : ''
                                    receipt.resName = settings?.resName
                                    receipt.path = settings?.pathName ? settings?.pathName : window.location.hostname
                                    receipt.lang = direction
                                    receipt.currency = settings?.currency;
                                    let customer_id = user?.info?.stripeCards?.find(r => r.stripe_account === settings?.stripe_id)?.user_stripeId;
                                    try {
                                        let createPaymentIntent = functions().httpsCallable('createPaymentIntentClient');
                                        let paymentIntent = await createPaymentIntent({
                                            id: orderId,
                                            amount: Number((receipt.total * 100).toFixed(2)),
                                            customer: customer_id,
                                            redirectUrl: `${window.location.host}/${settings?.pathName}`,
                                            payment_method: id,
                                            discount: coupon,
                                            resKey: settings?.resKey,
                                            destination: settings?.stripe_id
                                        });
                                        if (paymentIntent.data.error) {
                                            return _this.setState({ error: paymentIntent.data.error, loading: false })
                                        }
                                        if (["requires_confirmation", "requires_action"].includes(paymentIntent?.data?.status)) {
                                            const { error: confirmError } = await _this.props.stripe.confirmCardPayment(
                                                paymentIntent?.data?.id,
                                                { payment_method: id },
                                                // { handleActions: false }
                                            );
                                            if (confirmError) {
                                                throw confirmError
                                            }
                                        }
                                        await placeOrder(orderList, user, freeItem, percent, coupon, receipt, null, 'orders', settings, orderId, paymentIntent?.data).then(resut => {
                                            this.setState(resut, () => {
                                                if (!resut.error) {
                                                    this.props.flushOrders()
                                                }
                                            });
                                        });
                                    } catch (error) {
                                        console.log("errorerrorerror", error);
                                        return _this.setState({ error: 'Cannot Authorize this Transaction', loading: false })

                                    }



                                }}

                            />
                            :
                            <Button
                                className='placeOrderBtn'
                                loading={loading}
                                disabled={this.state.disableOrder || loading || (selectedLocation && selectedLocation.active && !selectedLocation.active.active)}
                                onClick={() => {
                                    let _this = this;
                                    this.setState({ loading: true }, async () => {
                                        receipt.paymentType = selectedPayment;
                                        receipt.delivery = selectedLocation.fees;
                                        receipt.branch = selectedLocation.branches;
                                        receipt.specialNotes = specialNotes ? specialNotes : ''
                                        receipt.resName = settings?.resName
                                        receipt.path = settings?.pathName ? settings?.pathName : window.location.hostname
                                        receipt.lang = direction
                                        receipt.currency = settings?.currency;
                                        if (selectedPayment === 'online') {
                                            if (!selectedCard) {
                                                return _this.setState({ error: 'Select Card', loading: false })
                                            }
                                            switch (settings?.stripe) {
                                                case true:
                                                    let createPaymentIntent = functions().httpsCallable('createPaymentIntentClient');
                                                    let paymentIntent = await createPaymentIntent({
                                                        id: orderId,
                                                        amount: Number((receipt.total * 100).toFixed(2)),
                                                        customer: selectedCard?.user_stripeId,
                                                        redirectUrl: `${window.location.host}/${settings?.pathName}`,
                                                        payment_method: selectedCard.id,
                                                        discount: coupon,
                                                        resKey: settings?.resKey,
                                                        destination: settings?.stripe_id
                                                    });
                                                    console.log("paymentIntentpaymentIntent", paymentIntent);
                                                    if (paymentIntent.data.error) {
                                                        return _this.setState({ error: paymentIntent.data.error, loading: false })
                                                    }
                                                    if (["requires_confirmation", "requires_action"].includes(paymentIntent?.data?.status)) {
                                                        await placeOrder(orderList, user, freeItem, percent, coupon, receipt, null, 'pendingOrders', settings, orderId);
                                                        const { error: confirmError } = await _this.props.stripe.confirmCardPayment(
                                                            paymentIntent?.data?.id,
                                                            { payment_method: selectedCard.id },
                                                            // { handleActions: false }
                                                        );
                                                        if (confirmError) {
                                                            console.log("confirmErrorconfirmError", confirmError);
                                                            return _this.setState({ error: 'Cannot Authorize this Transaction', loading: false })
                                                        }
                                                    }

                                                    await placeOrder(orderList, user, freeItem, percent, coupon, receipt, null, 'orders', settings, orderId, paymentIntent?.data).then(resut => {
                                                        this.setState(resut, () => {
                                                            if (!resut.error) {
                                                                this.props.flushOrders()
                                                            }
                                                        });
                                                    });

                                                    break;
                                                default:
                                                    var makeCharge = functions().httpsCallable('AuthTransactions');
                                                    selectedCard.phoneNumber = {
                                                        country_code: user.info.counterCode,
                                                        number: user.info.number
                                                    }
                                                    _this.props.updateUserAddress(receipt.selectedAddress)
                                                    let redirectUrl = window.location.href.split('?')[0]
                                                    let result = await makeCharge({ id: _this.state.tokenId, receipt: receipt, resKey: settings?.resKey, card: selectedCard, redirect: redirectUrl })
                                                    console.log("result", result)
                                                    let { data } = result
                                                    let { card, customer, id, status, transaction, errors } = data
                                                    if (errors) {
                                                        return _this.setState({ error: errors[0].description, loading: false })
                                                    }
                                                    card.customer = customer;
                                                    receipt.destinationId = settings?.destinationId;

                                                    if (["AUTHORIZED", "INITIATED"].includes(status)) {
                                                        let node = (status === "AUTHORIZED") ? 'orders' : 'pendingOrders'
                                                        let order = await placeOrder(orderList, user, freeItem, percent, coupon, receipt, id, node, settings, orderId);
                                                        console.log("tokenId", _this.state.tokenId)
                                                        _this.setState(order);
                                                        if (order.error) {
                                                            return
                                                        }
                                                        switch (status) {
                                                            case 'AUTHORIZED':
                                                                if (card && card.id) {
                                                                    firestore().collection('users').doc(auth().currentUser.uid).update({
                                                                        [`card.${card.id}`]: card
                                                                    })
                                                                }
                                                                _this.props.flushOrders()
                                                                break;
                                                            default:
                                                                window.location.href = transaction.url
                                                                break;
                                                        }
                                                    } else {
                                                        _this.setState({ error: 'Cannot Authorize this Transaction', loading: false })
                                                    }
                                                    break;
                                            }


                                        } else {
                                            placeOrder(orderList, user, freeItem, percent, coupon, receipt, false, 'orders', settings, orderId).then(resut => {
                                                this.setState(resut, () => {
                                                    if (!resut.error) {
                                                        this.props.flushOrders()
                                                    }
                                                });
                                            })
                                        }
                                    });
                                }}>

                                {strings.placeOrder}
                            </Button>
                    }

                </div>

            </div>

        )
    }
    renderAddressPicker() {
        let { user, selectedLocation, allLocations } = this.props
        let { addresses, direction } = this.state
        return (
            <Dimmer active page className='dimmer' >
                <div className='selectAddress' >
                    {/* <Icon className='iconTimes' name='times' icon='cancel' onClick={() => this.setState({ selectAddress: false })}></Icon> */}
                    <div className='cartDetail'>
                        <div className='title'>

                            <div style={{ margin: '10px', marginBottom: '20px', textAlign: 'start' }}>{strings.WhereToDeliver}</div>
                            <div className='cartList' style={{ margin: '8px 0', fontWeight: '600', maxHeight: '75vh', overflow: 'auto' }}>
                                {
                                    user && user.info && user.info.fullAddress && <div className='cartItem' onClick={() => this.setState({ selectAddress: false, selectedAddress: false })}>
                                        <div>
                                            <span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>
                                            {user && user.info ? user.info.area : ''}
                                            <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                {user && user.info ? user.info.fullAddress : ''}
                                            </div>

                                        </div>




                                    </div>

                                }


                                {
                                    addresses && Object.keys(addresses).map((item, index) => {
                                        let addrs = addresses[item]
                                        return (
                                            <div key={index} className='cartItem' style={{ borderBottom: '1px solid #cccccc40', opacity: addrs.disabled ? '0.4' : '1' }} >
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => this.setState(addrs.disabled ? {} : { selectedAddress: addrs, selectAddress: false })}>
                                                        <span><Icon style={{ color: 'var(--themeColor)' }} name='map marker alternate' /></span>
                                                        {addrs.label ? addrs.label : addrs.area}
                                                        <div style={{ fontWeight: '100', padding: '8px', fontSize: '12px', color: 'gray', textAlign: 'inherit' }}>
                                                            {(addrs.area && addrs.area.name ? (direction === 'rtl' ? addrs.area.name_ar : addrs.area.name) : addrs.area) + " - " + addrs.fullAddress}
                                                        </div>
                                                    </div>
                                                    <div onClick={() => this.props.SetAlertMessage({
                                                        title: strings?.['Delete Saved Location!'],
                                                        message: strings?.['Are you sure'],
                                                        confirmTitle: strings?.['Delete location'],
                                                        cancelTitle: strings?.['Cancel'],
                                                        onCancel: () => {
                                                            this.props.SetAlertMessage(false)
                                                        },
                                                        onConfirm: () => {
                                                            let uid = auth().currentUser.uid
                                                            firestore().collection('users').doc(uid).update({
                                                                [`addresses.${item}`]: firestore.FieldValue.delete()
                                                            })
                                                            this.props.SetAlertMessage(false)
                                                            delete addresses[item];
                                                            let newAddresses = CheckAddresses(addresses, allLocations, selectedLocation)
                                                            this.setState({ addresses: newAddresses?.addresses })
                                                        }

                                                    })}>
                                                        <Icon style={{ color: 'red' }} name='trash alternate outline' />
                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <div className='cartItem' style={{ border: 'none' }}>
                                    <Button className='tag' onClick={() => this.setState({ pageStep: 2, selectAddress: false })}>{strings.addNewAddress} </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dimmer>
        )

    }
    submitNewAddress() {
        let { selectedLocation, user, allLocations } = this.props

        let { address, location, notes, label, newLocation, building, street, floor, flat, direction, addresses } = this.state


        this.setState({ loading: true }, () => {
            let name = this.state.name ? this.state.name : user && user.info && user.info.name
            let newEmail = this.state.email ? this.state.email : user && user.info && user.info.email
            let uid = auth().currentUser.uid
            if (!selectedLocation || !name || !building || !street || !floor || !flat || !newEmail) {
                this.setState({ error: direction === 'rtl' ? 'يرجى تعبئة جميع البيانات المطلوبة!' : 'Please fill all required fields!', loading: false })
                return
            }
            console.log("locationlocation", location, selectedLocation?.location?.location);
            let id = Date.now()
            let post = {
                area: address ? address : selectedLocation ? selectedLocation?.location?.name : '',
                address: address ? address : selectedLocation ? selectedLocation?.location?.name : '',
                location: location || selectedLocation?.location?.location || '',
                fullAddress: [street, building, ('floor:' + floor), ('flat' + flat)].join(' - '),
                notes: notes ? notes : '',
                label: label ? label : 'Home'
            }
            firestore().collection('users').doc(uid).update({
                name: name,
                email: newEmail ? newEmail : '',
                [`addresses.${id}`]: post
            }).then(() => {
                if (newLocation) {
                    this.props.updateUserLocation(newLocation)
                }
                if (addresses) {
                    addresses[id] = post
                } else {
                    addresses = { [id]: post }
                }
                let newAddresses = CheckAddresses(addresses, allLocations, selectedLocation)
                this.setState({ pageStep: 1, selectedAddress: post, loading: false, newLocation: false, error: false, addresses: newAddresses?.addresses })

            }).catch((error) => {
                console.log("errorerror", error);
                this.setState({ error: error.message, loading: false })
            })
        })

    }
    submitNewUserInfo() {
        let { user } = this.props
        let name = this.state.name ? this.state.name : user && user.info && user.info.name
        let newEmail = this.state.email ? this.state.email : user && user.info && user.info.email
        let uid = auth().currentUser.uid
        firestore().collection('users').doc(uid).update({
            name: name,
            email: newEmail ? newEmail : ''
        }).then(() => {
            this.setState({ pageStep: 1, loading: false, error: false })
        }).catch((error) => {
            this.setState({ error: error.message, loading: false })
        })
    }
    renderUserInfo() {
        let { user } = this.props
        let { error, loading, direction } = this.state

        return (
            <div className='checkout'>
                <div className='cartDetail'>
                    <div className='title'>
                        {strings.addressDetails}
                    </div>
                    <div className='cartList' >
                        {
                            !user || !user.info || !user.info.name ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.name}</div>
                                    <Input fluid placeholder={direction === 'rtl' ? 'اسمك' : 'Your Name'} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            !user || !user.info || !user.info.email ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.email}</div>
                                    <Input fluid placeholder='name@email.com' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            error && <div className='error'>{error}</div>
                        }
                    </div>
                    <Button loading={loading} className='placeOrderBtn' onClick={() => this.submitNewUserInfo()}>{strings.save}</Button>

                </div>
            </div>

        )
    }
    renderAddAddress() {
        let { selectedLocation, user, allLocations } = this.props
        let { type } = selectedLocation
        if (type === "pickup") {
            return this.renderUserInfo()

        }
        let { label, error, loading, direction } = this.state
        let dropdownValue = this.state.address
        if (selectedLocation && !this.state.address) {
            if (selectedLocation.location.name) {
                dropdownValue = direction === 'rtl' && selectedLocation.location.name_ar ? selectedLocation.location.name_ar : selectedLocation.location.name
            } else {
                dropdownValue = selectedLocation.location
            }
        }
        let optimizedLocations = JSON.parse(JSON.stringify(allLocations)).map(item => {
            if (item.text.name) {
                item.text = direction === 'rtl' ? item.text.name_ar : item.text.name
                item.value = direction === 'rtl' ? item.value.name_ar : item.value.name
            }
            return item
        })
        return (
            <div className='checkout'>
                <div className='cartDetail'>
                    <div className='title'>
                        {strings.addressDetails}
                    </div>
                    <div className='cartList' >

                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.areaName}</div>
                            <Dropdown
                                search
                                style={{ padding: '14px 8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'rgba(247, 247, 247, 1)' }}
                                options={optimizedLocations}
                                scrolling
                                direction={direction === 'rtl' ? 'left' : 'right'}
                                selectOnBlur={false}
                                value={dropdownValue}
                                fluid
                                onChange={(e, { value }) => {
                                    let item = optimizedLocations.filter(item => item.value === value)[0];
                                    let originalLocation = allLocations.filter(originalItem => originalItem.key === item.key)
                                    console.log("originalLocationoriginalLocation", originalLocation);
                                    let newLocation = {
                                        location: originalLocation.length > 0 ? originalLocation[0].value : value,
                                        fees: item.fees,
                                        branches: item.branches,
                                        minOrder: item.minOrder,
                                        locationId: item.locationId,
                                        activeMenu: selectedLocation.activeMenu,
                                        type: selectedLocation.type
                                    }
                                    this.setState({ address: value, newLocation, error: false })
                                }
                                }
                                placeholder='business bay'
                            >
                            </Dropdown>
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.street}</div>
                            <Input fluid placeholder={direction === 'rtl' ? 'شارع الشيخ زايد' : 'Sheikh zayed st'} value={this.state.street} onChange={(e) => this.setState({ street: e.target.value, error: false })}></Input>
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.building}</div>
                            <Input fluid placeholder={direction === 'rtl' ? 'عمارة 23' : 'building no 23'} value={this.state.building} onChange={(e) => this.setState({ building: e.target.value, error: false })}></Input>
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '35%' }}>
                                    <div className='itemTitle'>{strings.floor}</div>
                                    <Input fluid placeholder='13' value={this.state.floor} onChange={(e) => this.setState({ floor: e.target.value, error: false })}></Input>
                                </div>
                                <div style={{ width: '60%' }}>
                                    <div className='itemTitle'>{strings.flat}</div>
                                    <Input fluid placeholder='1302' value={this.state.flat} onChange={(e) => this.setState({ flat: e.target.value, error: false })}></Input>
                                </div>
                            </div>
                        </div>
                        {/* <div className='cartItem' style={{ display: 'block' }} >
                                <div>{strings.fullAddress}</div>
                                <TextArea style={{ padding: '8px 0 0', width: '100%', border: 'none', outline: 'none' }} rows={2} transparent fluid placeholder='House no. /Flat no. /Floor /Building' value={this.state.fullAddress} onChange={(e) => this.setState({ fullAddress: e.target.value })}></TextArea>
                            </div> */}
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.addressLabel}</div>
                            <div style={{ display: 'flex' }}>
                                {
                                    (direction === 'rtl' ? ['منزل', 'مكتب', 'آخر'] : ["Home", "Office", "Other"]).map(item => {
                                        return (
                                            <Button key={item} basic className={item === label || item === this.state.otherAddressLabel ? 'tagSelected ' : 'tag'} onClick={() => this.setState({ label: item, otherAddressLabel: item, error: false })}>{item}</Button>
                                        )
                                    })
                                }


                            </div>
                            {
                                ['آخر', 'Other'].includes(this.state.otherAddressLabel) &&
                                <Input style={{ padding: '12px 0 0', }} fluid placeholder={direction === 'rtl' ? 'منزل' : 'ex. Home'} onChange={(e) => this.setState({ label: e.target.value, error: false })} />
                            }
                        </div>
                        <div className='cartItem' style={{ display: 'block' }} >
                            <div className='itemTitle'>{strings.notes}</div>
                            <Input style={{ width: '100%', border: 'none', outline: 'none' }} fluid placeholder={direction === 'rtl' ? 'خلف محطة ....' : 'ex. behind ... '} value={this.state.notes} onChange={(e) => this.setState({ notes: e.target.value, error: false })}></Input>
                        </div>
                        {
                            !user || !user.info || !user.info.name ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.name}</div>
                                    <Input fluid placeholder={direction === 'rtl' ? 'اسمك' : 'Your Name'} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            !user || !user.info || !user.info.email ?
                                <div className='cartItem' style={{ display: 'block' }} >
                                    <div className='itemTitle'>{strings.email}</div>
                                    <Input fluid placeholder='name@email.com' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, error: false })}></Input>
                                </div>
                                : ''
                        }
                        {
                            error && <div className='error'>{error}</div>
                        }
                    </div>

                    <Button loading={loading} className='placeOrderBtn' onClick={() => this.submitNewAddress()}>{strings.saveAddress}</Button>

                </div>
            </div>
        )

    }
}

function mapStateToProps({ userProp, orderList, loyalty, settings }) {
    return {
        orderList: orderList,
        user: userProp ? userProp.user : {},
        selectedLocation: userProp ? userProp.location : {},
        timings: userProp ? userProp.timings : {},
        allLocations: userProp ? userProp.allLocations : [],
        userAddressProp: userProp ? userProp.userAddressProp : {},
        loyalty,
        helpers: userProp ? userProp.helpers : {},
        activeOrder: userProp ? userProp.activeOrder : false,
        settings: settings

    }
}

const mapDispatchToProps = dispatch => ({
    flushOrders: () => dispatch(flushOrders()),
    updateUserLocation: (location) => dispatch(updateUserLocation(location)),
    updateUserAddress: (address) => dispatch(updateUserAddress(address)),
    setHelpers: (helpers) => dispatch(setHelpers(helpers)),
    SetAlertMessage: (data) => dispatch(SetAlertMessage(data))

});
const Checkout = connect(mapStateToProps, mapDispatchToProps)(Cart)

export default function InjectedCheckoutForm() {
    const { stripe_id } = useSelector(state => state.settings);
    if (!stripe_id) {
        return <Checkout />

    }
    const stripePromise = loadStripe(process.env.REACT_APP__STRIPE, {
        stripeAccount: stripe_id
    });

    return (
        <Elements stripe={stripePromise} >
            <ElementsConsumer>
                {({ stripe, elements }) => (
                    <Checkout stripe={stripe} elements={elements} />
                )}
            </ElementsConsumer>

        </Elements>


    )
}

// export default connect(mapStateToProps, mapDispatchToProps)(Cart)
import React, { Component } from 'react';
// import '../../Login/login.css'
import './item.css'

import { Dimmer, Checkbox, Icon, Button, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { updateOrder } from '../../../reducers/newOrder'
import LocalizedStrings from 'react-localization';
import { firestore } from 'firebase'
import { Redirect } from 'react-router-dom';
class Extras extends Component {
    state = {
        loading: true,
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
        optimizedItems: {},
        checked: {},
        count: 1
    }
    strings = new LocalizedStrings({
        en: {
            specialNotes: "Special Notes",
            addToCart: "Add to Basket",
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",
            required: "Required",
            chooseAny: "Choose Any ",
            chooseAtLeast: "Choose at least ",
            chooseUpTo: "Choose up to ",
            choose: "Choose ",
            items: " Item(s)",
            remove: "Remove",
            Qty: "Qty "
        },
        ar: {
            specialNotes: "ملاحظات خاصة",
            addToCart: "اضف إلى الطلبات",
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",
            required: "مطلوب",
            chooseAny: "إختر ",
            chooseAtLeast: "اختر بحد ادنى ",
            chooseUpTo: "اختر بحد اقصى ",
            choose: "إختر ",
            items: "",
            remove: "مسح",
            Qty: "عدد "


        }
    });
    componentWillMount() {
        let { menu, removeRedirect } = this.props
        this.strings.setLanguage(this.state.direction === 'rtl' ? 'ar' : 'en');
        window.scrollTo({
            top: '0'
        })
        if (menu) {
            this.getMenuItem()
        }
        if (removeRedirect) {
            removeRedirect()
        }
    }
    getMenuItem() {
        let { menu, orderList, selectedItem, settings } = this.props
        var url = new URL(window.location.href);
        var itemId = url.searchParams.get("itemId");
        let count = orderList[itemId] ? orderList[itemId].count : 1
        let menuItem = (menu && menu[itemId]) ? menu[itemId] : selectedItem;
        let extras = (menuItem && menuItem.modifierList) ? menuItem.modifierList : [];
        if (menuItem) {
            if (extras.length === 0) {
                return this.setState({ loading: false, menuItem, count, allModifiersData: {}, price: Number(menuItem.price) })
            }
        } else {
            return this.setState({ redirect: '/' })
        }

        let all = []
        let allModifiersData = {}
        extras.map((extraCategory) => {
            let modifierObject = {}
            modifierObject.name = extraCategory.name
            modifierObject.name_ar = extraCategory.name_ar
            modifierObject.count = extraCategory.count
            modifierObject.type = extraCategory.type
            modifierObject.min_count = extraCategory.min_count
            modifierObject.max_count = extraCategory.max_count
            modifierObject.items = []
            let modifiers = extraCategory.items

            let promise = new Promise((resolve, reject) => {
                modifiers.map((modifierKey, innerIndex) => {
                    firestore().collection('resturant').doc(settings?.resKey).collection('menuItems').doc(modifierKey).get().then(result => {
                        if (result.exists && !result.data().hide) {
                            let value = result.data()
                            value.id = modifierKey
                            modifierObject.items.push(value)
                            allModifiersData[value.id] = value
                        }
                        if (modifiers.length === innerIndex + 1) {
                            resolve(modifierObject)
                        }
                    })
                    return false
                })
            })
            return all.push(promise)
        })
        Promise.all(all).then(result => {
            let optimizedItems = []
            result.map(item => {
                return optimizedItems.push(item)
            })

            this.setState({ optimizedItems, count: 1, allModifiersData, loading: false, price: Number(menuItem.price), menuItem })
        })
    }
    componentWillReceiveProps(prop) {
        let { menu } = prop
        if (menu && (menu !== this.props.menu)) {
            this.getMenuItem()
        }
        setTimeout(() => {
            if (!menu) {
                this.setState({ redirect: true })
            }
        }, 1500)
    }

    render() {
        let { loading, redirect } = this.state
        const { settings } = this.props
        if (redirect) {
            return (<Redirect to={(settings?.pathName ? ('/' + settings?.pathName) : '/')} />)
        }
        return (
            <div className='itemClass'>
                {
                    loading ?
                        <Dimmer active page>
                            {
                                <Loader active />

                            }
                        </Dimmer>
                        : this.renderStep()
                }
            </div>

        )
    }
    submit() {
        let { orderList } = this.props
        let { optimizedItems, checked, allModifiersData, price, menuItem, count } = this.state
        if (optimizedItems && optimizedItems.length) {
            let count = (orderList[menuItem.id] && orderList[menuItem.id].count) ? orderList[menuItem.id].count : 0;
            count = count + this.state.count;
            menuItem.count = count;
            let selectedExtras = {};
            optimizedItems.map(item => {
                let value = checked[item.name]
                if (value) {
                    let selectedModifiers = value.map(modifierKey => {
                        return allModifiersData[modifierKey]
                    })
                    selectedExtras[item.name] = selectedModifiers
                }
                return selectedExtras
            })
            menuItem.modifiedPrice = (menuItem.modifiedPrice ? menuItem.modifiedPrice : 0) + (price * this.state.count)
            let prevExtras = orderList && orderList[menuItem.id] && orderList[menuItem.id].selectedExtras;
            if (prevExtras) {
                let counter = this.state.count
                for (var i = count - 1; i >= (count - counter); i--) {
                    prevExtras[i] = selectedExtras
                }
                menuItem.selectedExtras = prevExtras
            } else {
                let counter = this.state.count
                let extrasArray = []
                for (var e = count - 1; e >= (count - counter); e--) {
                    extrasArray[e] = selectedExtras
                }
                menuItem.selectedExtras = extrasArray
            }
        } else {
            menuItem.count = count
        }
        orderList[menuItem.id] = menuItem;
        this.props.updateOrder(orderList);
        if (window.location.href.includes("home")) {
            return this.props.redirect()
        }
        if (this.props.goBack) {
            this.props.goBack()
        } else {
            window.history.back()
        }
        return
    }
    enableAdd() {
        let { optimizedItems, checked } = this.state
        if (optimizedItems && optimizedItems.length) {
            let allowAdd = optimizedItems.map(item => {
                let { min_count } = item
                if (Number(min_count) === 0) {
                    return true
                }
                if (checked && checked[item.name] && checked[item.name].length >= Number(min_count)) {
                    return true
                }
                return false
            })
            if (allowAdd.includes(false)) {
                return true
            } else {
                return false
            }
        }
        return false
    }
    addExtra(item, extraItem) {

        let { checked, price, allModifiersData } = this.state
        let { max_count, name, type } = item
        let category = checked[name] ? checked[name] : [];
        if (type === 'multiple') {
            category.push(extraItem.id)
            price = Number(price) + Number(extraItem.price ? extraItem.price : 0)

        } else {

            if (category.includes(extraItem.id)) {
                price = Number(price) - Number(extraItem.price ? extraItem.price : 0)
                category = category.filter(item => item !== extraItem.id)

            } else {
                if (category && (Number(max_count) !== 0) && category.length >= Number(max_count)) {

                    let lastId = category[category.length - 1];
                    price = Number(price) - Number(allModifiersData[lastId].price ? allModifiersData[lastId].price : 0);
                    category.pop()
                    category.push(extraItem.id)
                    price = Number(price) + Number(extraItem.price ? extraItem.price : 0)
                } else {
                    category.push(extraItem.id)
                    price = Number(price) + Number(extraItem.price ? extraItem.price : 0)
                }
            }
        }


        checked[name] = category
        this.setState({ checked, price: price.toFixed(2) })
    }
    count(type) {
        let { count, price } = this.state
        if (type) {
            // price = (Number(price) / count) * (count+1)
            count += 1

        } else {
            // price = count === 1 ? price : Number(price) / (count * (count-1))
            count = count === 1 ? count : count -= 1
        }
        this.setState({ count, price })

    }
    removeItem(index) {
        let { orderList } = this.props
        let { remove } = this.state
        let { selectedExtras } = remove
        selectedExtras.splice(index, 1)

        let newObject = Object.assign({}, remove)

        newObject.count--
        remove.count--
        delete newObject.removeItem
        orderList[newObject.id] = newObject;
        if (newObject.count === 0) {
            delete orderList[newObject.id]
        }
        this.props.updateOrder(orderList);
        this.setState({ remove })
    }
    getCategouryName(name, direction) {
        let { remove } = this.state
        if (direction === 'rtl') {
            let { modifierList } = remove
            let categoury = modifierList.filter(item => item.name === name)
            if (categoury && categoury.length > 0) {
                return categoury[0].name_ar
            }
        }
        return name
    }
    renderStep() {
        let { error, loading, optimizedItems, direction, checked, price, remove, menuItem } = this.state
        let { selectedLocation, helpers, settings } = this.props
        let { discountApplied } = helpers ? helpers : false
        let strings = this.strings
        let excluded = menuItem?.excluded && menuItem.excluded[helpers?.couponeCode]

        return (
            <div className='popContainer' >
                <Dimmer active={this.state.showDisabled} page onClick={() => this.setState({ showDisabled: false })}>
                    <div style={{ position: 'relative', backgroundColor: 'white', borderRadius: '8px', padding: '24px', color: 'rgb(74, 74, 74)' }}>{
                        direction === 'rtl' ?
                            <>
                                <div style={{ fontSize: '16px', paddingBottom: '8px' }}>
                                    الطلب التلقائي موقوف حالياً
                                </div>
                                <div>الرجاء طلب المساعدة من طاقم المطعم</div>
                            </>
                            :
                            <>
                                <div style={{ fontSize: '16px', paddingBottom: '8px' }}>
                                    Self ordering is currently disabled
                                </div>
                                <div>Kindly ask the waiter to take your order</div>
                            </>
                    }</div>
                </Dimmer>
                {
                    menuItem.pictures && menuItem.pictures.length > 0 ? <div className='itemImage' style={{ backgroundImage: 'url("' + menuItem.pictures[menuItem.pictures.length - 1] + '")' }}>
                        <div
                            style={{ padding: '16px', color: 'var(--themeColor)', direction: 'ltr' }}
                            onClick={() => {
                                if (this.props.goBack) {
                                    this.props.goBack()
                                } else {
                                    window.history.back()
                                }

                            }}>
                            <Icon size='big' style={{
                                backgroundColor: 'white',
                                display: 'flex',
                                borderRadius: '50%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '26px',
                                height: '26px'
                            }} name='arrow alternate circle left outline'></Icon>
                        </div>
                    </div>
                        :
                        <div
                            style={{ padding: '16px', color: 'var(--themeColor)', direction: 'ltr' }}
                            onClick={() => {
                                if (this.props.goBack) {
                                    this.props.goBack()
                                } else {
                                    window.history.back()
                                }
                            }}>

                            <Icon size='big' style={{
                                backgroundColor: 'white',
                                display: 'flex',
                                borderRadius: '50%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '26px',
                                height: '26px'
                            }} name='arrow alternate circle left outline'></Icon>
                        </div>
                }

                <div className='titleContainers'>
                    {/* <Icon name='times' className='iconTimes' style={{ top: '0px' }} onClick={() => this.props.dismiss()} /> */}
                    <div className='title'>{direction === 'rtl' ? menuItem.name_ar : menuItem.name}</div>
                    <div className='details'>{direction === 'rtl' ? menuItem.detail_ar : menuItem.detail}</div>

                </div>
                {
                    remove ?
                        <div className='inputsContainer'>
                            {
                                remove.selectedExtras.map((item, index) => {
                                    return (
                                        <div key={index} className='deleteItemsContainer'>
                                            <div className='textContainer'>
                                                <div className='upperText'>{direction === 'rtl' ? remove.name_ar : remove.name}</div>
                                                <div style={{ display: 'flex' }}>
                                                    {
                                                        Object.keys(item).map((categury, index) => {
                                                            return (
                                                                <div key={index} className='lowerText'>{
                                                                    this.getCategouryName(categury, direction) + ": " +
                                                                    [item[categury].map(items => direction === 'rtl' ? items.name_ar : items.name)].join(" - ")
                                                                }</div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className={'btnContainerInv'} onClick={() => this.removeItem(index)}>
                                                <div style={{ color: 'var(--themeColor)' }} >{strings.remove}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div>
                            <div className='inputsContainer'>
                                {
                                    optimizedItems && optimizedItems.length ? optimizedItems.map((item, index) => {
                                        let { name, name_ar, items, type, min_count, max_count } = item
                                        let checkedItems = checked[item.name];
                                        console.log('****optimizedItems', item, min_count, max_count)

                                        let totalSelected = 0

                                        return (
                                            <div key={index} >
                                                <div className='underline'></div>

                                                <div className='mainoption'>
                                                    <div className='title' >{direction === 'rtl' ? name_ar : name}
                                                        {
                                                            min_count && Number(min_count) !== 0 && <span>{strings.required}</span>
                                                        }
                                                    </div>

                                                    {
                                                        min_count && max_count && Number(max_count) === Number(min_count) ?
                                                            <div className='subtitle' >{strings.choose + max_count}</div> :
                                                            ''
                                                    }
                                                    {
                                                        min_count && (!max_count || Number(min_count) > Number(max_count)) ?
                                                            <div className='subtitle' >{strings.chooseAtLeast + min_count}</div>

                                                            : ''
                                                    }
                                                    {
                                                        max_count && (!min_count || Number(max_count) > Number(min_count)) ?

                                                            <div className='subtitle' >{strings.chooseUpTo + max_count}</div>

                                                            : ''
                                                    }
                                                    <div className='checkitem'>

                                                        {
                                                            items.map(extraItem => {
                                                                let extraCount = checkedItems?.filter(rec => rec === extraItem.id)?.length
                                                                totalSelected += extraCount ? extraCount : 0
                                                                console.log("totalSelected", totalSelected)
                                                                console.log("extraCount", max_count)
                                                                console.log("checkedItems", checkedItems)
                                                                if (type === 'multiple') {
                                                                    return (

                                                                        <div key={extraItem.id}
                                                                            style={{ padding: "14px 0", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                                            className={direction === 'rtl' ? 'floatright' : ''}>
                                                                            <div style={{ display: 'flex', width: '70%', cursor: 'pointer' }}
                                                                                onClick={() => (totalSelected < max_count) && this.addExtra(item, extraItem)}
                                                                            >
                                                                                {
                                                                                    extraCount && (extraCount > 0) ? <div style={{ color: 'var(--themeColor)', fontWeight: '600' }}>{extraCount}x</div> : ''

                                                                                }
                                                                                <div
                                                                                    style={{ fontSize: '16px', textAlign: 'initial', maxWidth: '65%', padding: '0 4px', opacity: checkedItems && checkedItems.length === Number(max_count) ? '0.5' : '1' }}>
                                                                                    {direction === 'rtl' ? extraItem.name_ar : extraItem.name}
                                                                                </div>
                                                                            </div>

                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {

                                                                                    (extraItem.price && extraItem.price !== '0') &&
                                                                                    <div
                                                                                        className='price'
                                                                                        style={(checkedItems && checkedItems.includes(extraItem.id)) ? { color: 'var(--themeColor)', display: 'flex', alignItems: 'center' } : { color: 'rgba(74, 74, 74, 1)', display: 'flex', alignItems: 'center' }}>
                                                                                        {
                                                                                            discountApplied && !excluded && <span style={{ margin: '0 4px', fontSize: '11px', textDecoration: 'line-through', padding: '0 0 0 8px', color: '#adadad', opacity: checkedItems && checkedItems.length === Number(max_count) ? '0.5' : '1' }}>{extraItem.price + strings[settings?.currency] + ' '}</span>
                                                                                        }
                                                                                        {
                                                                                            <span style={{ opacity: checkedItems && checkedItems.length === Number(max_count) ? '0.5' : '1' }}>{(discountApplied && !excluded ? ((extraItem.price * discountApplied).toFixed(2) + strings[settings?.currency]) : (extraItem.price + strings[settings?.currency]))}</span>
                                                                                        }


                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    extraCount && (extraCount > 0) ?
                                                                                        <Icon
                                                                                            onClick={() => {

                                                                                                let newPrice = Number(price) - (extraItem.price ? (Number(extraItem.price) * extraCount) : 0)

                                                                                                checked[item.name] = checked[item.name].filter(rec => rec !== extraItem.id)
                                                                                                this.setState({ checked, price: newPrice })
                                                                                            }}
                                                                                            size='big'
                                                                                            style={{
                                                                                                fontSize: '14px', color: 'red', margin: '0 8px'
                                                                                            }} name='trash alternate outline'></Icon> : ''

                                                                                }
                                                                            </div>


                                                                        </div>
                                                                    )
                                                                }

                                                                return <div key={extraItem.id} style={{ padding: "12px 0", display: 'flex', justifyContent: 'space-between', alignContent: 'center' }} className={direction === 'rtl' ? 'floatright' : ''}>
                                                                    <Checkbox
                                                                        style={{ textAlign: 'initial', lineHeight: 'initial', fontSize: '16px' }} checked={checkedItems && checkedItems.includes(extraItem.id)} label={direction === 'rtl' ? extraItem.name_ar : extraItem.name} radio type='checkbox' onClick={() => this.addExtra(item, extraItem)} />
                                                                    {

                                                                        (extraItem.price && extraItem.price !== '0') &&
                                                                        <div
                                                                            className='price'
                                                                            style={(checkedItems && checkedItems.includes(extraItem.id)) ? { color: 'var(--themeColor)' } : { color: 'rgba(74, 74, 74, 1)' }}>
                                                                            {
                                                                                discountApplied && !excluded && !menuItem.offer && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: '0 0 0 8px', color: '#adadad' }}>{extraItem.price + strings[settings?.currency] + ' '}</span>
                                                                            }
                                                                            {
                                                                                ((discountApplied && !excluded && !menuItem.offer) ? ((extraItem.price * discountApplied).toFixed(2) + strings[settings?.currency]) : (extraItem.price + strings[settings?.currency]))
                                                                            }


                                                                        </div>
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) :
                                        ''
                                    // <div className='underline'></div>
                                }
                                {/* <div >
                                    <div className='underline'></div>
                                    <div className='mainoption'>
                                        <div className='title' >{strings.specialNotes} <span style={{ color: 'rgba(184, 184, 184, 1)', padding: '4px', fontSize: '12px' }}> {direction === 'rtl' ? '(اختياري)' : '(Optional)'}</span></div>
                                        <Input className='itemNotes' transparent placeholder={direction === 'rtl' ? 'اضف ملاحظاتك الخاصة هنا' : 'Add any instructions here..'} onChange={(e, { value }) => {
                                            this.setState({ specialNotes: value })
                                        }} />
                                    </div>
                                </div> */}
                                {
                                    error ?
                                        <div className='error'>{error}</div>
                                        : ''
                                }
                                <div className='qtybuttoncontaitner'>
                                    <div className='qtybutton'>
                                        <div className='remove' onClick={() => this.count(false)}>-</div>
                                        <div className='text'>{strings.Qty + ": " + this.state.count}</div>
                                        <div className='add' onClick={() => this.count(true)}>+</div>
                                    </div>
                                </div>

                            </div>
                            <div style={{ position: 'absolute', bottom: 0 }}>
                                <div className='submitbuttoncontainer'>
                                    <div style={{ width: '80%', backgroundColor: 'white',borderRadius:'6px' }}>
                                        <Button loading={loading} disabled={this.enableAdd()} className='submitbutton' onClick={() => {
                                            if (selectedLocation && !selectedLocation.location) {
                                                this.setState({ showDisabled: true }, () => {
                                                    setTimeout(() => {
                                                        this.setState({ showDisabled: false })
                                                    }, 3000);
                                                })
                                                return
                                            }
                                            this.submit()
                                        }}>
                                            <div className='addtobasket'>{strings.addToCart}</div>
                                            <div className='totalprice'>{
                                                ((discountApplied && !excluded && !menuItem.offer) ? (discountApplied * price * this.state.count).toFixed(2) : (price * this.state.count).toFixed(2)) + strings[settings?.currency]

                                            }</div>
                                        </Button>
                                    </div>

                                </div>
                            </div>

                        </div>
                }


            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        orderList: state.orderList,
        menu: state.userProp ? state.userProp.menuMap : {},
        user: state.userProp ? state.userProp.user : {},
        selectedLocation: state.userProp ? state.userProp.location : {},
        lastRes: state.userProp ? state.userProp.lastRes : '',
        allLocations: state.userProp ? state.userProp.allLocations : [],
        categoryMenu: state.userProp ? state.userProp.categoryMenu : false,
        loyalty: state.loyalty,
        helpers: state.userProp ? state.userProp.helpers : {},
        activeOrder: state.userProp ? state.userProp.activeOrder : false,
        settings: state.settings
    }
}

const mapDispatchToProps = dispatch => ({
    updateOrder: (updatedList) => dispatch(updateOrder(updatedList)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Extras)

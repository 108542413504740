import React, { Component } from "react";
import {
    calcGrandTotal,
} from '../../helpers/cartHelpres'
import { Link, Redirect } from 'react-router-dom';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';
import Btn from '../Helpers/AddItemBtn/btn';
import Extras from './extra/extra'
import { firestore } from "firebase";
import { connect } from 'react-redux';
import { persistMenu } from '../../reducers/userReducer'
import { strings } from "../../helpers/translation";
import { localizeString } from "../../helpers";

class ItemsList extends Component {
    state = {
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
    }

    getPrice(item, type) {
        let { price, advPrice } = item
        if (type && advPrice && advPrice[type]) {
            return advPrice[type]
        }
        return price
    }
    componentWillMount() {

        let direction = localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl'
        strings.setLanguage(direction === 'rtl' ? 'ar' : 'en')
        window.scrollTo({
            top: 0,
        });
        this.getMenuItems(this.props.menu)
    }
    getMenuItems(menu) {
        let direction = localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl'

        let { menuMap, selectedLocation, settings } = this.props
        let { type } = selectedLocation ? selectedLocation : false;
        let categoryUrl = new URL(window.location.href);
        var categouryId = categoryUrl.searchParams.get("id");
        let categoryMenu = window.location.href.includes('categories')
        if (!categoryMenu) {
            return
        }

        let categouryIndex = 0
        if (categouryId && menu) {
            // eslint-disable-next-line 
            let filteredItems = menu.filter((item, index) => {
                let { category } = item
                if (category.name === (decodeURIComponent(categouryId.replace('%', 'dddd')).replace('dddd', '%'))) {
                    categouryIndex = index
                    return category.name === (decodeURIComponent(categouryId.replace('%', 'dddd')).replace('dddd', '%'))
                }
            })[0];
            if (filteredItems && filteredItems.loadItems && filteredItems.loadItems.length > 0) {
                let { loadItems } = filteredItems
                delete filteredItems.loadItems
                this.setState({ loading: true }, () => {
                    loadItems.forEach((itemKey, index) => {
                        firestore().collection('resturant').doc(settings?.resKey).collection('menuItems').doc(itemKey).get().then(item => {
                            let data = item.data()
                            if (data) {
                                data.id = itemKey;
                                data.price = this.getPrice(data, type)
                                filteredItems.items.push(data)
                                menuMap[itemKey] = data
                            }
                            if (loadItems.length - 1 === (index)) {
                                menu[categouryIndex] = filteredItems;
                                this.props.persistMenu({ menu: menu, menuMap: menuMap, categoryMenu: categoryMenu });
                                this.setState({ loading: false, direction });
                            }
                        })
                    })
                })
            } else {

                this.setState({ loading: false, direction })
            }
        }
    }
    componentWillReceiveProps(prop) {
        if (!prop.selectedLocation) {
            return this.setState({ redirect: (prop?.settings?.pathName ? ('/' + prop?.settings?.pathName) : '/') })
        } else {
            this.setState({ redirect: false })
        }
        if (prop.menu !== this.props.menu) {
            this.getMenuItems(prop.menu)
        }
        if (prop.direction !== this.props.direction) {
            strings.setLanguage(prop.direction === 'rtl' ? 'ar' : 'en')
            this.setState({ direction: prop.direction })
        }
    }

    render() {
        let { redirect, extras, direction } = this.state
        if (redirect) {
            return (<Redirect to={redirect} push={true} />)
        }
        let { helpers, setRef, search, activeOrder, menu, orderList, user, selectedLocation, settings } = this.props
        let selectedCateg = window.location.href.includes('categories')

        let { percent, cap, freeItem, discountApplied, couponeCode } = helpers ? helpers : {}

        let filteredItems = JSON.parse(JSON.stringify(menu ? menu : []))
        let count = (orderList ? Object.keys(orderList) : []).map(item => {
            return orderList[item].count
        }).reduce((a, b) => a + b, 0)


        let receipt = calcGrandTotal(user, helpers, orderList, freeItem, (selectedLocation ? selectedLocation : {}), false,settings)
        if (search && search !== '') {
            var result = [];
            result = filteredItems.filter(item => {
                let { category, items } = item
                if (category.name.toLocaleLowerCase().includes(search) || category.name_ar.toLocaleLowerCase().includes(search)) {
                    return item
                } else if (items.filter(mennuItem => mennuItem.name.toLocaleLowerCase().includes(search) || (mennuItem.name_ar ? mennuItem.name_ar : '').includes(search)).length) {
                    item.items = items.filter(mennuItem => mennuItem.name.toLocaleLowerCase().includes(search) || (mennuItem.name_ar ? mennuItem.name_ar : '').includes(search))
                    return item
                } else {
                    return false
                }
            })
            filteredItems = Object.assign([], result)
        }

        let categoryUrl = new URL(window.location.href);
        var categouryId = categoryUrl.searchParams.get("id");
        if (categouryId) {
            filteredItems = filteredItems.filter(item => {
                let { category } = item
                return category.name === (decodeURIComponent(categouryId.replace('%', 'dddd')).replace('dddd', '%'))
            })

        }
        return (
            <div className='menuSection' style={{ display: 'block' }}>
                {
                    this.state.loading && <Dimmer active page>
                        <Loader active />
                    </Dimmer>
                }
                {extras && <Extras menuItem={extras} dismiss={() => this.setState({ extras: false })} />}

                <div className='listContainer' style={(!activeOrder && count !== 0) ? { top: '0', paddingBottom: '120px' } : { top: '0', paddingBottom: '0' }}>
                    {
                        filteredItems && filteredItems.length ? filteredItems.map((item, index) => {
                            let categoryItems = item.items
                            let category = item.category
                            // endOfList = filteredItems - 1 === index ? true : false
                            if (!categoryItems) {
                                return ''
                            }
                            return (
                                <div key={index} >

                                    {
                                        selectedCateg ? (<div className='header' style={{ position: 'sticky', top: '0', padding: '16px' }}>
                                            <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center', direction: 'ltr' }}>
                                                <Icon name="arrow left"
                                                    onClick={() => {
                                                        window.history.back()
                                                        // if (document.referrer) {
                                                        //     window.history.back()
                                                        // } else {
                                                        //     this.setState({redirect:(window.pathName ? ('/' + window.pathName) : '/')})
                                                        // }

                                                    }} className='back'

                                                ></Icon>
                                                <div className='titleBar'>{localizeString(category, 'name')}</div>
                                                <div className='back'></div>


                                            </div>
                                        </div>)
                                            :

                                            (<div className='title' id={category.name} ref={(el) => setRef(category.name, el)}>
                                                {
                                                    localizeString(category, 'name')
                                                }
                                            </div>)

                                    }


                                    <div className='list' id={category.name + 'list'}>
                                        {
                                            categoryItems.map((menuItem, inx) => {
                                                let excluded = menuItem && menuItem.excluded && menuItem.excluded[couponeCode]
                                                return (
                                                    <div key={inx} onClick={(e) => {
                                                        if (e.target.className === 'increase' || e.target.className === 'decrease') {
                                                            return
                                                        }
                                                        this.setState({ redirect: (settings?.pathName ? ('/' + settings?.pathName) : '') + '/menu?itemId=' + menuItem.id })
                                                    }}>
                                                        <div className='listItem' style={(inx === categoryItems.length - 1) ? { border: 'none' } : {}}>


                                                            <div className='itemInfo' style={{ padding: '0 8px 0 0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: menuItem.pictures ? "65%" : "100%" }}>
                                                                <div className='itemTitle' >
                                                                    <div style={{ display: 'flex', alignItems: 'center', color: '#4a4a4a' }}>
                                                                        {
                                                                            localizeString(menuItem, 'name')
                                                                        }
                                                                    </div>
                                                                    {
                                                                        menuItem.detail && <div className='itemDetails' style={{ WebkitBoxOrient: 'vertical' }}>{


                                                                            (localizeString(menuItem, 'detail') > 60) ? localizeString(menuItem, 'detail').substring(0, 60) + ' ...' : localizeString(menuItem, 'detail')
                                                                        }</div>
                                                                    }

                                                                    <div className='itemPrice'>
                                                                        {
                                                                            (menuItem.price !== "0") && discountApplied && !excluded && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: direction === 'rtl' ? '0 0 0 8px' : '0 8px 0 0', color: '#adadad' }}>{menuItem.price + strings[settings?.currency] + ' '}</span>
                                                                        }
                                                                        {
                                                                            (menuItem.price === "0") ? strings.priceOnSelection
                                                                                : ((discountApplied && !excluded) ? ((menuItem.price * discountApplied).toFixed(2) + strings[settings?.currency]) : (menuItem.price + strings[settings?.currency]))
                                                                        }

                                                                    </div>

                                                                </div>
                                                                {

                                                                    <div className='btnHolder' style={{ display: 'flex', alignItems: 'flex-end' }} >
                                                                        <Btn disabled={selectedLocation && !selectedLocation.location} menuItem={menuItem} direction showExtras={(extras) => {
                                                                            if (extras && extras.removeItem) {
                                                                                this.setState({ extras: extras })
                                                                            } else {
                                                                                this.setState({ redirect: (settings?.pathName ? ('/' + settings?.pathName) : '') + '/menu?itemId=' + menuItem.id })
                                                                            }
                                                                        }
                                                                        } />
                                                                    </div>
                                                                }

                                                            </div>

                                                            {
                                                                menuItem.pictures &&
                                                                <div style={{ display: 'flex', alignItems: 'center' }} >
                                                                    <div style={{ width: '110px', height: '115px' }}>
                                                                        {
                                                                            <img loading="lazy" alt='imageView' className='imageView' src={menuItem.pictures[0]} />

                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className='separator'></div>
                                </div>
                            )
                        })
                            : ''
                    }
                    {
                        activeOrder && <div className='activeOrderContainer' >
                            <Link className='activeOrder' to={settings?.pathName ? ('/' + settings?.pathName + '/order-status') : '/order-status'}>
                                <Icon name='stop circle outline'></Icon>
                                <div className='text'>{strings.youHave}<span>{strings.active}</span> {strings.order}</div>
                            </Link>
                        </div>

                    }
                    {
                        !activeOrder && count !== 0 && <div id='activeOrderContainer' className='activeOrderContainer'>
                            <Link to={settings?.pathName ? ("/" + settings?.pathName + '/cart') : '/cart'} className='CheckOut'>
                                <div className='addtobasket'>{strings.showCart + " (" + count + "x)"}</div>
                                <div className='totalprice'>{receipt.cartTotal + strings[settings?.currency]}</div>
                            </Link>
                        </div>

                    }


                </div>

            </div>



        )
    }

}
function mapStateToProps(state) {
    return {
        menu: state.userProp ? state.userProp.menu : [],
        menuMap: state.userProp ? state.userProp.menuMap : {},
        categoryMenu: state.userProp ? state.userProp.categoryMenu : false,
        helpers: state.userProp ? state.userProp.helpers : {},
        selectedLocation: state.userProp ? state.userProp.location : {},
        activeOrder: state.userProp ? state.userProp.activeOrder : false,
        user: state.userProp ? state.userProp.user : {},
        orderList: state.orderList,
        settings: state.settings

    }
}
const mapDispatchToProps = dispatch => ({
    persistMenu: (menu) => dispatch(persistMenu(menu)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);

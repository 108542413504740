import { createAction } from 'redux-action';
export const NEW_ORDER = 'NEW_ORDER';

export const FLUSH = 'FLUSH';

export const updateOrder = createAction(NEW_ORDER, (order) => {
  return order
})

export const flushOrders = createAction(FLUSH, () => {
  return {}
})
const initialState = {
  // newOrder: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_ORDER:
      return {
        ...state,
        // orderList: action.payload,
      };
    case FLUSH:
      return {}
    default:
      return state;
  }
};
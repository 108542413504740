import { strings } from "./translation"

export function getPrice(item, type) {
    let { price, advPrice } = item
    if (type && advPrice && advPrice[type]) {
        return advPrice[type]
    }
    return price
}

export function localizeString(object, field) {
    let direction = localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl';

    if (object) {
        if (direction === 'rtl' && object[field + '_ar']) {
            return object[field + '_ar']
        } else {
            return object[field]
        }
    }
    return ''

}
import React, { useMemo, useState, useEffect } from "react";
import {
  useStripe,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
const useOptions = paymentRequest => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "48px",
          type: 'book'
        }
      }
    }),
    [paymentRequest]
  );

  return options;
};

const usePaymentRequest = ({ options, onPaymentMethod }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then(res => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

const PaymentRequestForm = ({ receipt, walletPayNow }) => {
  const { resName } = useSelector(state => state.settings)
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!ready) {
        setReady(true)
      }

    }, 3000);
  }, [])

  const paymentRequest = usePaymentRequest({
    options: {
      country: "AE",
      currency: "aed",
      total: {
        label: resName,
        amount: Number((receipt?.total * 100).toFixed(2)),
      }
    },
    onPaymentMethod: async ({ complete, paymentMethod }) => {
      await walletPayNow(paymentMethod.id)
      return complete('success');
    }
  });
  const options = useOptions(paymentRequest);

  if (!paymentRequest) {
    return <div style={{ padding: '16px' }}>
      <Button fluid style={{ height: '50px' }} isLoading={!ready} isLoadingText=' ' disabled={true} minH={50} borderRadius={8} backgroundColor='gray.600'>Not Activated</Button>
    </div>
  }

  return (
    <div style={{ padding: '16px', minHeight: '50px' }}>
      <PaymentRequestButtonElement
        className="PaymentRequestButton"
        options={options}
        onReady={() => {
          setReady(true)
        }}
      />
    </div>

  );
};

export default PaymentRequestForm;

import React, { Component } from 'react';
import './menu.css';
import 'semantic-ui-css/semantic.min.css';
import { Input, Button, Loader, Dimmer, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { updateOrder } from '../../reducers/newOrder'
import { sendNotif } from '../../reducers/userReducer'

import Header from '../Header/index'
import {
    calcGrandTotal,
} from '../../helpers/cartHelpres'
import LocalizedStrings from 'react-localization';
import { Helmet } from 'react-helmet';

import SelectLocation from './selectLocation/selectLocation'
import { Link } from 'react-router-dom';
import Banner from './offlineBanner/offlineBanner'
import ScrollContainer from 'react-indiana-drag-scroll'
import LoyaltyTrms from './loyalty/loyalty.js';
import OfferDetails from './offerDetails/offerDetails.js';
import GetApp from './getApp/index.js';
import NewLogin from '../Login/newLogin';
import CategoriesPage from './categories/categories.js'
import ItemsList from './itemsList.js'
import moment from 'moment';
import "@lottiefiles/lottie-player";
import GridLayout from '../Helpers/GridMenu';

const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};
class Menu extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            menuItems: [],
            menu: [],
            loading: false,
            direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
            selectLocation: false,
            showVairal: true,
            showGetApp: true,
            signupGift: false
        }
    }
    strings = new LocalizedStrings({
        en: {
            menu: "Menu",
            yoyrcart: 'Your Cart',
            subtotal: 'Sub Total',
            vat: 'VAT',
            discount: 'Discount',
            total: "Total",
            delivery: "Delivery charge",
            applyCoupone: "Apply coupon",
            cartEmpty: "Your cart is empty",
            addItemsFromLeft: "Add items from the menu on the left to place order",
            promoCode: "promo code",
            placeOrder: "PLACE ORDER",
            freeItem: "Free Item",
            cashOnDelivery: "Cash on delivery",
            search: "Search for food 😋",
            login: "Login",
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",
            hi: "Hi, ",
            youHave: "You have an ",
            active: "Active",
            order: 'order',
            priceOnSelection: "Price On Selection",
            showCart: "Show Cart",
            minOrder: "min order ",
            deliveryTime: "Delivery time",
            min: " min(s)",
            hour: " hour(s)",
            day: " day(s)",
            reorder: 'Re-order',
            more: 'more..'

        },
        ar: {
            menu: "قائمة الطعام",
            yoyrcart: 'قائمة الطلبات',
            subtotal: 'مبلغ إجمالي',
            vat: 'ضريبة',
            discount: 'خصم',
            total: "إجمالي",
            delivery: "رسوم التوصيل",
            applyCoupone: "تطبيق القسيمة",
            cartEmpty: "قائمة التسوق خالية",
            addItemsFromLeft: "أضف الطلبات من القائمة الموجودة على اليمين لوضع الطلب",
            promoCode: "الرمز الترويجي",
            placeOrder: "تأكيد الطلب",
            freeItem: "مجاني",
            cashOnDelivery: "الدفع عن الاستلام",
            search: "بحث عن الطعام 😋",
            login: "تسجيل الدخول",
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",
            hi: "مرحبا ،",
            youHave: "لديك طلب ",
            active: "جاري",
            order: '',
            priceOnSelection: "السعر عند الاختيار",
            showCart: "عرض السلة",
            minOrder: "الحد الادنى ",
            deliveryTime: "التوصيل ",
            min: " دقيقة",
            hour: " ساعة",
            day: " يوم",
            reorder: 'اعادة الطلب',
            more: 'مزيد..'


        }
    });
    componentWillMount() {
        let { direction } = this.state
        // let { dineIn} = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
        // var url = new URL(window.location.href);
        // var branch = url.searchParams.get("branch");
        // let path = url.pathname.split('/')
        // if (dineIn) {
        //     this.setState({ selectLocation: true })
        // }
    }
    componentWillReceiveProps(prop) {
        let { loading } = this.state
        if (prop.menu && prop.menu.length && loading) {
            // setTimeout(() => {
            this.setState({ loading: false }, () => {
                // window.scrollTo({
                //     top: window.scrollY,
                // });
            })
            // }, 100)
        }
    }

    isBottom(el) {
        if (el && el.length > 0) {
            return (el[0].getBoundingClientRect().bottom <= window.innerHeight) && (el[0].getBoundingClientRect().height >= window.innerHeight);
        }
    }

    componentDidMount() {
        this.showBanner(this.props)
        document.addEventListener('scroll', this.trackScrolling);

    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }
    trackScrolling = () => {
        // const wrappedElement = document.getElementsByClassName('listContainer');
        let { menu } = this.props;

        (menu ? menu : []).map(item => {
            let category = item.category
            let element = document.getElementById(category.name + 'list')
            let appearedTag = document.getElementById(category.name + 'tag')
            if (element && appearedTag) {
                const { offsetBottom, offsetTop } = getDimensions(element);
                let appeared = (window.scrollY > offsetTop) && (window.scrollY < (offsetBottom + 70));
                if (appeared) {
                    let scrollContainer = this.scrollContainerRef.getElement()
                    if (scrollContainer) {
                        scrollContainer.scrollLeft = appearedTag.offsetLeft - 50;
                        appearedTag.classList.add('active');

                    }
                } else {
                    appearedTag.classList.remove('active');
                }
            }
            return false
        })
        // let activeOrderContainer = document.getElementById('activeOrderContainer')
        // if (activeOrderContainer) {
        //     if (this.isBottom(wrappedElement)) {
        //         document.getElementById('activeOrderContainer').classList.add('hidden')
        //     } else {
        //         document.getElementById('activeOrderContainer').classList.remove('hidden')
        //     }
        // }

    };
    componentWillReceiveProps(prop) {
        this.showBanner(prop)
    }
    showBanner(prop) {
        let { selectedLocation, timings, settings } = prop || {}
        let type = selectedLocation && selectedLocation.type ? (selectedLocation.type === "delivery" ? "deliveryTimings" : selectedLocation.type === "dineIn" ? "dineinTimings" : selectedLocation.type === "pickup" ? "pickupTimings" : '') : ''
        let selectedTimings = timings && timings[type]
        let today = moment(new Date()).format("dddd")

        let closed = settings?.closedOn;
        if (selectedTimings && Object.keys(selectedTimings).length) {
            let nowOptimized = moment('1955-01-05T' + moment().format('HH:mm'))
            let nowTime = nowOptimized.toDate().getTime()


            if (selectedTimings[today].isOpen) {
                let count2 = 0
                selectedTimings[today].timings.every(time => {
                    let startTime = moment(time.startTime)
                        .set('year', moment(nowOptimized).year())
                        .set('month', moment(nowOptimized).month())
                        .set('date', moment(nowOptimized).date());
                    let endTime = moment(time.endTime)
                        .set('year', moment(nowOptimized).year())
                        .set('month', moment(nowOptimized).month())
                        .set('date', moment(time.endTime).hours() === 0 ? moment(nowOptimized).date() + 1 : moment(nowOptimized).date());

                    if ((moment(nowTime).isSameOrAfter(startTime)) && moment(nowTime).isSameOrBefore(endTime)) {
                        this.setState({ showOfflineBanner: false })
                        return false
                    }
                    count2 += 1
                    return true
                })

                if (count2 === selectedTimings[today].timings.length) {
                    this.setState({ showOfflineBanner: true })
                }
            } else {
                this.setState({ showOfflineBanner: true })
            }
        } else {
            if (closed) {
                let nowOptimized = moment('1955-01-05T' + moment().format('HH:mm'))
                let nowTime = nowOptimized.toDate().getTime()
                let from = new Date('1955', '0', '05', closed.from, closed.minFrom).getTime()
                let to = new Date('1955', '0', '05', closed.to, closed.minTo).getTime()
                if (closed.from > closed.to ? (to > nowTime) || (nowTime > from) : (to > nowTime) && (nowTime > from)) {
                    setTimeout(() => {
                        this.setState({ showOfflineBanner: true })
                    }, 500);
                } else {
                    this.setState({ showOfflineBanner: false })
                }
            }
        }
    }

    render() {
        let { selectedLocation, loyalty, user, timings } = this.props
        let { direction, showOfflineBanner, showImage, selectLocation, showLoyalty, showOffer, showVairal, showGetApp } = this.state
        return (
            <div id="menuSection" className='menuSection' style={{ direction: direction }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.strings.menu}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>

                {
                    (showOfflineBanner) && <Banner timings={timings} hide={() => this.setState({ showOfflineBanner: false })} />
                }
                {
                    (!showOfflineBanner && selectedLocation && selectedLocation.active && !selectedLocation.active.active) && <Banner timings={timings} active={selectedLocation.active} hide={() => this.setState({ showOfflineBanner: false })} />
                }
                {
                    (selectLocation || !selectedLocation) && <SelectLocation
                        direction={direction}
                        dismiss={() => this.setState({ selectLocation: false }, () => {
                            window.scrollTo({
                                top: 0,
                            });
                        })}
                        updateLang={(direction) => {
                            this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
                            this.setState({ direction, extras: false }, () => {
                                window.scrollTo({
                                    top: 0,
                                });
                            })
                        }} />
                }
                {
                    showLoyalty && <LoyaltyTrms user={user} direction={direction} loyalty={loyalty} dismiss={() => this.setState({ showLoyalty: false, showVairal: false })} login={() => this.setState({ login: true })} sendNotif={(msg) => this.props.sendNotif(msg)} />
                }
                {
                    showOffer && <OfferDetails direction={direction} offer={showOffer} dismiss={() => this.setState({ showOffer: false, showVairal: false })} />
                }
                {
                    showGetApp && loyalty?.activated && !user && !user?.info && !user?.info?.loyalty && selectedLocation?.type === "dineIn" && <GetApp user={user} direction={direction} loyalty={loyalty} dismiss={() => this.setState({ showGetApp: false, showVairal: false })} login={() => this.setState({ login: true, signupGift: true })} />
                }

                {showImage && <Dimmer active={showImage} page onClickOutside={() => this.setState({ showImage: false })}>
                    <img alt='menuImg' src={showImage} style={{ width: '90%', borderRadius: '4px' }} ></img>
                </Dimmer>}

                {this.renderLeftSide()}

            </div>
        )
    }

    handleCategorySelection(category, offset) {
        let element = this[category]
        window.scrollTo({
            top: element.offsetTop + offset,
            left: 0,
            behavior: 'auto'
        });
    }
    renderLeftSide() {

        let { direction, searchActive, loading, login, signupGift } = this.state
        let { selectedLocation, menu, user, loyalty, orderList, categoryMenu, helpers, activeOrder, recentOrders, offers, settings } = this.props
        let { freeItem } = helpers ? helpers : {}
        let url = (window.location.pathname).split('/')
        let path = url[1]
        let strings = this.strings
        let count = (orderList ? Object.keys(orderList) : []).map(item => {
            return orderList[item].count
        }).reduce((a, b) => a + b, 0)

        let receipt = calcGrandTotal(user, helpers, orderList, freeItem, (selectedLocation ? selectedLocation : {}), false, settings)
        return (
            <div style={{ width: '100%' }}>
                {
                    login ? <NewLogin
                        referal={{ id: loyalty?.referal?.id, name: loyalty?.referal?.name }}
                        signupGift={signupGift}
                        dismiss={() => this.setState({ login: false })}
                        success={() => this.setState({ login: false })}
                    />
                        : ''
                }

                <Header
                    updateLang={(direction) => {
                        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
                        this.setState({ direction, extras: false }, () => {
                            window.scrollTo({
                                top: 0,
                            });
                        })
                    }}
                    showLoyalty={() => this.setState({ showLoyalty: true })}
                    showOffer={(e) => this.setState({ showOffer: e })}
                    user={user}
                    login={() => {
                        this.setState({ login: true })
                    }
                    }
                    resLoyalty={loyalty}
                    direction={direction}
                    hideCart={activeOrder}
                    location={selectedLocation}
                    showSelectLocation={() => this.setState({ selectLocation: true })}
                    offers={offers ? offers?.filter(r => r.global) : false} />
                {
                    (selectedLocation && loading) &&
                    <Dimmer active page >
                        <Loader active />
                    </Dimmer>

                }
                {
                    recentOrders ? <div className='reorder'>
                        <div className='title'>{strings.reorder}</div>
                        <ScrollContainer className='tagsView' style={{ display: 'flex' }}>
                            {
                                recentOrders ?
                                    recentOrders.map(item => {
                                        let { receipt, items } = item
                                        return (
                                            <div key={item.id} className='card'>
                                                <div className='cardTitle'>{
                                                    items.slice(0, 1).map(rec => {
                                                        return (rec.count + "x " + (direction === 'rtl' ? rec.name_ar : rec.name))
                                                    })
                                                }<span>{items.length > 1 ? (" + " + (items.length - 1).toString() + ' ' + strings.more) : ''}</span>
                                                </div>
                                                <div style={{ display: 'flex', padding: '12px', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className='price'> {receipt.total}{" "}{strings[receipt.currency]}</div>
                                                    <div className='reorderBtn'
                                                        onClick={() => {
                                                            items.forEach(record => {
                                                                let post = record
                                                                post.selectedExtras = record.extra
                                                                orderList[record.id] = { ...post, count: 1 }
                                                            })
                                                            this.props.updateOrder(orderList)
                                                        }}>
                                                        {strings.reorder}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                            }

                        </ScrollContainer>
                    </div>
                        : ''
                }

                {
                    menu && menu.length > 0 ?

                        settings?.layout === 'categories' ?
                            <div className='listContainer' style={(!activeOrder && count !== 0) ? { top: '0', marginBottom: '120px' } : { top: '0', marginBottom: '0' }}>
                                <CategoriesPage menu={menu} direction={direction} path={settings.pathName} />
                                {
                                    activeOrder && <div className='activeOrderContainer'>
                                        <Link className='activeOrder' to={path ? ('/' + path + '/order-status') : '/order-status'}>
                                            <Icon name='stop circle outline'></Icon>
                                            <div className='text'>{strings.youHave}<span>{strings.active}</span> {strings.order}</div>
                                        </Link>
                                    </div>

                                }

                                {
                                    !activeOrder && count !== 0 && <div id='activeOrderContainer' className='activeOrderContainer'>
                                        <Link to={path ? ("/" + path + '/cart') : '/cart'} className='CheckOut'>
                                            <div className='addtobasket'>{strings.showCart + " (" + count + "x)"}</div>
                                            <div className='totalprice'>{receipt.cartTotal + strings[settings?.currency]}</div>
                                        </Link>
                                    </div>
                                }

                            </div>
                            : settings?.layout === 'grid' ?
                                <div className='listContainer'>
                                    <GridLayout />
                                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}> */}
                                    {
                                        activeOrder && <div className='activeOrderContainer'>
                                            <Link className='activeOrder' to={path ? ('/' + path + '/order-status') : '/order-status'}>
                                                <Icon name='stop circle outline'></Icon>
                                                <div className='text'>{strings.youHave}<span>{strings.active}</span> {strings.order}</div>
                                            </Link>
                                        </div>

                                    }
                                    {
                                        !activeOrder && count !== 0 && <div id='activeOrderContainer' className='activeOrderContainer'>
                                            <Link to={path ? ("/" + path + '/cart') : '/cart'} className='CheckOut'>
                                                <div className='addtobasket'>{strings.showCart + " (" + count + "x)"}</div>
                                                <div className='totalprice'>{receipt.cartTotal + strings[settings?.currency]}</div>
                                            </Link>
                                        </div>
                                    }
                                    {/* </div> */}


                                </div>

                                :
                                <>
                                    <div className='tagsContainer' style={{ direction: 'ltr' }}>
                                        <Button className='searchIcon' style={{ background: searchActive ? 'var(--themeColor)' : 'white', color: searchActive ? 'white' : 'var(--themeColor)', margin: '0 8px', fontSize: '14px' }} icon={searchActive ? 'cancel' : 'search'} onClick={() => this.setState({ searchActive: !searchActive, search: false })}></Button>
                                        {
                                            searchActive ?
                                                <Input className='searchBar' placeholder={this.strings.search} onChange={(e, { value }) => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0; this.setState({ search: value.toLocaleLowerCase() }) }} />
                                                :
                                                <ScrollContainer id='scrollContainerid' hideScrollbars={true} className='tagsView' ref={(ref) => this.scrollContainerRef = ref}>
                                                    {
                                                        menu.map((item, index) => {
                                                            let { category } = item

                                                            return (
                                                                <div key={index} id={category.name + 'tag'} className='tag' onClick={() => this.handleCategorySelection(category.name, ((selectedLocation && (selectedLocation.deliveryTime || selectedLocation.minOrder)) ? (loyalty ? 225 : 175) : 144))}>
                                                                    {
                                                                        direction === 'rtl' ? category.name_ar : category.name
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </ScrollContainer>
                                        }
                                    </div>

                                    <ItemsList
                                        // strings={this.strings}
                                        // helpers={this.props.helpers}
                                        direction={direction}
                                        // selectedCateg={showCategories && showCategories.cat.name}
                                        // props={this.props}
                                        setRef={(key, el) => this[key] = el}
                                        search={this.state.search}
                                    />
                                    <div style={{ textAlign: 'center', color: '#9c9c9c', fontSize: '12px', padding: '12px', fontWeight: 'bold' }}><span role="img" aria-label="">⚡️</span>{process.env.REACT_APP_POWERD}</div>

                                </>

                        : ''

                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        orderList: state.orderList,
        user: state.userProp ? state.userProp.user : {},
        selectedLocation: state.userProp ? state.userProp.location : {},
        timings: state.userProp ? state.userProp.timings : {},
        menu: state.userProp ? state.userProp.menu : {},
        categoryMenu: state.userProp ? state.userProp.categoryMenu : false,
        loyalty: state.loyalty,
        activeOrder: state.userProp ? state.userProp.activeOrder : false,
        helpers: state.userProp ? state.userProp.helpers : {},
        recentOrders: state.recentOrders && state.recentOrders.length ? state.recentOrders : false,
        offers: state.offers && state.offers.length ? state.offers : false,
        settings: state.settings
    }
}

const mapDispatchToProps = dispatch => ({
    updateOrder: (updatedList) => dispatch(updateOrder(updatedList)),
    sendNotif: (notif) => dispatch(sendNotif(notif)),


});
export default connect(mapStateToProps, mapDispatchToProps)(Menu)
import React from 'react';
import './index.css'
import { connect } from 'react-redux';
import { sendNotif } from '../../reducers/userReducer'
import { firestore } from '../../fire';
import { Message } from 'semantic-ui-react'
async function dismiss(prop) {
    if (prop.notif?.remove) {
        await firestore().collection('resturant').doc(prop.notif?.key).collection('loyalty').doc(prop.notif?.userUID).update({
            'notif.seen': true
        })
    }
    setTimeout(() => {
        prop.sendNotif(false)

    }, 6000);

}
const Index = (props) => {
    dismiss(props)
    return (
        <div className='notif'>
            <Message style={{ backgroundColor: 'var(--themeColorOpacity)', display: 'flex', justifyContent: 'center' ,borderRadius:'8px'}} content={props.notif?.msg} />

            {/* <div className='notif_title'>{props.notif?.title}</div>
            <div> {props.notif?.msg}</div> */}

        </div>
    );
};
const mapDispatchToProps = dispatch => ({
    sendNotif: (notif) => dispatch(sendNotif(notif))
});

export default connect(null, mapDispatchToProps)(Index);
import React, { Component } from 'react';
import '../../Login/login.css'
import './extra.css'

import { Dimmer, Checkbox, Icon, Button, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux';
import { updateOrder } from '../../../reducers/newOrder'
import LocalizedStrings from 'react-localization';
import { firestore } from 'firebase'

class Extras extends Component {
    state = {
        loading: true,
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
        optimizedItems: {},
        checked: {},
        count: 1
    }
    strings = new LocalizedStrings({
        en: {
            specialNotes: "Special Notes",
            addToCart: "Add to Basket",
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",
            required: " Required ",
            chooseAny: "Choose Any ",
            items: " Item(s)",
            remove: "Remove",
            dismiss: 'Dismiss'
        },
        ar: {
            specialNotes: "ملاحظات خاصة",
            addToCart: "اضف إلى الطلبات",
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",
            required: " مطلوب ",
            chooseAny: "إختر ",
            items: "",
            remove: "حذف",
            dismiss: 'تم'


        }
    });
    componentWillMount() {
        const { settings } = this.props
        document.body.style.overflow = "hidden";
        let { menuItem, orderList } = this.props
        this.strings.setLanguage(this.state.direction === 'rtl' ? 'ar' : 'en');
        if (menuItem.removeItem) {
            let items = orderList[menuItem.id]
            // console.log("itemsitems", items)
            // let { selectedExtras } = items
            // let newFiltes = []
            // selectedExtras.forEach(element => {
            //     newFiltes.push(selectedExtras.filter(item => item === element))
            // });
            // console.log("newFiltes", newFiltes)
            return this.setState({ remove: items, loading: false })
        }
        let extras = menuItem ? menuItem.modifierList : [];
        let all = []
        let allModifiersData = {}
        extras.map((extraCategory) => {
            let modifierObject = {}
            modifierObject.name = extraCategory.name
            modifierObject.name_ar = extraCategory.name_ar
            modifierObject.count = extraCategory.count
            modifierObject.items = []
            let modifiers = extraCategory.items

            let promise = new Promise((resolve, reject) => {
                modifiers.map((modifierKey, innerIndex) => {
                    firestore().collection('resturant').doc(settings?.resKey).collection('menuItems').doc(modifierKey).get().then(result => {
                        if (result.exists) {
                            let value = result.data()
                            value.id = modifierKey
                            modifierObject.items.push(value)
                            allModifiersData[value.id] = value
                        }
                        if (modifiers.length === innerIndex + 1) {
                            resolve(modifierObject)
                        }
                    })
                    return false
                })
            })
            return all.push(promise)
        })
        Promise.all(all).then(result => {
            let optimizedItems = []
            result.map(item => {
                return optimizedItems.push(item)
            })
            this.setState({ optimizedItems, count: 1, allModifiersData, price: Number(menuItem.price), loading: false })
        })
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
    }
    render() {
        let { loading } = this.state

        // window.alert(window.innerWidth)
        return (
            <Dimmer active className='extrasClass' page>
                {
                    loading ?
                        <Loader active />
                        :
                        this.renderStep()
                }
                {/* {
                    this.renderStep()
                } */}
            </Dimmer>
        )
    }
    submit() {
        let { menuItem, orderList } = this.props
        let { optimizedItems, checked, allModifiersData, price } = this.state
        let count = (orderList[menuItem.id] && orderList[menuItem.id].count) ? orderList[menuItem.id].count : 0;
        count = count + this.state.count;
        menuItem.count = count;
        let selectedExtras = {};

        // this.setState({ loading: true })

        optimizedItems.map(item => {
            let value = checked[item.name]
            if (value) {
                let selectedModifiers = value.map(modifierKey => {
                    return allModifiersData[modifierKey]
                })
                selectedExtras[item.name] = selectedModifiers
            }
            return selectedExtras
        })
        menuItem.modifiedPrice = (menuItem.modifiedPrice ? menuItem.modifiedPrice : 0) + (price * this.state.count)
        let prevExtras = orderList && orderList[menuItem.id] && orderList[menuItem.id].selectedExtras;
        if (prevExtras) {
            let counter = this.state.count
            for (var i = count - 1; i >= (count - counter); i--) {
                prevExtras[i] = selectedExtras
            }
            menuItem.selectedExtras = prevExtras
        } else {
            let counter = this.state.count
            let extrasArray = []
            for (var e = count - 1; e >= (count - counter); e--) {
                extrasArray[e] = selectedExtras
            }
            menuItem.selectedExtras = extrasArray
        }
        orderList[menuItem.id] = menuItem;

        this.props.updateOrder(orderList);
        if (window.location.href.includes("home")) {
            return this.props.redirect()
        }
        this.props.dismiss()

    }
    enableAdd() {
        let { optimizedItems, checked } = this.state
        if (optimizedItems && optimizedItems.length) {
            let allowAdd = optimizedItems.map(item => {
                let { count } = item
                if (Number(count) === 0) {
                    return true
                }
                if (checked && checked[item.name] && checked[item.name].length >= Number(count)) {
                    return true
                }
                return false
            })
            if (allowAdd.includes(false)) {
                return true
            } else {
                return false
            }
        }
        return true
    }
    addExtra(item, extraItem) {

        let { checked, price, allModifiersData } = this.state
        let { count, name } = item
        let category = checked[name] ? checked[name] : [];

        if (category.includes(extraItem.id)) {
            price = Number(price) - Number(extraItem.price ? extraItem.price : 0)
            category = category.filter(item => item !== extraItem.id)

        } else {
            if (category && (Number(count) !== 0) && category.length >= Number(count)) {

                let lastId = category[category.length - 1];
                price = Number(price) - Number(allModifiersData[lastId].price ? allModifiersData[lastId].price : 0);
                category.pop()
                category.push(extraItem.id)
                price = Number(price) + Number(extraItem.price ? extraItem.price : 0)
            } else {
                category.push(extraItem.id)
                price = Number(price) + Number(extraItem.price ? extraItem.price : 0)
            }
        }
        checked[name] = category
        this.setState({ checked, price: price.toFixed(2) })
    }
    count(type) {
        let { count, price } = this.state
        if (type) {
            // price = (Number(price) / count) * (count+1)
            count += 1

        } else {
            // price = count === 1 ? price : Number(price) / (count * (count-1))
            count = count === 1 ? count : count -= 1
        }
        this.setState({ count, price })

    }
    removeItem(index) {
        let { orderList } = this.props
        let { remove } = this.state
        let { selectedExtras } = remove
        selectedExtras.splice(index, 1)

        let newObject = Object.assign({}, remove)
        newObject.count--
        remove.count--
        delete newObject.removeItem
        orderList[newObject.id] = newObject;
        if (newObject.count === 0) {
            delete orderList[newObject.id]
            this.props.dismiss()
        }
        this.props.updateOrder(orderList);
        this.setState({ remove })
    }
    getCategouryName(name, direction) {
        let { remove } = this.state
        if (direction === 'rtl') {
            let { modifierList } = remove
            let categoury = modifierList.filter(item => item.name === name)
            if (categoury && categoury.length > 0) {
                return categoury[0].name_ar
            }
        }
        return name
    }
    renderStep() {
        let { error, loading, optimizedItems, direction, checked, price, remove } = this.state
        let { discountApplied, helpers, settings } = this.props
        let strings = this.strings

        return (
            <div className='popContainer' >
                <div className='titleContainers'>
                    <Icon name='times' className='iconTimes' style={{ top: '0px' }} onClick={() => this.props.dismiss()} />
                    <div className='title'>{strings.specialNotes}</div>
                    <div className='underline'></div>
                </div>

                {
                    remove ?
                        <div style={{ height: '72%' }}>
                            <div className='inputsContainer'>
                                {
                                    remove.selectedExtras.map((item, index) => {
                                        return (
                                            <div key={index} className='deleteItemsContainer'>
                                                <div className='textContainer'>
                                                    <div className='upperText'>{direction === 'rtl' ? remove.name_ar : remove.name}</div>
                                                    <div style={{ padding: '8px 0' }}>
                                                        {
                                                            Object.keys(item).map((categury, index) => {
                                                                return (
                                                                    <div key={index} className='lowerText'>{
                                                                        this.getCategouryName(categury, direction) + ": " +
                                                                        [item[categury].map(items => direction === 'rtl' ? items.name_ar : items.name)].join(" - ")
                                                                    }</div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className={'btnContainerInv'} onClick={() => this.removeItem(index)}>
                                                    <div style={{ color: 'var(--themeColor)' }} >{strings.remove}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='submitbuttoncontainer' >
                                <Button className='submitbutton' onClick={() => this.props.dismiss()}>
                                    {strings.dismiss}
                                </Button>
                            </div>
                        </div>
                        :
                        <div style={{ height: '72%' }}>
                            <div className='inputsContainer'>
                                {
                                    optimizedItems && optimizedItems.length && optimizedItems.map((item, index) => {
                                        let { name, name_ar, items, count } = item
                                        let checkedItems = checked[item.name];

                                        return (
                                            <div key={index} className='mainoption'>
                                                <div className='title' >{direction === 'rtl' ? name_ar : name}</div>
                                                {
                                                    Number(count) !== 0 &&
                                                    <div className='subtitle' >{strings.chooseAny + count + strings.items}<span>{strings.required}</span></div>

                                                }
                                                <div className='checkitem'>
                                                    {
                                                        items.map(extraItem => {
                                                            let excluded = extraItem?.excluded && extraItem.excluded[helpers?.couponeCode]

                                                            return <div key={extraItem.id} style={{ padding: "14px 0", display: 'flex', justifyContent: 'space-between' }} className={direction === 'rtl' ? 'floatright' : ''}>
                                                                <Checkbox style={{ textAlign: 'initial', maxWidth: '65%' }} checked={checkedItems && checkedItems.includes(extraItem.id)} label={direction === 'rtl' ? extraItem.name_ar : extraItem.name} radio type='checkbox' onClick={() => this.addExtra(item, extraItem)} />
                                                                {

                                                                    (extraItem.price && extraItem.price !== '0') &&
                                                                    <div
                                                                        className='price'
                                                                        style={(checkedItems && checkedItems.includes(extraItem.id)) ? { color: 'var(--themeColor)' } : { color: 'rgba(74, 74, 74, 1)' }}>
                                                                        {
                                                                            discountApplied && !excluded && <span style={{ fontSize: '11px', textDecoration: 'line-through', padding: '0 0 0 8px', color: '#adadad' }}>{extraItem.price + strings[settings?.currency] + ' '}</span>
                                                                        }
                                                                        {
                                                                            (discountApplied ? ((extraItem.price * discountApplied).toFixed(2) + strings[settings?.currency]) : (extraItem.price + strings[settings?.currency]))
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    error ?
                                        <div className='error'>{error}</div>
                                        : ''
                                }
                                <div className='qtybuttoncontaitner'>
                                    <div className='qtybutton'>
                                        <div className='remove' onClick={() => this.count(false)}>-</div>
                                        <div className='text'>{"Qty: " + this.state.count}</div>
                                        <div className='add' onClick={() => this.count(true)}>+</div>
                                    </div>
                                </div>

                            </div>
                            <div className='submitbuttoncontainer'>
                                <Button loading={loading} disabled={this.enableAdd()} className='submitbutton' onClick={() => this.submit()}>
                                    <div className='addtobasket'>{strings.addToCart}</div>
                                    <div className='totalprice'>{(discountApplied ? (discountApplied * price * this.state.count).toFixed(2) : (price * this.state.count).toFixed(2)) + strings[settings?.currency]}</div>
                                </Button>
                            </div>
                        </div>
                }


            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        orderList: state.orderList,
        helpers: state.userProp ? state.userProp.helpers : {},
        settings: state.settings
    }
}

const mapDispatchToProps = dispatch => ({
    updateOrder: (updatedList) => dispatch(updateOrder(updatedList)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Extras)

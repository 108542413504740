import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import "firebase/performance";


// var firebaseConfig = {
//     apiKey: "AIzaSyCnL8ZbanUNt3edWy52ACb75XIzWG1DXes",
//     authDomain: "cairo-gourmet-dev.firebaseapp.com",
//     databaseURL: "https://cairo-gourmet.firebaseio.com",
//     projectId: "cairo-gourmet",
//     storageBucket: "cairo-gourmet.appspot.com",
//     messagingSenderId: "831057236631",
//     appId: "1:831057236631:web:408e94b95b9dae14"
//   };
const firebaseConfig2 = {
  apiKey: "AIzaSyAPe5JQQz0a1T7ytukrW3JLTapYdfRyuSE",
  authDomain: "food2order-33f23.firebaseapp.com",
  databaseURL: "https://food2order-33f23.firebaseio.com",
  projectId: "food2order-33f23",
  storageBucket: "food2order-33f23.appspot.com",
  messagingSenderId: "1031783764410",
  appId: "1:1031783764410:web:5a15589c3f345e7d1fcd83",
  measurementId: "G-TCEEZR3TLT"
};
firebase.initializeApp(firebaseConfig2);

// firebase.initializeApp(firebaseConfig,'cairoGourmet')
// const perf = firebase.performance();

const auth = firebase.auth;
const firestore = firebase.firestore;
const storage = firebase.storage();
// firebase.functions().useFunctionsEmulator('http://localhost:5000')

const functions = firebase.functions();

export {
  auth,
  firestore,
  storage,
  functions
};


import React, { useEffect, useState } from 'react';
import './ratingPage.css'
import ratingImg from '../../MenuComponents/img/ratingImg.svg'
import LocalizedStrings from 'react-localization';
import { Link, Redirect } from 'react-router-dom'
import { Form, Checkbox, Divider, TextArea, Button, Icon } from 'semantic-ui-react'
import { firestore, auth } from 'firebase'

import { useSelector } from 'react-redux';

const RatingPage = ({ orderId, dismiss }) => {
    const { resKey, pathName } = useSelector(state => state.settings)
    const [state, setState] = useState({
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',
        step: 0,
        selectedRate: null,
    });

    const [strings] = useState(new LocalizedStrings({
        en: {
            orderSent: "Your Order has been sent 👏",
            trackOrder: "Track order",
            RateExperience: "Rate Experience",
            SorryToHear: "Sorry to hear That",
            WhatWentWrong: "What went wrong?",
            ItemDelayed: "Item delayed",
            FoodWasDamaged: "Item was damaged",
            PoorFoodQuality: "Poor Item quality",
            MissingWrongItems: "Missing or wrong items",
            LeaveComment: "Leave a comment",
            ThankYou: "Thank you",
            YourFeedback: "Your feedback will help us improve our services we provide to you",
            terrible: "Terrible",
            bad: "Bad",
            average: "Average",
            good: "Good",
            awsome: "Awsome",
            continue: "Continue",
            submit: "submit",
            backToMenu: "Back To Menu",
            howWasFood: "How was the experience?",
            pleaseRate: "please rate the experience for recent order",


        },
        ar: {
            orderSent: "تم إرسال طلبك 👏",
            trackOrder: "تتبع طلبك",
            RateExperience: "تقييم التجربة",
            SorryToHear: "نأسف لسماع ذلك",
            WhatWentWrong: "ما الخطأ الذي حدث؟",
            ItemDelayed: "تاخر التوصيل",
            FoodWasDamaged: "تضرر المنتج",
            PoorFoodQuality: "نوعية المنتج رديئة",
            MissingWrongItems: "عناصر مفقودة أو خاطئة",
            LeaveComment: "اترك تعليقا",
            ThankYou: "شكرا لك",
            YourFeedback: "ستساعدنا ملاحظاتك في تحسين خدمتنا التي نقدمها لك",
            terrible: "رهيب",
            bad: "سيئة",
            average: "متوسط",
            good: "جيد",
            awsome: "رائع",
            continue: "متابعة",
            submit: "إرسال",
            backToMenu: "عودة إلى القائمة",
            howWasFood: "كيف كان التجربة؟",
            pleaseRate: "يرجى تقييم التجربة للطلب الأخير",
        }
    }))

    const [ratings] = useState([{ emoji: "🤢", text: strings.terrible }, { emoji: "😐", text: strings.bad }, { emoji: "🙂", text: strings.average }, { emoji: "😊", text: strings.good }, { emoji: "😍", text: strings.awsome }])
    const [issues] = useState([strings.ItemDelayed, strings.PoorFoodQuality, strings.MissingWrongItems])

    useEffect(() => {
        strings.setLanguage(state.direction === 'rtl' ? 'ar' : 'en');
    }, [state?.direction])
    const handleChange = (e, { value }) => {
        let { selectedRate } = state
        selectedRate["badRate"] = value
        setState({ ...state, selectedRate })
    }
    const handleInputChange = (e, { value }) => {
        let { selectedRate } = state
        selectedRate["comment"] = value
        setState({ ...state, selectedRate })
    }
    const submit = () => {
        let { selectedRate } = state
        let key = resKey
        console.log("resKey", resKey);
        selectedRate.userUId = auth().currentUser ? auth().currentUser.uid : 'non'

        if (!orderId) {
            let url = new URL(window.location.href)
            orderId = url.searchParams.get('orderId')
        }
        console.log("orderId", orderId);

        if (key) {
            let id = firestore().collection('resturant').doc(key).collection('ratings').doc().id

            if (auth().currentUser) {
                firestore().collection('users').doc(auth().currentUser.uid).get().then(results => {
                    let data = results.data()
                    let { name } = data
                    selectedRate.user = {
                        name: name ? name : auth().currentUser.phoneNumber,
                        phone: auth().currentUser.phoneNumber
                    }
                    selectedRate.created = Date.now()
                    selectedRate.orderId = orderId
                    firestore().collection('resturant').doc(key).collection('ratings').doc(id).set(selectedRate).then(() => {
                        window.dataLayer.push({
                            'userID': auth().currentUser.uid,
                            'resKey': window.resKey,
                            'rate': selectedRate,
                            'event': 'Feedback'
                        })
                        firestore().collection('resturant').doc(key).collection('orders').doc(orderId).update({
                            rated: true
                        })
                        dismissView()
                    }).catch(() => {
                        dismissView()
                    })

                })
            } else {
                dismissView()
            }
        } else {
            dismissView()
        }

    }
    function dismissView() {
        if (dismiss) {
            dismiss()
        } else {
            setState({ ...state, redirect: true })
        }
    }

    return (
        <div className='ratingClass' style={(state?.step === 2) ? { justifyContent: 'center' } : {}} >
            {(state?.step === 0 || state?.step === 1) &&
                <Link to={pathName ? '/' + pathName : '/'} onClick={() => dismissView()} className='iconTimes' > <Icon name='times' /></Link>
            }
            {
                state?.redirect ?
                    <Redirect to={pathName ? '/' + pathName : '/'} />
                    :
                    <div />
            }

            {
                state?.step === 0 ? (
                    <div className='rateExperienceContainer' >

                        <div className='title' >
                            {strings.RateExperience}
                            <div className='underline' />
                        </div>
                        <div className='sorryContainer' >
                            <div className='upperText' >{strings.howWasFood}</div>
                            <div className='lowerText' >{strings.pleaseRate}</div>
                        </div>
                        <div className='emojiContainer'>
                            {
                                ratings.map((item, index) => {
                                    return (
                                        <div className='emojiItem' key={index} onClick={() => { setState({ ...state, selectedRate: { rate: item, index: index } }) }}>
                                            <div className='emoji' style={(state?.selectedRate && state?.selectedRate.index === index) ? { backgroundColor: 'var(--themeColor)' } : {}} >{item.emoji}</div>
                                            <div className='text'>{item.text}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                ) : state?.step === 1 ? (
                    <div className='rateExperienceContainer' >
                        <div className='title' >
                            {strings.RateExperience}
                            <div className='underline' />
                        </div>
                        <div className='sorryContainer' >
                            <div className='upperText' >{strings.SorryToHear}</div>
                            <div className='lowerText' >{strings.WhatWentWrong}</div>
                        </div>
                        <Form className='checkboxContainer'>
                            {
                                issues.map((item, index) => {
                                    return (
                                        <Form.Field key={index} >
                                            <Checkbox
                                                radio
                                                label={item}
                                                className={state?.direction === 'rtl' ? 'floatright' : ''}
                                                value={item}
                                                checked={state.selectedRate.badRate === item}
                                                onChange={handleChange}
                                            />
                                            <Divider />
                                        </Form.Field>
                                    )
                                })
                            }

                        </Form>
                        <Form>
                            <TextArea rows={1} placeholder={strings.LeaveComment} onChange={handleInputChange} />
                        </Form>
                    </div>
                ) : (
                    <div className='thankyouClassContainer' >
                        <img className='checkimg' src={ratingImg} alt='' />
                        <div className='holatext' >{strings.ThankYou}</div>
                        <div className='yourordertext' >{strings.YourFeedback}</div>
                    </div>
                )
            }

            {
                (state?.step !== 2) ? (<Button className='submitButton' disabled={!state?.selectedRate} onClick={() => {
                    if (state?.selectedRate && state?.selectedRate.index > 2) {
                        setState({ ...state, step: 2 })
                    } else {
                        setState({ ...state, step: state?.step + 1 })
                    }
                }}>{[strings.continue, strings.submit, strings.backToMenu][state?.step]}</Button>
                ) : (
                    <Button loading={state?.loading} className='submitButton' onClick={() => { setState({ ...state, loading: true }); submit() }}>{[strings.continue, strings.submit, strings.backToMenu][state?.step]}</Button>
                )
            }

        </div>
    );
};

export default RatingPage;


import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { SetAlertMessage } from '../../reducers/settings';

const AlertModal = () => {
    const { alertMessage } = useSelector(state => state.settings);
    let direction = localStorage.getItem('direction');
    const dispatch = useDispatch()
    return (
        <Modal
            basic
            // onClose={() => setOpen(false)}
            // onOpen={() => setOpen(true)}
            open={true}
            size='small'
        >
            <Header icon>
                {
                    alertMessage?.icon && <Icon name={alertMessage?.icon} />
                }

                {alertMessage?.title}
            </Header>
            <Modal.Content>
                <p style={{ textAlign: direction === 'rtl' ? 'right' : 'left' }}>
                    {alertMessage.message}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button basic color='red' inverted onClick={alertMessage?.onCancel || dispatch(SetAlertMessage(false))}>
                    <Icon name='remove' /> {alertMessage?.cancelTitle}
                </Button>
                <Button color='green' inverted onClick={alertMessage?.onConfirm}>
                    <Icon name='checkmark' /> {alertMessage?.confirmTitle}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default AlertModal;
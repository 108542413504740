import { Dimmer, Icon, Loader } from 'semantic-ui-react'
import React, { Component } from 'react';
// import '../loyalty.css'
import LocalizedStrings from 'react-localization';

import { ReactComponent as ReactLogo } from '../../../Login/img/times.svg';

class Step1 extends Component {
    strings = new LocalizedStrings({
        en: {
            Get: 'Get',
            signup: 'Signup',
            receiveRewards: 'Sign up now and receive',
            receiveRewards2: 'in your wallet for your next delivery order.',
            AED: " AED",
            EGP: " EGP",
            BHD: " BHD",
            KWD: " KWD",
            OMR: " OMR",
            QAR: " QAR",
            SAR: " SAR",
            USD: " USD",

        },
        ar: {
            Get: 'حمل تطبيق',
            signup: 'تسجيل',
            receiveRewards: 'سجل الآن واستقبل',
            receiveRewards2: 'في محفظتك لطلب التوصيل القادم.',
            AED: " درهم",
            EGP: " جنيه",
            BHD: " BHD",
            KWD: " دينار",
            OMR: " ريال",
            QAR: " ريال",
            SAR: " ريال",
            USD: " USD",
        }

    })
    state = {
        loading: true
    }

    componentWillMount() {
        let { direction } = this.props
        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');

        document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden'

    }
    componentWillUnmount() {
        document.body.style.overflow = 'auto'

    }
    render() {
        let { dismiss, loyalty, setStep, direction, login } = this.props
        let strings = this.strings

        return (
            <Dimmer active className='getapp' page >
                <div className='step1Container'>
                    <div style={{ textAlign: "initial", padding: '12px', position: 'absolute', right: direction === 'rtl' ? 'auto' : '0', left: direction === 'rtl' ? '0' : 'auto', cursor: 'pointer' }} onClick={() => {
                        dismiss()
                    }}>
                        {/* <ReactLogo /> */}
                        <Icon name='remove' />

                    </div>
                    <div className='infoContainer'>
                        <div className='priceContainer'>
                            <div className='price'>{loyalty?.downloadGift || 0}</div>
                            <div className='currency'>{strings[window.currency]}</div>
                        </div>
                        <div className='descriptionContainer'>
                            <div className='descriptionText'>
                                {strings.receiveRewards} {loyalty?.downloadGift || 0} {strings[window.currency]} {strings.receiveRewards2}
                            </div>
                            <div className='getAppButton' onClick={() => login()}>
                                {strings.signup}
                            </div>
                        </div>

                    </div>


                </div>

            </Dimmer>
        )
    }


}

export default Step1
import React, { Component } from 'react';
import './profile.css';
import Header from '../Header';
import { auth, firestore } from 'firebase'
import { Link } from 'react-router-dom'
import Login from '../Login/newLogin'
import { connect } from 'react-redux';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';
import LocalizedStrings from 'react-localization';

function dateFormatter(timeStamp) {
    var date = new Date(timeStamp);
    return date.toLocaleDateString("en-US") + ' at ' + date.toLocaleTimeString('en-US')
}
class Profile extends Component {
    state = {
        step: 0,
        loading: true,
        direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : 'rtl',

    }
    strings = new LocalizedStrings({
        en: {
            ordersHistory: "Orders History",
            orderAgain: "Order Again",
            orders: "Orders",
            accountSettings: "Account Settings",
            editProfile: "Edit Profile",
            logout: "Log out"
        },
        ar: {
            ordersHistory: "الطلبات السابقة",
            orderAgain: "طلب مرة اخرى",
            orders: "الطلبات",
            accountSettings: "إعدادت الحساب",
            editProfile: "تعديل الحساب الشخصي",
            logout: "تسجيل الخروج"




        }
    });
    componentWillMount() {
        this.strings.setLanguage(this.state.direction === 'rtl' ? 'ar' : 'en');

        auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ loading: false })
            } else {
                this.setState({ loading: false, login: true })
            }
        })
    }

    loadHistory() {
        if (!this.props.user || !auth().currentUser) {
            this.setState({ login: true, step: 0 })
            return
        }
        let uid = this.props.user.id
        firestore().collection('resturant').doc(window.resKey).collection('orders').where('user.key', '==', uid).get().then(results => {
            let history = [];
            results.forEach(order => {
                let value = order.data()
                value.id = order.id
                history.push(value)
            })
            history = history.filter(item => item.items)
            history.sort((a, b) => { return b.created - a.created })
            this.setState({ history })
        })
    }
    getItems = (items) => {
        let itemsNames = [];
        (items ? items : []).map(item => {
            return itemsNames.push(item.count + 'x ' + item.name)
        })
        return (itemsNames)
    }
    orderAgain = (order) => {
        let { user } = this.props
        if (user && user.info && user.info.name && user.info.number && user.info.area) {
            delete order.id;
            delete order.updated;
            delete order.coupon;
            order.created = Date.now();
            order.status = 'new';
            firestore().collection('resturant').doc(window.resKey).collection('orders').add(order).then(() => {
                console.log("order updated successfully")
                this.setState({ login: true, step: 3 })
            })
        } else {
            this.setState({ login: true, step: !user ? 0 : (!user.info.name || !user.info.address || !user.info.area) ? 2 : 0 })
            window.alert("fill all user data")
        }
    }
    render() {
        let { login, step, loading, direction } = this.state;
        return (
            <div className='profileSection' style={{ direction: direction }}>
                {
                    loading ?
                        <Dimmer active>
                            <Loader active name='spinner'></Loader>
                        </Dimmer>
                        : (login || !auth().currentUser) ?
                            <Login step={step} edit={auth().currentUser ? true : false} dismiss={() => this.setState({ login: false })} complited={() => this.setState({ login: false })} />
                            : ''
                }
                <div className='leftSide'>
                    {this.renderLeftSide()}
                </div>
                {/* <div className='rightSide' /> */}
            </div>
        )
    }

    renderLeftSide() {
        let { history, direction } = this.state
        let { user ,activeOrder,selectedLocation} = this.props
        let strings = this.strings
        console.log('user', user)
        if (history) {
            return (
                <div className='profile'>

                <Header
                    updateLang={(direction) => {
                        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
                        this.setState({ direction, extras: false }, () => {
                            window.scrollTo({
                                top: 0,
                            });
                        })
                    }}
                    showLoyalty={() => this.setState({ showLoyalty: true })}
                    user={user}
                    login={() => {
                        this.setState({ login: true })
                    }
                    }
                    resLoyalty={false}
                    direction={direction}
                    hideCart={activeOrder}
                    location={selectedLocation}
                    showSelectLocation={() => this.setState({ selectLocation: true })} />

                    {/* <Header back={() => this.setState({ history: false })} direction={direction} /> */}
                    <div style={{ position: 'relative'}}>
                        <div className='profileHeader'>
                            <div className='userInfo'>
                                <div className='name'>
                                    {strings.ordersHistory}
                                </div>
                            </div>
                        </div>
                        <div className='historyContainer'>
                            {
                                history.map(order => {
                                    return (
                                        <div key={order.created} className='historyItem'>
                                            <div className='info'>
                                                <div className='date'>{dateFormatter(order.created)}</div>
                                                <div className='area'>{'delivered to: '} <span style={{ color: 'rgba(74, 74, 74, 1)' }}> {order.user.area}</span></div>
                                            </div>
                                            <div className='details'>
                                                {
                                                    this.getItems(order.items).join(',')
                                                }
                                            </div>
                                            <div className='actionBtn' onClick={() => this.orderAgain(order)}>
                                                {strings.orderAgain}

                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>


                </div>
            )

        }
        return (
            <div className='profile'>
                
                <Header
                    updateLang={(direction) => {
                        this.strings.setLanguage(direction === 'rtl' ? 'ar' : 'en');
                        this.setState({ direction, extras: false }, () => {
                            window.scrollTo({
                                top: 0,
                            });
                        })
                    }}
                    showLoyalty={() => this.setState({ showLoyalty: true })}
                    user={user}
                    login={() => {
                        this.setState({ login: true })
                    }
                    }
                    resLoyalty={false}
                    direction={direction}
                    hideCart={activeOrder}
                    location={selectedLocation}
                    showSelectLocation={() => this.setState({ selectLocation: true })} />
                <div style={{ position: 'relative' }}>
                    <div className='profileHeader'>
                        {
                            (user && user.info && user.info.picture) ?
                                <img alt='' src={user.info.picture} />
                                :
                                <Icon className='placeholder' name='user' />

                        }
                        <div className='userInfo'>
                            <div className='name'>
                                {(user && user.info) ? user.info.name : ''}
                            </div>
                            <div className='area'>
                                {(user && user.info) ? user.info.area : ''}

                            </div>
                        </div>

                    </div>
                    <div className='listHeader'>
                        {strings.orders}
                    </div>
                    <div className='listItem' onClick={() => this.loadHistory()}>
                        {strings.ordersHistory}
                        <Icon name='chevron right' style={direction === 'rtl' ? { transform: "rotate(180deg)" } : {}} />
                    </div>
                    <div className='listHeader'>
                        {strings.accountSettings}
                    </div>
                    <div className='listItem' onClick={() => { this.setState({ login: true, step: auth().currentUser ? 2 : 0 }) }}>
                        {strings.editProfile}
                        <Icon name='chevron right' style={direction === 'rtl' ? { transform: "rotate(180deg)" } : {}} />
                    </div>
                    <Link to='/' className='listItem' onClick={() => { auth().signOut() }}>
                        {strings.logout}
                        <Icon name='chevron right' style={direction === 'rtl' ? { transform: "rotate(180deg)" } : {}} />
                    </Link>

                </div>

            </div>
        )
    }

}
function mapStateToProps(state) {
    return {
        user: state.userProp ? state.userProp.user : {},
        selectedLocation: state.userProp ? state.userProp.location : {},
        loyalty: state.loyalty,
        activeOrder: state.userProp ? state.userProp.activeOrder : false


    }
}

const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
